/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "otp",
            "endpoint": "https://pfkntmz3w2.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "publicZendeskAPI",
            "endpoint": "https://fn5s8z36nh.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "salesforceapi",
            "endpoint": "https://dwt0e7o8cc.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "slack",
            "endpoint": "https://96sm2hla3m.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "ups",
            "endpoint": "https://92mp9hs9ua.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "zendeskapi",
            "endpoint": "https://ufpye5yyvd.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yifgjb2eqnez3lidresbz27n5q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-axhdfj4d7najrlczf6sjjw3f2a",
    "aws_cognito_identity_pool_id": "us-west-2:711d4cdc-57b6-455f-9989-2fe1ab4e661d",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5PlgXq89O",
    "aws_user_pools_web_client_id": "527hbhotob43l66b34uv97rsbv",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "test-bucket-2023-12-04235742-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
