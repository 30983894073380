import { Box, Typography, useTheme, Button, TextField,FormControl,InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers";
// import { API } from 'aws-amplify';

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";

import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";
import { useParams } from 'react-router-dom';

import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';

import dayjs from 'dayjs';
import { DaySeriesModel } from "@fullcalendar/core/internal";
import {createChannelName} from "./support_functions";

import { generateClient } from 'aws-amplify/api';
import { get } from 'aws-amplify/api';
import {orderinformation_setone, orderinformation_settwo, orderinformation_setthree, orderinformation_setfour, orderinformation_hardware_setone, orderinformation_hardware_settwo, orderinformation_hardware_setthree
,orderinformation_software_setone, orderinformation_software_settwo, orderinformation_software_setthree, orderinformation_software_setfour} from '../../functions/create_order';
Amplify.configure(awsExports);


const DisplayOrderInformation = () => {
    
    const isNonMobile = useMediaQuery("(min-width:600px)");  

    const [orderFormData, setOrderFormData] = useState({});
    const [showOrderForm, setShowOrderForm] = useState(true);
    // Gets the Suggested Order Number
    async function getSuggestedOrderNumber() {
        setOrderFormData((orderFormData) => ({
            ...orderFormData,
            ["order_type"]: "SO",
            }));
        try
        {
            const orderData = await DataStore.query(ImportedOrders);
            const internalOrderNumbers = orderData.map((order) => order.internal_order_number);
    
            let largestNumber = -1;
            
            for (const orderNumber of internalOrderNumbers) 
            {
                const numericPart = orderNumber.split('-')[1]; // Split by hyphen and get the second part
                const numericValue = parseInt(numericPart, 10); // Convert to an integer
            
                if (!isNaN(numericValue) && numericValue > largestNumber)
                {
                largestNumber = numericValue;
                }
            }
            largestNumber = largestNumber + 1;
            if(largestNumber > 0)
            {
                setOrderFormData((orderFormData) => ({
                    ...orderFormData,
                    ["internal_order_number"]: (orderFormData.order_type + "-" + largestNumber.toString().padStart(7, '0'))
                    }));
            }
    
        } 
        catch (error) {console.error('Error fetching data:', error);}
    }
    // Calls the Fetch Inventory Data
    useEffect(() => {getSuggestedOrderNumber();}, []);
    //Any changes in field for order
    const handleFieldChangeOrder = (event) => {
        const { name, value } = event.target;
        // Update the form data state with the new value
        setOrderFormData((prevOrderInformation) => ({
            ...prevOrderInformation,
            [name]: value,
        }));
    };
    //Hanldes the change in field for Order Type, it will update the IOT based on order type
    const handleOrderFormChangeOrderType = (event) => {      
        const { name, value } = event.target;
        if (name === "order_type") {
            const newOrderType = value;
        
            // Update the internal_order_number based on the new order_type
            const temp_internal_order_number = `${newOrderType}-${orderFormData.internal_order_number.split('-')[1]}`.padStart(7, '0');
        
            setOrderFormData((prevOrderData) => ({
            ...prevOrderData,
            order_type: newOrderType, // Use the correct object property notation
            internal_order_number: temp_internal_order_number, // Use the correct object property notation
            }));
        }
    };
    //Handles changed made to date field.
    const handleFieldChangeOrderDate = (selectedDate) => {
        const stringDate = selectedDate.format('YYYY-MM-DD');
        // Update the form data state with the new date value
        setOrderFormData((prevOrderInformation) => ({
        ...prevOrderInformation,
        ['order_date']: stringDate, // Update the order_date field with the selected date
        }));
    };
    // Handles the submit order (need to add in slack team members list)
    async function handleSubmitOrder(event) 
    {
        event.preventDefault();
        let channel_id;

        const channel_name = createChannelName(orderFormData.internal_order_number,orderFormData.order_type,orderFormData.partner_name, orderFormData.end_customer_name );
        console.log(channel_name);


        ////This is where slack channel is created
        // try {
        //     const restOperation = get({ 
        //       apiName: 'slack',
        //       path: '/createChannel',
        //       options:{
        //         queryParams:{
        //             channel_name: channel_name
        //         }
        //       }
        //     });
        //     const { body } = await restOperation.response;
        //     const json = await body.json();
        //     channel_id = json['channel']['id'];

        //   } catch (error) {
        //     console.log('GET call failed: ', error);
        //   }

        // try {
        //     const restOperation = get({ 
        //         apiName: 'slack',
        //         path: '/addToChannel',
        //         options:{
        //           queryParams:{
        //             channel_id: channel_id,
        //             user_id : 'U02SQ10N0V7'
        //           }
        //         }
        //       });
        //       const { body } = await restOperation.response;
        //       const json = await body.json();
        //       console.log(json);
        // }
        // catch (error) {console.log('GET call failed: ', error);}


        const createOrder = await DataStore.save(new ImportedOrders({
        internal_order_number : orderFormData.internal_order_number,
        external_order_number : orderFormData.external_order_number,
        order_type : orderFormData.order_type,
        order_date : orderFormData.order_date ?  orderFormData.order_date:dayjs().format('YYYY-MM-DD'),
        partner_name : orderFormData.partner_name,
        end_customer_name : orderFormData.end_customer_name,
        sales_rep : orderFormData.sales_rep,
        ship_country : orderFormData.ship_country,
        ship_address : orderFormData.ship_address,
        bill_address : orderFormData.bill_address,
        bill_country : orderFormData.bill_country,
        contact_name : orderFormData.contact_name,
        contact_phone : orderFormData.contact_phone,
        contact_email : orderFormData.contact_email,
        hardware : orderFormData.hardware || "No",
        software : orderFormData.software || "No",
        progress : orderFormData.progress ? orderFormData.progress : "New",
        additional_details : orderFormData.additional_details,
        internal_invoice : orderFormData.internal_invoice,
        salesforce_link : orderFormData.salesforce_link,
        slack_channel : channel_id,
        notifications : orderFormData.notifications ? orderFormData.notifications : "1",
        slack_channel_archive : orderFormData.slack_channel_archive  ? orderFormData.slack_channel_archive : "0",
        software_cancelled : orderFormData.software_cancelled ? orderFormData.software_cancelled  : "0",
        opportunityid : orderFormData.opportunityid,
        orderid : orderFormData.orderid,
        }));
        console.log(createOrder);
        setShowOrderForm(false);



        if(orderFormData.hardware === "yes")
        {
            setShowOrderFormHardware(true);
        }
        if(orderFormData.software === "yes")
        {
            setShowOrderFormSoftware(true);
        }
    };

    const hardwareFields = ["M6000", "M3000", "Photon_400", "Photon_300", "Photon_250", "Photon_160", "Photon_150", "Photon_100"];
    const [orderFormDataHardware, setOrderFormDataHardware] = useState({});
    const [hardwareError, setHardwareError] = useState(false);
    const [showOrderFormHardware, setShowOrderFormHardware] = useState(false);

    const handleFieldChangeOrderHardware = (event) => {
        const { name, value } = event.target;
        // Update the form data state with the new value
        setOrderFormDataHardware((prevOrderInformation) => ({
            ...prevOrderInformation,
            [name]: value,
        }));
        console.log(orderFormDataHardware)
    };
    const handleFieldChangeOrderHardwareNumber = (event) => {
        const { name, value } = event.target;
        const intValue = parseInt(value);
        // Validate if the input is an integer
        if (!isNaN(intValue) )
        {
            setHardwareError(false);
            // If the input is a valid integer, update the form data state
            setOrderFormDataHardware((prevOrderInformation) => ({
                ...prevOrderInformation,
                [name]: value,
            }));
        }
        else
        {
            setHardwareError(true);
        }
    };
    async function handleSubmitOrderHardware(event)
    {
        event.preventDefault();
        const createOrderHardware = await DataStore.save(
            new ImportedOrdersHardware({
                internal_order_number: orderFormData.internal_order_number,
                M6000: orderFormDataHardware.M6000,
                M3000: orderFormDataHardware.M3000,
                Photon_400: orderFormDataHardware.Photon_400,
                Photon_300: orderFormDataHardware.Photon_300,
                Photon_250: orderFormDataHardware.Photon_250,
                Photon_150: orderFormDataHardware.Photon_150,
                Photon_100: orderFormDataHardware.Photon_100,
                addtional_details: orderFormDataHardware.addtional_details,
                additional_warranty: orderFormDataHardware.additional_warranty,
                Photon_160: orderFormDataHardware.Photon_160,
            })
        );


        console.log(createOrderHardware);
        setShowOrderFormHardware(false);
    }

    const [orderFormDataSoftware, setOrderFormDataSoftware] = useState({});
    const [showOrderFormSoftware, setShowOrderFormSoftware] = useState(false);
    async function handleSubmitOrderSoftware(event)
    {
        event.preventDefault();

        const createOrderSoftware = await DataStore.save(
            new ImportedOrdersSoftware({
                internal_order_number: orderFormData.internal_order_number,
                items: orderFormDataSoftware.items,
                otp: orderFormDataSoftware.otp,
                ramp: orderFormDataSoftware.ramp ? orderFormDataSoftware.ramp : "0",
                additional_details: orderFormDataSoftware.additional_details,
                item_1: orderFormDataSoftware.item_1,
                item_2: orderFormDataSoftware.item_2,
                item_3: orderFormDataSoftware.item_3,
                item_4: orderFormDataSoftware.item_4,
                item_5: orderFormDataSoftware.item_5,
                item_6: orderFormDataSoftware.item_6,
                item_7: orderFormDataSoftware.item_7,
                item_8: orderFormDataSoftware.item_8,
                item_9: orderFormDataSoftware.item_9,
                item_10: orderFormDataSoftware.item_10,
                item_11: orderFormDataSoftware.item_11,
                item_12: orderFormDataSoftware.item_12,
                item_13: orderFormDataSoftware.item_13,
                item_14: orderFormDataSoftware.item_14,
                item_15: orderFormDataSoftware.item_15,
                item_16: orderFormDataSoftware.item_16,
                item_17: orderFormDataSoftware.item_17,
                item_18: orderFormDataSoftware.item_18,
                item_19: orderFormDataSoftware.item_19,
                item_20: orderFormDataSoftware.item_20,
                item_21: orderFormDataSoftware.item_21,
                item_22: orderFormDataSoftware.item_22,
                item_23: orderFormDataSoftware.item_23,
                item_24: orderFormDataSoftware.item_24,
                item_25: orderFormDataSoftware.item_25,
                item_26: orderFormDataSoftware.item_26,
                item_27: orderFormDataSoftware.item_27,
                item_28: orderFormDataSoftware.item_28,
                item_29: orderFormDataSoftware.item_29,
                item_30: orderFormDataSoftware.item_30,
                term_1: orderFormDataSoftware.term_1,
                term_2: orderFormDataSoftware.term_2,
                term_3: orderFormDataSoftware.term_3,
                term_4: orderFormDataSoftware.term_4,
                term_5: orderFormDataSoftware.term_5,
                term_6: orderFormDataSoftware.term_6,
                term_7: orderFormDataSoftware.term_7,
                term_8: orderFormDataSoftware.term_8,
                term_9: orderFormDataSoftware.term_9,
                term_10: orderFormDataSoftware.term_10,
                term_11: orderFormDataSoftware.term_11,
                term_12: orderFormDataSoftware.term_12,
                term_13: orderFormDataSoftware.term_13,
                term_14: orderFormDataSoftware.term_14,
                term_15: orderFormDataSoftware.term_15,
                term_16: orderFormDataSoftware.term_16,
                term_17: orderFormDataSoftware.term_17,
                term_18: orderFormDataSoftware.term_18,
                term_19: orderFormDataSoftware.term_19,
                term_20: orderFormDataSoftware.term_20,
                term_21: orderFormDataSoftware.term_21,
                term_22: orderFormDataSoftware.term_22,
                term_23: orderFormDataSoftware.term_23,
                term_24: orderFormDataSoftware.term_24,
                term_25: orderFormDataSoftware.term_25,
                term_26: orderFormDataSoftware.term_26,
                term_27: orderFormDataSoftware.term_27,
                term_28: orderFormDataSoftware.term_28,
                term_29: orderFormDataSoftware.term_29,
                term_30: orderFormDataSoftware.term_30,
                quantity_1: orderFormDataSoftware.quantity_1,
                quantity_2: orderFormDataSoftware.quantity_2,
                quantity_3: orderFormDataSoftware.quantity_3,
                quantity_4: orderFormDataSoftware.quantity_4,
                quantity_5: orderFormDataSoftware.quantity_5,
                quantity_6: orderFormDataSoftware.quantity_6,
                quantity_7: orderFormDataSoftware.quantity_7,
                quantity_8: orderFormDataSoftware.quantity_8,
                quantity_9: orderFormDataSoftware.quantity_9,
                quantity_10: orderFormDataSoftware.quantity_10,
                quantity_11: orderFormDataSoftware.quantity_11,
                quantity_12: orderFormDataSoftware.quantity_12,
                quantity_13: orderFormDataSoftware.quantity_13,
                quantity_14: orderFormDataSoftware.quantity_14,
                quantity_15: orderFormDataSoftware.quantity_15,
                quantity_16: orderFormDataSoftware.quantity_16,
                quantity_17: orderFormDataSoftware.quantity_17,
                quantity_18: orderFormDataSoftware.quantity_18,
                quantity_19: orderFormDataSoftware.quantity_19,
                quantity_20: orderFormDataSoftware.quantity_20,
                quantity_21: orderFormDataSoftware.quantity_21,
                quantity_22: orderFormDataSoftware.quantity_22,
                quantity_23: orderFormDataSoftware.quantity_23,
                quantity_24: orderFormDataSoftware.quantity_24,
                quantity_25: orderFormDataSoftware.quantity_25,
                quantity_26: orderFormDataSoftware.quantity_26,
                quantity_27: orderFormDataSoftware.quantity_27,
                quantity_28: orderFormDataSoftware.quantity_28,
                quantity_29: orderFormDataSoftware.quantity_29,
                quantity_30: orderFormDataSoftware.quantity_30,
                start_date_1: orderFormDataSoftware.start_date_1,
                start_date_2: orderFormDataSoftware.start_date_2,
                start_date_3: orderFormDataSoftware.start_date_3,
                start_date_4: orderFormDataSoftware.start_date_4,
                start_date_5: orderFormDataSoftware.start_date_5,
                start_date_6: orderFormDataSoftware.start_date_6,
                start_date_7: orderFormDataSoftware.start_date_7,
                start_date_8: orderFormDataSoftware.start_date_8,
                start_date_9: orderFormDataSoftware.start_date_9,
                start_date_10: orderFormDataSoftware.start_date_10,
                start_date_11: orderFormDataSoftware.start_date_11,
                start_date_12: orderFormDataSoftware.start_date_12,
                start_date_13: orderFormDataSoftware.start_date_13,
                start_date_14: orderFormDataSoftware.start_date_14,
                start_date_15: orderFormDataSoftware.start_date_15,
                start_date_16: orderFormDataSoftware.start_date_16,
                start_date_17: orderFormDataSoftware.start_date_17,
                start_date_18: orderFormDataSoftware.start_date_18,
                start_date_19: orderFormDataSoftware.start_date_19,
                start_date_20: orderFormDataSoftware.start_date_20,
                start_date_21: orderFormDataSoftware.start_date_21,
                start_date_22: orderFormDataSoftware.start_date_22,
                start_date_23: orderFormDataSoftware.start_date_23,
                start_date_24: orderFormDataSoftware.start_date_24,
                start_date_25: orderFormDataSoftware.start_date_25,
                start_date_26: orderFormDataSoftware.start_date_26,
                start_date_27: orderFormDataSoftware.start_date_27,
                start_date_28: orderFormDataSoftware.start_date_28,
                start_date_29: orderFormDataSoftware.start_date_29,
                start_date_30: orderFormDataSoftware.start_date_30,
                end_date: orderFormDataSoftware.end_date,
                SaaS: orderFormDataSoftware.SaaS ? orderFormDataSoftware.SaaS : "0",
                domain_name: orderFormDataSoftware.domain_name
        }));
        console.log(createOrderSoftware);
        setShowOrderFormSoftware(false);
    }
    const handleFieldChangeOrderSoftware = (event) => {
        const { name, value } = event.target;
        // Update the form data state with the new value
        setOrderFormDataSoftware((prevOrderInformation) => ({
            ...prevOrderInformation,
            [name]: value,
        }));
    };
    //Handles changed made to software.
    const handleFieldChangeOrderDateSoftware = (value, field) => {
        console.log(value, field);
        // Access the specific property holding the field name
        const stringDate = value.format('YYYY-MM-DD');
        // Update the form data state with the new date value
        setOrderFormDataSoftware((prevOrderInformation) => ({
        ...prevOrderInformation,
        [field]: stringDate, // Update the order_date field with the selected date
        }));
        console.log(orderFormDataSoftware);
    };



    const components_order_setone = [];
    components_order_setone.push(orderinformation_setone(orderFormData,handleFieldChangeOrder, handleOrderFormChangeOrderType, handleFieldChangeOrderDate, isNonMobile));

    const components_order_settwo = [];
    components_order_settwo.push(orderinformation_settwo(orderFormData, handleFieldChangeOrder, isNonMobile));

    const components_order_setthree = [];
    components_order_setthree.push(orderinformation_setthree(orderFormData, handleFieldChangeOrder, isNonMobile));

    const components_order_setfour = [];
    components_order_setfour.push(orderinformation_setfour(orderFormData, handleFieldChangeOrder, isNonMobile));

    const components_hardwareorder_setone = [];
    components_hardwareorder_setone.push(orderinformation_hardware_setone(orderFormDataHardware, hardwareFields, handleFieldChangeOrderHardwareNumber, isNonMobile));

    const components_hardwareorder_settwo = [];
    components_hardwareorder_settwo.push(orderinformation_hardware_settwo(orderFormDataHardware, handleFieldChangeOrderHardwareNumber, isNonMobile));

    const components_hardwareorder_setthree = [];
    components_hardwareorder_setthree.push(orderinformation_hardware_setthree(orderFormDataHardware, handleFieldChangeOrderHardware, isNonMobile));

    const components_software_setone = [];
    components_software_setone.push(orderinformation_software_setone(orderFormDataSoftware, handleFieldChangeOrderSoftware, isNonMobile));
    const components_software_settwo = [];
    components_software_settwo.push(orderinformation_software_settwo(orderFormDataSoftware, handleFieldChangeOrderSoftware,handleFieldChangeOrderDateSoftware, isNonMobile));
    const components_software_setthree = [];
    components_software_setthree.push(orderinformation_software_setthree(orderFormDataSoftware, handleFieldChangeOrderSoftware, isNonMobile));
    const components_software_setfour = [];
    components_software_setfour.push(orderinformation_software_setfour(orderFormDataSoftware, handleFieldChangeOrderSoftware, handleFieldChangeOrderDateSoftware, isNonMobile));

return (
<div>
    {showOrderForm && (
        <Box m="20px">
            <h1>Create Order</h1>
            <form onSubmit={handleSubmitOrder}>
                {/* IOT, EOT, Order Type, Order Date, Partner Name, End Customer Name, Sales Rep */}
                {components_order_setone}
                {/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
                {components_order_settwo}
                {/* Hardware,Software,Progress,Additional Information,Internal Invoices,Salesforce Link */}
                {components_order_setthree}
                {/* slack channel, notifications, slack channel archive, software cancelled, opportunityid, orderid */}
                {components_order_setfour}
                <Box m="20px">
                    <Button type="submit" color="secondary" variant="contained">
                        Create Order
                    </Button>
                </Box>
            </form>
        </Box>
    )}
    {!showOrderForm && showOrderFormHardware && (
    <Box m="20px">
        <h1>Create Order Hardware</h1>
        {hardwareError && <p>Please Input Integer</p>}
        {/* Each hardware item type */}
        {components_hardwareorder_setone}
        {/* Additional Warranty */}
        {components_hardwareorder_settwo}
        {/* Additional Information */}
        {components_hardwareorder_setthree}
        <form onSubmit={handleSubmitOrderHardware}>
        <Box m="20px">
            <Button type="submit" color="secondary" variant="contained">
            Create Order
            </Button>
        </Box>
        </form>
    </Box>
    )}

    {!showOrderForm && !showOrderFormHardware && showOrderFormSoftware && (
        <Box m="20px">
        <h1>Create Order Software</h1>
        <p> {orderFormData.internal_order_number}</p>

        <form onSubmit={handleSubmitOrderSoftware}>

        {components_software_setone}
        {components_software_settwo}
        {components_software_setthree}
        {components_software_setfour}

        <Box m="20px">
            <Button type="submit" color="secondary" variant="contained">
            Create Order
            </Button>
        </Box>
        </form>
        </Box>
    )}

    {!showOrderForm && !showOrderFormHardware && !showOrderFormSoftware && (
        <p> ORDER IS COMPLETED</p>
    )}



</div>
   
     
)

};

export default DisplayOrderInformation;

