import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';
import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped, LoginInformation } from '../../models';
import { DataStore } from '@aws-amplify/datastore';

Amplify.configure(awsExports); // Configure Amplify with your project settings


const ViewOTP = () => {
    const [otpData, setOtpData] = useState(null);
    const [error, setError] = useState(null);
    const [otpOrderData, setOtpOrderData] = useState(null);


    const parameters = '{"otpKey": "f08e80229288"}';
    const endpoint = "otp";
    const path = "/getOTPDataOnPrem";

    const searchOTP = "f08e80229288"
    
    const fetchOTPData = async () => {    
        if(parameters)
        {
            console.log(parameters);
            console.log(JSON.parse(parameters));
        }
        try {
            // if (parameters) {
            //     const queryData = await API.get(endpoint, path, {
            //         queryStringParameters: JSON.parse(parameters)
            //     });
            //     setOtpData(queryData[0].data);
    
            // } else {
            //     const queryData = await API.get(endpoint, path);
            //     setOtpData(queryData);
            // }
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {fetchOTPData(); }, []);

    let otpItems = [];

    const fetchOTPOrderData = async () => {
        const queryData = await DataStore.query(ImportedOrdersSoftware, (order) => order.otp.eq(searchOTP));
        setOtpOrderData(queryData);
        console.log(queryData);
        for(let x = 0;  x < queryData.length; x++)
        {
            const indvidualOrderData = queryData[x];
            console.log(indvidualOrderData);

            for(let y = 1;  y < 31; y++)
            {
                if(indvidualOrderData["item_" + y] != null && indvidualOrderData["item_" +y] != "")
                {
                    console.log(indvidualOrderData["item_" + y]);
                    otpItems.push(indvidualOrderData["item_" +y]);
                }

            }
        }
        console.log(otpItems);
        
    }
    useEffect(() => {fetchOTPOrderData(); }, []);

    return (
        <div>
            <h1>VIEW OTP Information for 2ed8d0bf03de</h1>
           
            {otpOrderData && (
                <div>
                    {/* Render your data here based on the structure of your API response */}
                    <pre>{otpItems}</pre>


                </div>
            )}


        </div>
    );
};

export default ViewOTP;
