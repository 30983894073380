import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";

import { ImportedProducts, ImportedProductsShipped } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";

import useMediaQuery from "@mui/material/useMediaQuery";


Amplify.configure(awsExports);



const Inventory = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [displayType, setDisplayType] = useState("inventory");
  //Variable for userInformation
  const [inventorydata, set_inventorydata] = useState([]);
  const [shippedinventorydata, set_shippedinventorydata] = useState([]);

  //Variable for selectedUsers
  const [selectedInventoryData, setSelectedInventoryData] = useState([]);
  const handleToggleDisplay = (type) => {setDisplayType(type);};

  async function fetchData()
  {
    try {
      const importedProductsData = await DataStore.query(ImportedProducts);
      if (importedProductsData) {set_inventorydata(importedProductsData);}
      const importedProductsShippedData = await DataStore.query(ImportedProductsShipped);
      if (importedProductsShippedData) {set_shippedinventorydata(importedProductsShippedData);}
    } 
    catch (error) {console.error('Error fetching Login Information:', error);}
  }


//Calls the Fetch Inventory Data
  useEffect(() => {fetchData()}, []);


  const columnsInventory = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "additional_information",
      headerName: "Additional Information",
      flex: 1,
    },
    {
      field: "manufacturer_warranty",
      headerName: "Manufacturer Warranty",
      flex: 1,
    },
    {
      field: "recieved_date",
      headerName: "Received Date",
      flex: 1,
    },
  ];
  const columnsShippedInventory = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "internal_order_number",
      headerName: "Internal Order Number",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "additional_information",
      headerName: "Additional Information",
      flex: 1,
    },
    {
      field: "manufacturer_warranty",
      headerName: "Manufacturer Warranty",
      flex: 1,
    },
    {
      field: "recieved_date",
      headerName: "Received Date",
      flex: 1,
    },
  ];


  let columns;
  switch (displayType) {
    case "inventory":
      columns = columnsInventory;
      break;
    case "inventoryshipped":
      columns = columnsShippedInventory;
      break;
    default:
      columns = columnsInventory;
      break;
  }
  let rows;
  switch (displayType) {
    case "inventory":
      rows = inventorydata;
      break;
    case "inventoryshipped":
      rows = shippedinventorydata;
      break;
    default:
      rows = inventorydata;
      break;
  }


//Delete Selected Inventory Handler
const handleDeleteProduct = async () => 
  {
    if (selectedInventoryData.length === 0) {return;}

    if(displayType === "inventory")
    {
      // Perform deletion logic here
      try {
        // Delete the selected users based on their IDs
        await Promise.all(
          selectedInventoryData.map(async (deleteinventoryitem) => {
            await DataStore.delete(ImportedProducts, deleteinventoryitem);
          })
        );
        // After successful deletion, update the userInformation state
        const updatedIventoryData = rows.filter(
          (deleteinventoryitem) => !selectedInventoryData.includes(deleteinventoryitem.id)
        );
        set_shippedinventorydata(updatedIventoryData);
        // Clear the selectedUserIds state
        setSelectedInventoryData([]);
      }
      catch (error) {}
    }
  if(displayType === "inventoryshipped")
  {
    // Perform deletion logic here
    try {
      // Delete the selected users based on their IDs
      await Promise.all(
        selectedInventoryData.map(async (deleteinventoryitem) => {
          await DataStore.delete(ImportedProductsShipped, deleteinventoryitem);
        })
      );
      // After successful deletion, update the userInformation state
      const updatedIventoryData = rows.filter(
        (deleteinventoryitem) => !selectedInventoryData.includes(deleteinventoryitem.id)
      );
      setSelectedInventoryData(updatedIventoryData);
      // Clear the selectedUserIds state
      setSelectedInventoryData([]);
    }
    catch (error) {}
  }
 
};


const [createProduct, setCreateProduct] = useState([]);


async function handleCreateProduct (event) {
  event.preventDefault();
  
  const q_create =  await DataStore.save(
    new ImportedProducts({
      serial_number: createProduct.serial_number,
      model: createProduct.model
	})
);

console.log(q_create);
  
}

const handleCreateProductInput = (event) => {
  const { name, value } = event.target;

  setCreateProduct((prevData) => {
    // Create a new instance of orderData with the updated property
    const updatedData = {
      ...prevData,
      [name]: value,
    };

    // Create a new object with the updated orderData
    const updatedUserData = {
      ...prevData,
      ...updatedData,  // Spread the properties of updatedData
    };

    return updatedUserData;
  });

  console.log(createProduct);

}





  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />
      <Box m="20px">
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "inventory"}
          onClick={() => handleToggleDisplay("inventory")}
        >
          Show Inventory
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "inventoryshipped"}
          onClick={() => handleToggleDisplay("inventoryshipped")}
        >
          Show Shipped Inventory
        </Button>
      </Box>
      <Box m="20px">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteProduct}
          disabled={selectedInventoryData.length === 0}
        >
          Delete Selected Items
        </Button>
      </Box>
      <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
        <form onSubmit={handleCreateProduct}>
          <TextField fullWidth variant="filled" type="text" label="Serial Number" value={createProduct.serial_number || ""} name="serial_number"
                  onChange={handleCreateProductInput} sx={{ gridColumn: "span 1" }} />
          <TextField fullWidth variant="filled" type="text" label="Model" value={createProduct.model || ""} name="model"
            onChange={handleCreateProductInput} sx={{ gridColumn: "span 1" }} />
        <Box m="20px">
            <Button type="submit" color="secondary" variant="contained">
            Create Product in Inventory
            </Button>
        </Box>


        </form>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}

          onRowSelectionModelChange={(newSelection) => {
            setSelectedInventoryData(newSelection);
          }}
          selectionModel={selectedInventoryData}
        />
      </Box>
    </Box>
  );

};

export default Inventory;
