import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { ImportedOrders } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";

Amplify.configure(awsExports);



const Inventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  //Variable for userInformation
  const [inventoryData, setInventoryData] = useState([]);

  //Variable for selectedUsers
  const [selectedInventoryData, setSelectedInventoryData] = useState([]);


  const handleInternalOrderNumberClick = (internalOrderNumber) => {
    const redirectPath = `/displayorderinformation/${internalOrderNumber}`;
    navigate(redirectPath);
  };


  async function fetchInventoryData() {
    try {
      console.log('Fetching inventory data...'); // Ensure this log is printed
      const importedProductsData = await DataStore.query(ImportedOrders);
      console.log(importedProductsData); // Log the result here
  
      if (importedProductsData) {
        setInventoryData(importedProductsData);
      } else {
        console.log('No inventory data found.');
      }
    } catch (error) {
      console.error('Error fetching Inventory Data:', error);
    }
  }
  

// Calls the Fetch Inventory Data
useEffect(() => {
  console.log("Fetching inventory data...");

  fetchInventoryData();
}, []);


//Delete Selected Inventory Handler
const handleDeleteSelectedInventory = async () => {
  // Ensure that there are selected users to delete
  if (selectedInventoryData.length === 0) {
    return;
  }

  // Perform deletion logic here
  try {
    // Delete the selected users based on their IDs
    await Promise.all(
      selectedInventoryData.map(async (inventoryItem) => {
        await DataStore.delete(ImportedOrders, inventoryItem);
      })
    );

    // After successful deletion, update the userInformation state
    const updatedIventoryData = inventoryData.filter(
      (iventoryItem) => !selectedInventoryData.includes(iventoryItem.id)
    );

    setInventoryData(updatedIventoryData);

    // Clear the selectedUserIds state
    setSelectedInventoryData([]);

  } catch (error) {
  }
};



const columns = [
  {
    field: "progress",
    headerName: "Progress",
    flex: 1,
  },
  {
    field: "internal_order_number",
    headerName: "Internal Order Number",
    flex: 1,
    renderCell: (params) => (
      <button
        onClick={() => handleInternalOrderNumberClick(params.row.internal_order_number)}
      >
        {params.value}
      </button>
    ),
  },
  {
    field: "order_date",
    headerName: "Order Date",
    flex: 1,
  },
  {
    field: "partner_name",
    headerName: "Partner Name",
    flex: 1,
  },
  {
    field: "end_customer_name",
    headerName: "End Customer Name",
    flex: 1,
  },
  {
    field: "additional_details",
    headerName: "Additional Details",
    flex: 1,
  },
  {
    field: "internal_invoice",
    headerName: "Internal Invoice",
    flex: 1,
  },
];



  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />

      <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelectedInventory}
          disabled={selectedInventoryData.length === 0}
        >
          Delete Selected Items
        </Button>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={inventoryData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}

          onRowSelectionModelChange={(newSelection) => {
            setSelectedInventoryData(newSelection);
          }}
          selectionModel={selectedInventoryData}
        />
      </Box>
    </Box>
  );

};

export default Inventory;
