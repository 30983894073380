import { Box, Typography, useTheme, Button, TextField } from "@mui/material";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Alert from '@mui/material/Alert';


import { useState,useEffect } from "react";
import { DataStore } from '@aws-amplify/datastore';
import { Amplify } from 'aws-amplify'
import awsExports from "../../aws-exports";
import useMediaQuery from "@mui/material/useMediaQuery";


import { Item, ItemLog, OrderSoftwareItem, OrderHardwareItem } from "../../models";
import { Company, OTP } from "../../models";
import { Order } from "../../models";


// TO DO
// For company name check, remove all spaces and special characters so no duplicates/existing will happen
// For company name check, eventually copy from SalesForce


const CompanyView = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");  

  // OTP, Software, Hardware, Orders
  const [displayType, setDisplayType] = useState("Orders");
  // Sets/Toggles the Display Type
  const handleToggleDisplay = (type) => {setDisplayType(type);};






  const [uiDisplay, setUIDisplayType] = useState("View All");
  const [company_name, set_company_name] = useState("");

// Data Fetch
  const [companyData, set_companyData] = useState([]);
  const [companyDataNames, set_companyDataNames] = useState([]);
  const [orderData, set_orderData] = useState([]);

  const [otpData, set_otpData] = useState([]);

  // Function to fetch the data, fetches companyData, companyDataNames, OTP
  async function fetchData()
  {
      try {
        // Sets the Company Data
        let importedData = await DataStore.query(Company);
        if (importedData) {set_companyData(importedData);}


        // Sets the Company Data
        importedData = await DataStore.query(Order);
        if (importedData) {set_orderData(importedData);}


        const importedDataNames = await DataStore.query(Company, null, {select: ['company_name']});


        importedData = await DataStore.query(Company);
       
        if (importedDataNames) {
        // When fetching only company_name, map the result to an array of company names
        const companyNames = importedDataNames.map(company => company.company_name);
        set_companyDataNames(companyNames);
        } 
        // Gets the OTP data
        const importedDataOTP = await DataStore.query(OTP);
        if (importedDataOTP) {set_otpData(importedDataOTP);}
      }
      catch (error) {console.error('Error fetching Login Information:', error);}
  }
  // Fetch the data
  useEffect(() => {fetchData()}, []);



  //Variable for selected items
  const [selected_data, set_selected_data] = useState([]);
  //Delete Selected Inventory Handler, only works for company names
  const handleDeleteSelectedData = async () => 
    {
      //If no selected data, return
      if (selected_data.length === 0) {return;}
      // Perform deletion logic here
      try {
        // Delete the selected users based on their IDs
        await Promise.all(
          selected_data.map(async (delete_selected_items) => {
            await DataStore.delete(Company, delete_selected_items);
          })
        );
        // // After successful deletion, update the userInformation state
        // const updatedCompanyData = rows.filter(
        //   (delete_selected_items) => !selected_data.includes(delete_selected_items.id)
        // );
        // // After successful deletion, update the userInformation state
        // const updatedCompanyDataNames = rows.filter(
        //   (delete_selected_items) => !selected_data.includes(delete_selected_items.Names)
        // );
        // set_companyDataNames(updatedCompanyDataNames);
        // set_companyData(updatedCompanyData);
        // // Clear the selectedUserIds state
        // set_selected_data([]);
      }
      catch (error) {}
      window.location.reload();

  }
  
  // Error message for any issues
  const [errorMessage, setErrorMessage] = useState("");

  // Columns for the Company
  const columnsForCompany = [

    {
      field: "company_name",
      headerName: "Company Name",
      flex: 4,
      renderCell: (params) => (
        <Button variant="contained" color="secondary"
          onClick={() => {
            // Change the uiDisplay to "View Single"
            setUIDisplayType("View Single");
            set_companyDataNames(params.row.company_name);

            // Do something with the selected company name, like navigating to another page or displaying more details
          }}
        >
          {params.row.company_name}
        </Button>
        ),
    }
  ]
  // Sets the Datastore to rows
  let rows = companyData;
  // Sets the Columns to columnsForCompany
  let columns = columnsForCompany;
  // Creates item in aws database
  async function handleCreateCompany(event)
  {
    event.preventDefault();
    setErrorMessage("");
    // Remove all spaces and special characters from company names in companyDataNames array
    const normalizedCompanyDataNames = companyDataNames.map(name => name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase());
    // Remove all spaces and special characters from the input company name
    const normalizedUICompanyName = ui_company_name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();
    // If the ui_company_name already exists in the database, set error message and don't add it.
    if(normalizedCompanyDataNames.includes(normalizedUICompanyName)){setErrorMessage("Company Name Already Exists.");}
    // If check is ok, it will add the company name into the Datastore and reload the window
    else
      {
        await DataStore.save(new Company({company_name: ui_company_name}));
        window.location.reload();
      }
   
  }

  // Variable for User Inputted Company Name
  const [ui_company_name, set_ui_company_name] = useState("");
  // Handler for form to add company in database.
  const handleUpdate_ui_company_name = (event) => {
    const { name, value } = event.target;
    set_ui_company_name(value);
  }

return (
  <Box m="20px">

  {/* Buttons to toggle order display */}
  <Button variant="contained" color="secondary" disabled={displayType === "Orders"} onClick={() => handleToggleDisplay("Orders")}>
    Orders
  </Button>
  <Button variant="contained" color="secondary" disabled={displayType === "Hardware"} onClick={() => handleToggleDisplay("Hardware")}>
    Hardware
  </Button>
  <Button variant="contained" color="secondary" disabled={displayType === "Software"} onClick={() => handleToggleDisplay("Software")}>
    Software
  </Button>
  <Button variant="contained" color="secondary" disabled={displayType === "OTP"} onClick={() => handleToggleDisplay("OTP")}>
    OTP
  </Button>

  {/* Display for viewing all companies */}
  {uiDisplay === "View All" && (
  <>
    {/* FORM TO ADD A COMPANY IN DATABASE*/}
    <form onSubmit={handleCreateCompany}>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
          <TextField fullWidth variant="filled" type="text" label="Company Name" name="ui_company_name"
        sx={{ gridColumn: "span 1" }} onChange={handleUpdate_ui_company_name} value={ui_company_name|| ""}/>
      </Box>
      <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>

      <Button type="submit" color="secondary" variant="contained">
            Add Company
        </Button>
        </Box>
    </form> 

    <Box m="30px">

    <Button variant="contained" color="secondary" onClick={handleDeleteSelectedData} disabled={selected_data.length === 0}> Delete Selected Items
    </Button>
    </Box>
    {/* Display for the company names */}
    <Box
      m="40px 0 0 0" height="75vh"
      sx={{ "& .MuiDataGrid-root": { border: "none",}, "& .MuiDataGrid-cell": { borderBottom: "none",}, "& .name-column--cell": { color: colors.greenAccent[1],}, 
      "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[1], borderBottom: "none",}, "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[1],}, 
      "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[1],}, "& .MuiCheckbox-root": { color: `${colors.greenAccent[1]} !important`,}, 
      "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[1]} !important`,},}}
    >
      <DataGrid 
        checkboxSelection
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        components={{ Toolbar: GridToolbar }}
        onRowSelectionModelChange={(newSelection) => {
          set_selected_data(newSelection);
        }}
        selectionModel={selected_data}
      />
    </Box>

  </>)}
  {/* View Single Copmany */}
  {uiDisplay === "View Single" && (
  <>
  </>)}



     
      
    </Box>
  );

    

};
export default CompanyView;
