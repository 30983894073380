import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';

import { DataStore, Predicates } from '@aws-amplify/datastore';
import { UPSToken } from '../../models';

import { hexdata } from './hexdata';

Amplify.configure(awsExports); // Configure Amplify with your project settings

const APITesting = () => {
  const assetCounts = {};
  const assetCountsTotal = {};
  
  hexdata.forEach(entry => {
    const date = entry.timestamp.split('T')[0]; // Extract date
    const monthYear = date.split('-').slice(0, 2).join('-'); // Extract month and year (e.g., "2024-03")
    
    // Filter for March 2024
    if (monthYear === '2024-04') {
      const assetCount = entry.license_usage;
  
      if (!(date in assetCounts)) {
        assetCounts[date] = 1;
        assetCountsTotal[date] = assetCount;
      } else {
        assetCounts[date]++;
        assetCountsTotal[date] += assetCount;
      }
    }
  });
  
  // Calculate averages
  const averageAssetCounts = {};
  Object.keys(assetCountsTotal).forEach(date => {
    averageAssetCounts[date] = assetCountsTotal[date] / assetCounts[date];
  });
  
  console.log(averageAssetCounts);
  

}


// const APITesting = () => {
//   // const token = "eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJrY2hlbkBzdGVsbGFyY3liZXIuYWkiLCJjbGllbnRpZCI6IjVLdzNtVVdLamhnbGtuVEZKNlNKYVFLVnpiS0hOR0lDcGY0TUFyVVBOcXFhN0xiTiIsImlzcyI6Imh0dHBzOi8vYXBpcy51cHMuY29tIiwidXVpZCI6IjJCMThEQkI0LUFBQUItMTVGQi05N0Q1LUNCQUQxRDg5OTc1QiIsInNpZCI6IjY0YzRiNjIwLTJmYWEtNDM1Ni1iMDQxLWYzYTBmMzZjYzFmYSIsImF1ZCI6IlJNQVRyYWNrZXIiLCJhdCI6ImFvQm4yZzA5U3dHdUx4Qk1vSzVMSEpDNjFmbEciLCJuYmYiOjE3MDA1NDA5ODYsInNjb3BlIjoiTG9jYXRvcldpZGdldCIsIkRpc3BsYXlOYW1lIjoiUk1BVHJhY2tlciIsImV4cCI6MTcwMDU1NTM4NiwiaWF0IjoxNzAwNTQwOTg2LCJqdGkiOiJjZmMzNzFkYy0wY2MwLTRhNTktODM1My03NWI4ODJlMzY1MjYifQ.KoYL5shMjrEj4FokfqWxH-Fvh8F76s1LfdfAq-jVSKy2qeKLqDH-m7iBC1qJgCpUA2lBnTUx7H7ErXHXPcqJTku6WGfTDnWxV14K7OMHWlQXtBRNEam2kR-9mqjd9cQeoXemSSEvC_AX6Zs9KLxGnlK02qfznhAqx4tMxgTz2Ip5lCxWLGBssl7sb3l0TCtiK5AGZU5LAwuEUI0nnoYWJewCO0nJQOzbNb1t-f3SrSqoOJNAi_tMIij17JVPlOBzOZPU-DizlEHbQVMwGWlBJx1AIMMwyAh8aD-gU9DA0S2F54-WnrkNvL3mSB1YDV5qY7tXCICOMf4H67vRS0WcuLgOtUZ7K_YiPoeK79JwKa6J2JvuANISTMkYwfAOX36m5EUrwi2sLPd7uaPqUr4VX5H-9Ko-CqR_VQZS81xB01dZMknoZSpJh54n4-oXOQJDKvnBpZzExbAI3stLdSNS3qVwnapCKd3HvK4iS5uDph_qlQKXCphXq1wC4s3mOMy9wSJfCzE1BD5zllB0o2FOP8Nb5RqzkE8R3NJ3ohPn-oaaBQndHL0f7-ME-s58J7mKqc-v7qczkSKqQRetWGk467mQU8KVPk92_MCP_tS0IDAU9alT-I0RSfmYr2R46pM9FUZRsst4r2zICKXr1PKy40V1WWHxQCrHLOrH6k-LPlE";

//   const location = useLocation();
//   const [authorizationCode, setAuthorizationCode] = useState(null);
//   const getAuthorizationCode = () => {
//     const clientId = '5Kw3mUWKjhglknTFJ6SJaQKVzbKHNGICpf4MArUPNqqa7LbN';
//     const redirectUri = 'https://sixty-mangos-feel.loca.lt/009APITesting';
//     const responseType = 'code';
//     const authorizationURL = `https://wwwcie.ups.com/security/v1/oauth/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${encodeURIComponent(responseType)}`;

//       window.location.href = authorizationURL;
//   };

//   const [token, setToken]  = useState('');

//   async function fetchUPSToken() {
//     console.log("working");
//     try {
//       const getTokenFromStore = await DataStore.query(UPSToken);
//       if (getTokenFromStore[0]["token"]) {setToken(getTokenFromStore[0]["token"]);} 
//       else {console.log('No inventory data found.');}
//     }
//     catch (error) {console.error('Error fetching Inventory Data:', error);}
//   };

// useEffect(() => {fetchUPSToken();}, []);

//   //Checks for the authorization code in URL which is code
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const urlSearchParams = new URLSearchParams(location.search);
//         const code = urlSearchParams.get('code');

//         if (code) {
//           setAuthorizationCode(code);
//         }
//       } catch (error) {
//         console.error('Error during fetch:', error.message);
//       }
//     };

//     fetchData();
//   }, [location.search]);

//   const [trackingNumber, setTrackingNumber] = useState('');

//   const [tokenData, setTokenData] = useState(null);


//   const getToken = async (event) =>  {
//     event.preventDefault();
//     // try {
//     //   const query = await API.get("ups", "/getToken", {
//     //       queryStringParameters: {
//     //         authorization_code: authorizationCode,
//     //         redirect_uri: callBackURL,
//     //         get_url : "https://onlinetools.ups.com/security/v1/oauth/token"
//     //       }
//     //   });
//     //   setTokenData(query);
//     //   setToken(query['data']['access_token']);

//     //   try{
//     //     const deletequery = await DataStore.delete(UPSToken, Predicates.ALL);
//     //     console.log(deletequery);

//     //     const createquery = await DataStore.save(
//     //       new UPSToken({
//     //       token: query['data']['access_token']
//     //     }));
//     //     console.log(createquery);
//     //   }
//     //   catch(error)
//     //   {
//     //     console.log(error);
//     //   }

//     // }
//     //   catch(error)
//     //   {
//     //     console.error('Error fetching data:', error);

//     //   }
//   };

//   const [trackingData, setTrackingData] = useState(null);

//   const handleTrackingNumberSubmit = async (event) => {
//     event.preventDefault();
//     // try {
//     //   const query = await API.get("ups", "/getTracking", {
//     //       queryStringParameters: {
//     //         token: token,
//     //         tracking_number: trackingNumber,
//     //       }
//     //   });
//     //   setTrackingData(query);

//     // }
//     //   catch(error)
//     //   {
//     //     console.error('Error fetching data:', error);

//     //   }
//   };

//   const [callBackURL, setCallBackURL]  = useState(null);
//   const handleGetAuthorizationCode = async (event) => {
//     event.preventDefault();
//     const clientId = '5Kw3mUWKjhglknTFJ6SJaQKVzbKHNGICpf4MArUPNqqa7LbN';
//     const redirectUri = callBackURL;
//     const responseType = 'code';
//     const authorizationURL = `https://onlinetools.ups.com/security/v1/oauth/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${encodeURIComponent(responseType)}`;

//     window.location.href = authorizationURL;

//   }
//   const testDeleteAllTokens = async (event) => {
//     event.preventDefault();
//     const deletequery = await DataStore.delete(UPSToken, Predicates.ALL);
//     console.log(deletequery);

//   }

  
//   return (
    
//     <div>
//        <div>
//           <form onSubmit={testDeleteAllTokens}>
//             <button type="submit">Delete Tokens</button>
//           </form>
//         </div>
//         {token && (
//         <div>
//           <pre>{token}</pre>
//           </div>
//       )}

//       {trackingData && (
//         <div>
//           <pre>{JSON.stringify(trackingData, null, 2)}</pre>
//           </div>
//       )}
//       {token ? (
//         <div>
//           <form onSubmit={handleTrackingNumberSubmit}>
//             Enter Tracking Number:
//               <input
//                 type="text"
//                 name="trackingNumber" // Add a name attribute to the input field
//                 onChange={(e) => setTrackingNumber(e.target.value)}
//               />
//             <button type="submit">Check Tracking</button>

//           </form>
//         </div>
//       ) : (
//         <div>
//           {token && (
//             <div>
//               <pre>{JSON.stringify(token, null, 2)}</pre>
//             </div>
//           )}
//           {tokenData && (
//             <div>
//               <pre>{JSON.stringify(tokenData, null, 2)}</pre>
//             </div>
//           )}
//           {authorizationCode ? (
//             <div>
//               <p>Authorization Code: {authorizationCode}</p>
//               <form onSubmit={getToken}>
//                   Enter CallbackURL:
//                   <input
//                     type="text"
//                     name="callBackURL" // Add a name attribute to the input field
//                     onChange={(e) => setCallBackURL(e.target.value)}
//                   />
//                 <button type="submit">Authorize with UPS</button>

//               </form>
//             </div>
//           ) : (
//             <div>
//                 <p>No authorization code set. </p>

//               <form onSubmit={handleGetAuthorizationCode}>
//                   Enter CallbackURL:
//                   <input
//                     type="text"
//                     name="callBackURL" // Add a name attribute to the input field
//                     onChange={(e) => setCallBackURL(e.target.value)}
//                   />
//                               <button type="submit">Authorize with UPS</button>

//               </form>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

export default APITesting;