import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';

import React, { useEffect, useState } from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import awsExports from "../../aws-exports";
import { LoginInformation } from '../../models';
import { DataStore } from '@aws-amplify/datastore';

Amplify.configure(awsExports);

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();


  //Variable for userInformation
  const [userInformation, setUserInformation] = useState([]);

  //Variable for selectedUsers
  const [selectedUserIds, setSelectedUserIds] = useState([]);



  const handleDeleteSelectedUsers = async () => {
    // Ensure that there are selected users to delete
    if (selectedUserIds.length === 0) {
      return;
    }
  
    // Perform deletion logic here
    try {
      // Delete the selected users based on their IDs
      await Promise.all(
        selectedUserIds.map(async (userId) => {
          await DataStore.delete(LoginInformation, userId);
        })
      );
  
      // After successful deletion, update the userInformation state
      const updatedUserInformation = userInformation.filter(
        (user) => !selectedUserIds.includes(user.id)
      );
  
      setUserInformation(updatedUserInformation);
  
      // Clear the selectedUserIds state
      setSelectedUserIds([]);
  
      console.log('Selected users deleted successfully');
    } catch (error) {
      console.error('Error deleting selected users:', error);
    }
  };


  const handleUsernameClick = (username) => {
    const redirectPath = `/displayuser/${username}`;
    navigate(redirectPath);
  };


  async function fetchLoginInformation() {
    try {
      const loginInformation = await DataStore.query(LoginInformation);
      console.log(loginInformation);
      if (loginInformation) {
        setUserInformation(loginInformation);
        console.log('Login Information:', loginInformation);
      } else {
        console.log('Login Information not found.');
      }
    } 
    catch (error) {console.error('Error fetching Login Information:', error);}
  };

    useEffect(() => {
      fetchLoginInformation();
    }, []);


  const columns = [

    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (params) => (
        <button
          onClick={() => handleUsernameClick(params.row.username)}
        >
          {params.value}
        </button>
      ),
    },
    {
      field: "privilege",
      headerName: "privilege",
      flex: 1,
      cellClassName: "privilege-column--cell",
    },
   
  ];

  return (
    <Box m="20px">
      <Header title="Users" subtitle="Manage Users" />
      <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelectedUsers}
          disabled={selectedUserIds.length === 0}
        >
          Delete Selected Users
        </Button>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
        }}
      >
        
        <DataGrid 
          checkboxSelection
          rows={userInformation}
          columns={columns} 
          getRowId={(row) => row.username}

          onRowSelectionModelChange={(newSelection) => {
            setSelectedUserIds(newSelection);
          }}
          selectionModel={selectedUserIds}

        
        />
      </Box>
    </Box>
  );
};

export default Team;
