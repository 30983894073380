import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { get, post } from 'aws-amplify/api';
import { zendeskMockData } from "./zendeskMockData";
import { salesforceMockData } from "./salesforceMockData";

//Currently pulling from MockData. Once we go to production, open API to pull information or only return true false with org id values
//Need to set up create Organization or create User, this will need to open API. Danger? If instant, can change to internal manual approval or pass to datastore.
//User email must match domain exact? Issues with if domain is not correct in salesforce

const PublicHome = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [companyValue, setCompanyValue] = useState("");
  const [domainValue, setDomainValue] = useState("");
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showUserCreated, setShowUserCreated] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [zendeskID, setZendeskID] = useState("");

  const [searchType, setSearchType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function testZendeskAPI() {
    try {
      let restOperation = get({
        apiName: 'publicZendeskAPI',
        path: '/getOrganization',
        options:{
          queryParams:{
            searchValue: "stellarcyber.ai",
            searchType: "domainName",
        }}
      });
      const {body} = await restOperation.response;
      let json = await body.json();
      console.log(json);        
    } catch (error) {
      console.log('GET call failed: ', error);
    }
  }

  async function handleFormSubmitCompanySearch(event) {
    event.preventDefault();

    if(!searchValue)
    {
      setErrorMessage("Please enter either a company name or a domain name.");
      return;
    }
    setErrorMessage("");


    if(searchType == 'companyName')
    {
      setSearchValue(searchValue.replace(/[^a-zA-Z0-9]/g, "").toLowerCase());
    }

    try {
      let restOperation = get({
        apiName: 'publicZendeskAPI',
        path: '/getOrganization',
        options:{
          queryParams:{
            searchValue: searchValue,
            searchType: searchType,
        }}
      });
      const {body} = await restOperation.response;
      let json = await body.json();
      console.log(json);

      if(json.organizationID)
      {
        setZendeskID(json.organizationID); 
      }
    } catch (error) {
      console.log('GET call failed: ', error);
    }

    if(zendeskID)
    {
      setShowEmailForm(true);
      console.log(zendeskID);
      return;
    }
    else
    {
      setErrorMessage("Will need to create API for Salesforce");
      return;
    }
    //Else if run to salesforce to check for organizations




  
    // setErrorMessage("");
  
    // try {
    //   let foundOrganization = [];
    //   if(searchType == 'companyName')
    //   {
    //     foundOrganization = Array.from(zendeskMockData).find(item => item.companyName === searchValue);
    //   }
    //   else if(searchType == 'domainName')
    //   {
    //     foundOrganization = Array.from(zendeskMockData).find(item => item.domainName === searchValue);
    //   }
    //   if (foundOrganization) {
    //     console.log("Found:", foundOrganization);
    //     setZendeskID(foundOrganization.companyID);
    //     setShowEmailForm(true);
    //     return;
    //   }
    // } catch (error) {console.error(error);}

    // try{
    //   let foundOrganization = [];

    //   if(searchType == 'companyName')
    //   {
    //     foundOrganization = Array.from(salesforceMockData).find(item => item.companyName === searchValue);
    //   }
    //   else if(searchType == 'domainName')
    //   {
    //     foundOrganization = Array.from(salesforceMockData).find(item => item.domainName === searchValue);
    //   }
    //   if (foundOrganization) {
    //     setErrorMessage("WILL NEED TO CREATE ORG IN ZENDESK", foundOrganization);
    //     setZendeskID(foundOrganization.companyID);
    //     setShowEmailForm(true);
    //     return;
    //   }
    //   else
    //   {
    //     setErrorMessage("Company or Domain is not found, please try again.");
    //     return;
    //   }
    //   setErrorMessage("");

    // } catch (error) {console.error(error);}
  }

  


  async function handleEmailFormSubmit(event) {
    event.preventDefault();

    try {
      let restOperation = post({
        apiName: 'publicZendeskAPI',
        path: '/createUser',
        options:{
          queryParams:{
            userName: name,
            userEmail: email,
            organizationID: zendeskID,
        }}
      });
      const {body} = await restOperation.response;
      let json = await body.json();
      console.log(json);
    } catch (error) {
      console.log('GET call failed: ', error);
    }

    
    setCompanyValue("");
    setDomainValue("");
    setEmail("");
    setName("");
    // Reset showEmailForm state
    setShowEmailForm(false);
    setShowUserCreated(true);
  }

  const handleChangeField = (event) => {
    const { name, value } = event.target;
    if(name == "companyValue")
    {
      setCompanyValue(value);
      setSearchType("companyName");
      setSearchValue(value);
    }
    else if(name == "domainValue")
    {
      setDomainValue(value);
      setSearchType("domainName");
      setSearchValue(value);
    }
  };


  return (
    <Box m="20px" textAlign="center">
      <Button
    variant="contained"
    color="secondary"
    onClick={() => testZendeskAPI()}
    >Test Zendesk API</Button>
      <Typography variant="h1" gutterBottom>
        Create a user for Stellar Cyber's Zendesk
      </Typography>


      {!showEmailForm && !showUserCreated &&(
        <form onSubmit={handleFormSubmitCompanySearch}>
          <h3>Please enter in your company's name or domain name.</h3>
          <h3>Please only enter one.</h3>
          <Box m="20px">
            <TextField fullWidth type="text" label="Enter Company Name" value={companyValue} name="companyValue"
              onChange={handleChangeField} sx={{ gridColumn: "span 1" , marginBottom: 2}}/>
            <TextField fullWidth type="text" label="Enter Domain Name" value={domainValue} name="domainValue"
              onChange={handleChangeField} sx={{ gridColumn: "span 1" , marginBottom: 2}}/>
            <Button type="submit" color="secondary" variant="contained">
              Search
            </Button>
          </Box>
          {errorMessage && (<p color='red'> {errorMessage}</p>)}
        </form>
      )}

      {showEmailForm && !showUserCreated && (
        <form onSubmit={handleEmailFormSubmit}>
        {errorMessage && (<p color='red'> {errorMessage}</p>)}

          <Box m="20px">
            <TextField
              label="Enter New User Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Enter User Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Button type="submit" color="secondary" variant="contained">
              Submit Email and Name
            </Button>
          </Box>
        </form>
      )}

    {!showEmailForm && showUserCreated &&(
    <h1>User has been created</h1>
    )}
    </Box>
  );
};

export default PublicHome;
