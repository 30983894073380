import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';
import { Box, Typography, useTheme, Button } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { get } from 'aws-amplify/api';



Amplify.configure(awsExports); // Configure Amplify with your project settings

const SFtoZSyncQuote = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(true);

    const [salesforceData, setSalesforceData] = useState([]);
    const [zendeskData, setZendeskData] = useState([]);



const fetchSalesforceData = async () => {
    try {
        const queryDataSF = get({
            apiName: 'salesforceapi',
            path: '/getQuery',
            options: {
                queryParams: {
                    queryArrayItems: "Name, Type, Website, ID",
                    queryObject: "Account"
                }
            }
        });
        const { body } = await queryDataSF.response;
        const json = await body.json();
        if (json.result.records && json.result.records.length > 0) 
        {
            const uniqueNames = new Set();
            json.result.records.forEach((record) => 
            {
              
              if(record['Type'] != null && record['Type'] != 'Prospect')
              {
                const website = record['Website']  || '';
                const companyName = record['Name'].replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
                const companyID = record['Id'];

                if (website != null && website != '') {
                  const withoutProtocol = website.replace(/^https?:\/\//, "");
                  const domainWithoutPath = withoutProtocol.split('/')[0];
                  const domainWithoutWWW = domainWithoutPath.replace(/^(www\.)/, "");
                  const domainName = domainWithoutWWW;

                  uniqueNames.add({ companyName, domainName, companyID });
                }
                else
                {
                  const domainName = '';
                  uniqueNames.add({ companyName, domainName, companyID });
                }
               
              }
            });
            const sortedSalesforceData = Array.from(uniqueNames).sort((a, b) => a.companyName.localeCompare(b.companyName));

            setSalesforceData(sortedSalesforceData);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
}
const fetchZendeskData = async () => {
      try {
          const uniqueNames = new Set();
          let queryDataZendesk = get({
            apiName: 'zendeskapi',
            path: '/getOrganizations',
            options:{
              queryParams:{
                page: "1"
            }
            }
          });
          let { body } = await queryDataZendesk.response;
          let json = await body.json();

          if (json.data.organizations && json.data.organizations.length > 0) {
              json.data.organizations.forEach((record) => {
                  const companyName = record['name'].replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
                  const domainName = record['domain_names'];
                  const companyID = record['id'];
                  uniqueNames.add({companyName, domainName, companyID});
              });
          }

          let istheredata = json.data.organizations.length;
          let pagecount = 2;
          while(istheredata > 0)
          {
              let queryDataZendesk = get({
                apiName: 'zendeskapi',
                path: '/getOrganizations',
                options:{
                  queryParams:{
                    page: pagecount
  
                }
                }
              });
              let { body } = await queryDataZendesk.response;
              let json = await body.json();
              if (json.data.organizations && json.data.organizations.length > 0) {
                  json.data.organizations.forEach((record) => {
                      const companyName = record['name'].replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
                      const domainName = record['domain_names'];
                      const companyID = record['id'];
                      uniqueNames.add({companyName, domainName, companyID});
                  });
              }

              pagecount++;
              istheredata = json.data.organizations.length;
          }

          const sortedZendeskNames = Array.from(uniqueNames).sort((a, b) => a.companyName.localeCompare(b.companyName));

          setZendeskData(sortedZendeskNames);

      }catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }

  }
  
useEffect(() => {
    // Call the fetchSalesforceData function when the component mounts
    fetchSalesforceData();
    fetchZendeskData();
}, []); // The empty dependency array ensures that this useEffect runs only once

const [newList, setNewList] = useState([]);
const [deleteList, setDeleteList] = useState([]);
const [currentList, setCurrentList] = useState([]);
const [displayType, setDisplayType] = useState("New");

const compareData = () => {
  if (salesforceData && zendeskData) {
      const sfItems = salesforceData;
      const zendeskItems = zendeskData;

      const newItems = sfItems.filter((sfItem) => {
          // Assuming sfItem is an object with companyName and domain properties
          return !zendeskItems.some((zdItem) =>
              zdItem.companyName === sfItem.companyName
          );
      });

      const deleteItems = zendeskItems.filter((zdItem) => {
          // Assuming zdItem is an object with companyName and domain properties
          return !sfItems.some((sfItem) =>
              sfItem.companyName === zdItem.companyName 
          );
      });

      const currentItems = sfItems.filter((sfItem) => {
          // Assuming sfItem is an object with companyName and domain properties
          return zendeskItems.some((zdItem) =>
              zdItem.companyName === sfItem.companyName
          );
      });

      setNewList(newItems);
      setDeleteList(deleteItems);
      setCurrentList(currentItems);
  }
};

  const fetchData = async () => {
      try {
          const sfQuery = await fetchSalesforceData();
          const zQuery = await fetchZendeskData();
          setLoading(false);
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }
  }
  const handleToggleDisplay = (type) => {
    setDisplayType(type);
    };

      useEffect(() => {
      compareData();
  }, [salesforceData, zendeskData]);

const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetchData();
}

const columns = [
        {
          field: "companyName", // Change "names" to "name" to match the property name in your data objects
          headerName: "Name", // Specify the header text for the "name" field
          flex: 1,
        },
        {
          field: "domainName", // Change "names" to "name" to match the property name in your data objects
          headerName: "Domain", // Specify the header text for the "name" field
          flex: 1,
        },
        {
          field: "companyID", // Change "names" to "name" to match the property name in your data objects
          headerName: "Company ID", // Specify the header text for the "name" field
          flex: 1,
        }
    ];
  
let rows;
    switch (displayType) {
        case "New":
        rows = newList;
        break;
        case "Delete":
        rows = deleteList;
        break;
        case "Current":
        rows = currentList;
        break;
        case "SalesForce":
        rows = salesforceData;
        break;
        case "Zendesk":
        rows = zendeskData;
        break;
        default:
        rows = newList;
        break;
    }

  
  

return (
  
<Box m="20px">


  <Button variant="contained" color="secondary" onClick={handleFormSubmit}>
    Get Data
  </Button>

  <Box m="20px">
    <Button variant="contained" color="secondary" disabled={displayType === "New"}onClick={() => handleToggleDisplay("orders")}
    >
    Show New
    </Button>
    <Button
    variant="contained"
    color="secondary"
    disabled={displayType === "Delete"}
    onClick={() => handleToggleDisplay("Delete")}
    >
    Show Delete
    </Button>
    <Button
    variant="contained"
    color="secondary"
    disabled={displayType === "Current"}
    onClick={() => handleToggleDisplay("Current")}
    >
    Show Current
    </Button>
    <Button
    variant="contained"
    color="secondary"
    disabled={displayType === "SalesForce"}
    onClick={() => handleToggleDisplay("SalesForce")}
    >
    Show SalesForce
    </Button>
    <Button
    variant="contained"
    color="secondary"
    disabled={displayType === "Zendesk"}
    onClick={() => handleToggleDisplay("Zendesk")}
    >
    Show Zendesk
    </Button>
  </Box>

      <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[1],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[1],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[1],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[1],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[1]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[1]} !important`,
        },
      }}
    >
     <DataGrid 
  checkboxSelection
  rows={rows}
  columns={columns}
  components={{ Toolbar: GridToolbar }}
  getRowId={(row) => row.companyID}
/>

    </Box>


    <pre>
  {JSON.stringify(salesforceData, null, 2)}
</pre>


</Box>


    
)

}

export default SFtoZSyncQuote;
