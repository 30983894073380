import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import CreateUser from "./scenes/createuser";
import ExportedInventory from "./scenes/0exportedinventory";
import ImportedInventory from "./scenes/0importedinventory";
import ExportedOrders from "./scenes/0exportedorders";
import ImportedOrders from "./scenes/0importedorders";
import DisplayOrderInformation from "./scenes/displayorderinformation";
import DisplayUser from "./scenes/displayuser";
import EditOrderInformation from "./scenes/editorderinformation";
import Salesforce from "./scenes/2salesforce";
import SalesforceLogin from "./scenes/2salesforcelogin";
import CreateOrder from "./scenes/001createorder"
import SoftwarePartNames from "./scenes/002softwarepartnames"
import UPS from "./scenes/007UPS"
import APITesting from "./scenes/009APITesting"
import APITestPage from "./scenes/0091APITestPage"
import ViewOTP from "./scenes/003viewOTP"
import PDFCreate from "./scenes/006PDFCreate"
import SFtoZSync from "./scenes/008SFtoZSync"
import SFtoZSyncQuote from "./scenes/008SFtoZSyncQuote"
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import PublicHome from "./scenes/publichome";
import CompanyView from './scenes/002companyview';
import LicenseView from './scenes/002displayorder';

import ProductDisplay from './scenes/000productdisplay';
import ProductCreate from './scenes/000productcreate';


import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

Amplify.configure(config);

function App({ signOut, user }) {
  const location = useLocation();  // Use useLocation hook

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);


  return (
    <ColorModeContext.Provider value={colorMode}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={theme}>
    <CssBaseline />

    {location.pathname == "/publichome" && (
    <main className="content">
      <Routes>
        <Route path="/publichome" element={<PublicHome />} />
      </Routes>
    </main>

    )}

    {location.pathname !== "/publichome" && (

    <Authenticator>
      {({ signOut, user }) => (
        <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/000productdisplay" element={<ProductDisplay />} />
            <Route path="/000productcreate" element={<ProductCreate />} />
            <Route path="/002companyview" element={<CompanyView />} />
            <Route path="/002displayorder" element={<LicenseView />} />

            <Route path="/000productcreate" element={<ProductCreate />} />

            <Route path="/001createorder" element={<CreateOrder/>} />
            <Route path="/0exportedinventory" element={<ExportedInventory />} />
            <Route path="/0importedinventory" element={<ImportedInventory />} />
            <Route path="/006PDFCreate" element={<PDFCreate />} />

            <Route path="/0exportedorders" element={<ExportedOrders />} />
            <Route path="/0importedorders" element={<ImportedOrders />} />

            <Route path="/displayorderinformation/:internalOrderNumber" element={<DisplayOrderInformation />} />
            <Route path="/editorderinformation/:internalOrderNumber" element={<EditOrderInformation />} />

            {/* <Route path="/editorderinformation/:internalOrderNumber" element={isAuthorized(userdata.privilege, "admin") ? (<EditOrderInformation />) : (<DisplayOrderInformation />)}/> */}
            {/* <Route path="/displayuser/:username"element={isAuthorized(userdata.privilege, "admin") ? (<DisplayUser />) : (<Dashboard />)}/> */}
            <Route path="/displayuser/:username" element={<DisplayUser/>} />

            {/* <Route path="/team" element={isAuthorized(userdata.privilege, "admin") ? (<Team />) : (<Dashboard />)}/> */}
            <Route path="/team" element={<Team/>} />

            <Route path="/002softwarepartnames" element={<SoftwarePartNames />} />

            <Route path="/003viewOTP" element={<ViewOTP />} />


            <Route path="/2salesforce" element={<Salesforce />} />
            <Route path="/2salesforcelogin" element={<SalesforceLogin />} />




            <Route path="/008SFtoZSync" element={<SFtoZSync />} />
            <Route path="/008SFtoZSyncQuote" element={<SFtoZSyncQuote />} />

            <Route path="/007UPS" element={<UPS />} />


            <Route path="/009APITesting" element={<APITesting />} />
            <Route path="/0091APITestPage" element={<APITestPage />} />


            <Route path="/contacts" element={<Contacts />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/form" element={<Form />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/line" element={<Line />} />
            <Route path="/faq" element={<FAQ />} />
            {/* <Route path="/calendar" element={<Calendar />} /> */}
            <Route path="/geography" element={<Geography />} />
          </Routes>
          {user && (
            <div>
              <p>Authenticated User: {user.username}</p>
            </div>
          )}
        </main>
      </div>
      )}

    </Authenticator>
     )}


    </ThemeProvider>
    </LocalizationProvider>
    
  </ColorModeContext.Provider>
  
  );
}

export default App;

// import { useState, useEffect } from "react";



// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";
// // import Calendar from "./scenes/calendar/calendar";

// import { Amplify } from 'aws-amplify'

// import { LoginInformation } from './models';
// import { DataStore } from "@aws-amplify/datastore";
// import awsExports from "./aws-exports";

// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// Amplify.configure(awsExports);


// function App() {
  
//   const [theme, colorMode] = useMode();
//   const [isSidebar, setIsSidebar] = useState(true);
//   const [user, setUser] = useState(null); // State to store the authenticated user
//   const location = useLocation();

//       useEffect(() => {
//         Auth.currentAuthenticatedUser()
//           .then((user) => {
//             // Set the user in the state
//             setUser(user);
      
//             // Fetch user data only if the user is authenticated
//             getuserData(user);
//           })
//           .catch((err) => {
//             // Handle the error if the user is not authenticated
//             setUser(null);
//           });
//       }, [location.pathname]);


//       const [userdata, setUserData] = useState({
//         username: "",
//         privilege: "",
//         name: "",
//         title: "",
//         email: "",
//         slackid: "",
//         salesforce_name: "",
//         se_support: "",
//       });

//       useEffect(() => {getuserData();}, []);


//     async function getuserData(user) {
//       if (user) {
//         const awsuserdata = await DataStore.query(LoginInformation, (order) =>
//           order.username.eq(user.username)
//         );

//         if (awsuserdata.length > 0) {
//           // Update the state with the fetched user data
//           setUserData(awsuserdata[0]);
//         }
//       }
//     }
//     const isAuthorized = (userPrivilege, requiredPrivilege) => {
//       // Check if the user's privilege matches the required privilege (e.g., "admin")
//       return userPrivilege === requiredPrivilege;
//     };

//   return (
    
    // <ColorModeContext.Provider value={colorMode}>
      
    //   <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <ThemeProvider theme={theme}>
    //     <CssBaseline />
       
    //     <div className="app">
    //       <Sidebar isSidebar={isSidebar} />
    //       <main className="content">
    //         <Topbar setIsSidebar={setIsSidebar} />
    //         <Routes>
    //           <Route path="/dashboard" element={<Dashboard />} />
    //           <Route path="/001createorder" element={<CreateOrder/>} />
    //           <Route path="/0exportedinventory" element={<ExportedInventory />} />
    //           <Route path="/0importedinventory" element={<ImportedInventory />} />
    //           <Route path="/006PDFCreate" element={<PDFCreate />} />

    //           <Route path="/0exportedorders" element={<ExportedOrders />} />
    //           <Route path="/0importedorders" element={<ImportedOrders />} />

    //           <Route path="/displayorderinformation/:internalOrderNumber" element={<DisplayOrderInformation />} />
    //           <Route path="/editorderinformation/:internalOrderNumber" element={isAuthorized(userdata.privilege, "admin") ? (<EditOrderInformation />) : (<DisplayOrderInformation />)}/>
    //           {/* <Route path="/displayuser/:username"element={isAuthorized(userdata.privilege, "admin") ? (<DisplayUser />) : (<Dashboard />)}/> */}
    //           <Route path="/displayuser/:username" element={<DisplayUser/>} />

    //           {/* <Route path="/team" element={isAuthorized(userdata.privilege, "admin") ? (<Team />) : (<Dashboard />)}/> */}
    //           <Route path="/team" element={<Team/>} />

    //           <Route path="/002softwarepartnames" element={<SoftwarePartNames />} />

    //           <Route path="/003viewOTP" element={<ViewOTP />} />


    //           <Route path="/2salesforce" element={<Salesforce />} />
    //           <Route path="/2salesforcelogin" element={<SalesforceLogin />} />




    //           <Route path="/008SFtoZSync" element={<SFtoZSync />} />
    //           <Route path="/008SFtoZSyncQuote" element={<SFtoZSyncQuote />} />

    //           <Route path="/007UPS" element={<UPS />} />


    //           <Route path="/009APITesting" element={<APITesting />} />
    //           <Route path="/0091APITestPage" element={<APITestPage />} />


    //           <Route path="/contacts" element={<Contacts />} />
    //           <Route path="/createuser" element={<CreateUser />} />
    //           <Route path="/invoices" element={<Invoices />} />
    //           <Route path="/form" element={<Form />} />
    //           <Route path="/bar" element={<Bar />} />
    //           <Route path="/pie" element={<Pie />} />
    //           <Route path="/line" element={<Line />} />
    //           <Route path="/faq" element={<FAQ />} />
    //           {/* <Route path="/calendar" element={<Calendar />} /> */}
    //           <Route path="/geography" element={<Geography />} />
    //         </Routes>
    //         {user && (
    //           <div>
    //             {/* Display the authenticated user's username */}
    //             <p>Authenticated User: {user.username}</p>
    //           </div>
    //         )}
    //       </main>
    //     </div>
    //   </ThemeProvider>
    //   </LocalizationProvider>

    // </ColorModeContext.Provider>
//   );
// }

// // export default App;
// export default withAuthenticator(App);