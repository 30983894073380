import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import { DataStore } from '@aws-amplify/datastore';
import { UPSToken, UPSTrackingData } from '../../models';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Token } from '@mui/icons-material';


Amplify.configure(awsExports);



const UPS = () => {
    const [token, setToken]  = useState('');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");  

    async function fetchUPSToken() {
      try {
        const getTokenFromStore = await DataStore.query(UPSToken);
        if (getTokenFromStore[0]["token"]) {setToken(getTokenFromStore[0]["token"]);} 
        else {console.log('No inventory data found.');}
      }
      catch (error) {console.error('Error fetching Inventory Data:', error);}
    };
  
    useEffect(() => {fetchUPSToken();}, []);


    const [trackingData, setTrackingData] = useState([]);


    async function fetchData(){
        const dataquery = await DataStore.query(UPSTrackingData);
        setTrackingData(dataquery);
    }
    useEffect(() => {fetchData();}, []);

    
    const columns = [{field: "tracking_number",headerName: "Tracking Number",flex: 2,},
    {field: "DEL",headerName: "Delivered",flex: 1,},
    {field: "SDD",headerName: "Expected Delivery Date",flex: 1,},
    {field: "latest_activity",headerName: "Latest Activity",flex: 1,},
    {field: "packageAddress_latest",headerName: "Latest Address",flex: 2,},
    {field: "packageAddress_to",headerName: "To Address",flex: 2,},
    {field: "packageAddress_from",headerName: "From Address",flex: 2,},];


    const submitTestData = async (event) => {
        const testQuery = await DataStore.save(
            new UPSTrackingData({
                "tracking_number": "Lorem ipsum dolor sit amet",
                "SDD": "Lorem ipsum dolor sit amet",
                "latest_activity": "Lorem ipsum dolor sit amet",
                "packageAddress_from": "Lorem ipsum dolor sit amet",
                "packageAddress_to": "Lorem ipsum dolor sit amet",
                "packageAddress_latest": "Lorem ipsum dolor sit amet",
                "currentStatus": "Lorem ipsum dolor sit amet",
                "DEL": "Lorem ipsum dolor sit amet"
            })
        );
        console.log(testQuery);
        window.location.reload();

      }

    const [selectedData, setSelectedData] = useState([]);
 
    const handleDeleteSectedData  = async () => {
        // Ensure that there are selected users to delete
        if (selectedData.length === 0) {
        return;
        }
    
        // Perform deletion logic here
        try {
        // Delete the selected users based on their IDs
        await Promise.all(
            selectedData.map(async (queryItem) => {
            await DataStore.delete(UPSTrackingData, queryItem);
            })
        );
    
        // After successful deletion, update the userInformation state
        const updatedSelectedData = trackingData.filter(
            (queryItem) => !selectedData.includes(queryItem.id)
        );
    
        setTrackingData(updatedSelectedData);
    
        // Clear the selectedUserIds state
        setSelectedData([]);
    
        } catch (error) {
            console.log(error);
        }
    };
    const [dataTest, setDataTest] = useState('');


    const [trackingNumber, setTrackingNumber] = useState('');


    const [trackingNumberInput, settrackingNumberInput] = useState('');


    const handleTrackingNumberSubmit = async (event) => {
        event.preventDefault();
        let trackingDataResponse;
        let SDD;
        let DEL;
        let latestActivity;
        let addressFrom;
        let addressTo;
        let addressLatest;
        let currentStatus;
        // try {
        //   const query = await API.get("ups", "/getTracking", {
        //       queryStringParameters: {
        //         token: token,
        //         tracking_number: trackingNumber,
        //       }
        //   });
        //   trackingDataResponse = query;



        // if(query["data"]["trackResponse"]["shipment"][0]["package"][0]) {trackingDataResponse = (query["data"]["trackResponse"]["shipment"][0]["package"][0])}
        // else
        // {
        //     if(query["data"]["trackResponse"]["shipment"][0]["package"]){trackingDataResponse = (query["data"]["trackResponse"]["shipment"][0]["package"]);}
        //     else{trackingDataResponse = "ISSUE WITH DATA";}
        // }
        // setDataTest(trackingDataResponse);



        // if(trackingDataResponse['trackingNumber']){settrackingNumberInput(trackingNumber);}
        // else{settrackingNumberInput("NOT FOUND");}


        // if( trackingDataResponse['deliveryDate'][0]['date'])
        // {
        //     if(trackingDataResponse['deliveryDate'][0]['type'] == "DEL")
        //     {
        //         DEL = "yes";
        //         SDD = (trackingDataResponse['deliveryDate'][0]['date']);
        //     }
        //     else
        //     {
        //         SDD = (trackingDataResponse['deliveryDate'][0]['date']);
        //     }
        // }
        // else{console.log("THIS IS NOT WORKING DD");}

        // if(trackingDataResponse['packageAddress'])
        // {
        //     if(trackingDataResponse['packageAddress'][0] && trackingDataResponse['packageAddress'][0]["type"] == "ORIGIN")
        //     {
        //         console.log(trackingDataResponse['packageAddress'][0] );
        //         addressFrom = trackingDataResponse['packageAddress'][0]['address']['city'] + ", " + trackingDataResponse['packageAddress'][0]['address']['stateProvince'] + ", " + trackingDataResponse['packageAddress'][0]['address']['countryCode'];
        //     }

        //     if(trackingDataResponse['packageAddress'][1] && trackingDataResponse['packageAddress'][1]["type"] == "DESTINATION")
        //     {
        //         console.log(trackingDataResponse['packageAddress'][1] );

        //         addressTo = trackingDataResponse['packageAddress'][1]['address']['city'] + ", " + trackingDataResponse['packageAddress'][1]['address']['stateProvince'] + ", " + trackingDataResponse['packageAddress'][1]['address']['countryCode'];
        //     }
        // }



        // if(trackingDataResponse['activity'][0])
        // {
        //     latestActivity = (trackingDataResponse['activity'][0]['status']['description']);
        //     addressLatest = trackingDataResponse['activity'][0]['location']['address']['city'] + ", " + trackingDataResponse['activity'][0]['location']['address']['stateProvince'] + ", " + trackingDataResponse['activity'][0]['location']['address']['countryCode'];
        // }
        // else {latestActivity = ("THIS IS NOT WORKING Last Activity");}

        // if(trackingDataResponse['currentStatus'])
        // {
        //     currentStatus = trackingDataResponse['currentStatus']['description'];
        // }




        // console.log("Tracking Number: ", trackingNumber);
        // console.log("SDD: ", SDD);
        // console.log("latest_activity:", latestActivity);
        // console.log("DEL:", DEL);
        // console.log("FROM ADDRESS:", addressFrom);
        // console.log("TO ADDRESS:", addressTo);
        // console.log("Latest Address:", addressLatest);
        // console.log("Current Status:", currentStatus);





        // // console.log("packageAddress_from:", getAddressString(trackingDataResponse['packageAddress'][0]?.['address']));
        // // console.log("packageAddress_to:", getAddressString(trackingDataResponse['packageAddress'][1]?.['address']));
        // // console.log("packageAddress_latest:", getAddressString(trackingDataResponse['activity'][0]?.['location']?.['address']));
        // // console.log("currentStatus:", trackingDataResponse['currentStatus']?.['description']);
        // // console.log("DEL:", trackingDataResponse['deliveryDate']?.['type']);
          
        // //   const testQuery = await DataStore.save(
        // //     new UPSTrackingData({
        // //         tracking_number: trackingDataResponse['trackingNumber'],
        // //         SDD: trackingDataResponse['deliveryDate']?.['date'],
        // //         latest_activity: trackingDataResponse['activity']?.[0]?.['status']?.['description'],
        // //         packageAddress_from: trackingDataResponse['packageAddress'][0]?.['address']?.['city'] + " " + trackingDataResponse['packageAddress'][0]?.['address']?.['stateProvince'] + " " + trackingDataResponse['packageAddress'][0]?.['address']?.['countryCode'],
        // //         packageAddress_to: trackingDataResponse['packageAddress'][1]?.['address']?.['city'] + " " + trackingDataResponse['packageAddress'][1]?.['address']?.['stateProvince'] + " " + trackingDataResponse['packageAddress'][1]?.['address']?.['countryCode'],
        // //         packageAddress_latest: trackingDataResponse['activity'][0]?.['location']?.['address']?.['city'] + " " + trackingDataResponse['activity'][0]?.['location']?.['address']?.['stateProvince'] + " " + trackingDataResponse['activity'][0]?.['location']?.['address']?.['countryCode'],
        // //         currentStatus: trackingDataResponse['currentStatus']?.['description'],
        // //         DEL: trackingDataResponse['deliveryDate']?.['type']
        // //     })
        // // );
        // // console.log(testQuery);
        // // window.location.reload();
    
        // }
        //   catch(error)
        //   {
        //     console.error('Error fetching data:', error);
    
        //   }
      };

      // Function to concatenate address properties
function getAddressString(address) {
    return address
      ? `${address?.['city']} ${address?.['stateProvince']} ${address?.['countryCode']}`
      : "N/A";
  }



  return(
    <Box>
        <Box>
            <Button variant="contained" color="secondary" onClick={submitTestData}>Submit Test Data</Button>

            <Button variant="contained" color="secondary" onClick={handleDeleteSectedData}>Delete Selected Items</Button>

        </Box>
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" 
        sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
        <form onSubmit={handleTrackingNumberSubmit}>
        <TextField fullWidth variant="filled" type="text" label="Tracking Number" value={trackingNumber || " "} name="trackingNumber"
            onChange={(e) => setTrackingNumber(e.target.value)} sx={{ gridColumn: "span 1" }} />
                    
        <Box m="20px">
            <Button type="submit" color="secondary" variant="contained"> Add Tracking </Button>
        </Box>

          </form>
        </Box>

        {dataTest && (
            <div>
                <pre>{JSON.stringify(dataTest, null, 2)}</pre>
          </div>
        )}

        <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none",
        },
        "& .name-column--cell": {
            color: colors.greenAccent[1],
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
        },
        }}
        >
        <DataGrid 
            checkboxSelection
            // rows={trackingData.map((tracking_number, id) => ({ id, tracking_number }))} // Convert the array of names into an array of objects with an 'id' and 'name' field.
            rows={trackingData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.id} // Define the 'id' field as the unique row identifier.
            onRowSelectionModelChange={(newSelection) => {
                setSelectedData(newSelection);
              }}
            selectionModel={setSelectedData}
        />
        </Box>

    </Box>
    

  );
  


}

export default UPS;
