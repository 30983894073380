import { Box, Typography, useTheme, Button, TextField,FormControl,InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers";


import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";

import { ImportedSoftwareParts } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";
import { useParams } from 'react-router-dom';

import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';

import dayjs from 'dayjs';

Amplify.configure(awsExports);

const SoftwarePartNames = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");  



    const [softwarePartsData, setSoftwarePartsData] = useState([]);

    async function fetchSoftwarePartsData() {
        try {
          const importedSoftwareParts = await DataStore.query(ImportedSoftwareParts);
          if (importedSoftwareParts) {setSoftwarePartsData(importedSoftwareParts);} 
          else {console.log('No inventory data found.');}
        }
        catch (error) {console.error('Error fetching Inventory Data:', error);}
      };
      
    // Calls the Fetch Inventory Data
    useEffect(() => {fetchSoftwarePartsData();}, []);





    const [selectedOrdersData, setSelectedOrdersData] = useState([]);

    const rows = softwarePartsData;
    const columns = [{field: "part_name",headerName: "Part Name",flex: 2,},
                    {field: "category_name",headerName: "Category Name",flex: 2,},
                    {field: "data_value",headerName: "Data Value",flex: 1,},
                    {field: "asset_value",headerName: "Asset Value",flex: 1,},
                    {field: "sensor_value",headerName: "Sensor Value",flex: 1,},
                    {field: "hot_storage",headerName: "Hot Storage",flex: 1,},
                    {field: "cold_storage",headerName: "Cold Storage",flex: 1,},];


    const [softwarePartFormData, setSoftwarePartFormData] = useState({});

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        // Update the form data state with the new value
        setSoftwarePartFormData((prevOrderInformation) => ({
            ...prevOrderInformation,
            [name]: value,
        }));
    };

    async function handleSubmitSoftwarePartName(event)
    {
        event.preventDefault(); // Prevent the default form submission behavior

        if(!softwarePartsData.some((item) => item.part_name === softwarePartFormData.part_name))
        {
            const createSoftwarePart = await DataStore.save(
                new ImportedSoftwareParts({
                    part_name: softwarePartFormData.part_name,
                    category_name: softwarePartFormData.category_name,
                    data_value: softwarePartFormData.data_value,
                    asset_value: softwarePartFormData.asset_value,
                    sensor_value: softwarePartFormData.sensor_value,
                    hot_storage: softwarePartFormData.hot_storage,
                    cold_storage: softwarePartFormData.cold_storage


                })
            );
            window.location.reload();

        }
        else(console.log("Part Name Exists"));
    }
    //Delete Selected Inventory Handler
    const handleDeleteSelected = async () => {
        // Ensure that there are selected users to delete
        if (selectedOrdersData.length === 0) {return;}
    
        // Perform deletion logic here
        try {
        // Delete the selected users based on their IDs
        await Promise.all(
            selectedOrdersData.map(async (item) => {
            await DataStore.delete(ImportedSoftwareParts, item);
            })
        );
    
        // After successful deletion, update the userInformation state
        const updatedIventoryData = softwarePartsData.filter(
            (iventoryItem) => !selectedOrdersData.includes(iventoryItem.id)
        );
    
        setSoftwarePartsData(updatedIventoryData);
    
        // Clear the selectedUserIds state
        setSelectedOrdersData([]);
    
        } catch (error) {
        }
    };



    return(

        <Box m="20px">
        <Header title="Software Parts" subtitle="these are software parts" />
            <Box m="20px">
                <form onSubmit={handleSubmitSoftwarePartName}>
                <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="text" label="Software Part Name" value={softwarePartFormData.part_name || ""} name="part_name"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="text" label="Category Name" value={softwarePartFormData.category_name || ""} name="category_name"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                </Box>
                <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="number" label="Data Value" value={softwarePartFormData.data_value || ""} name="data_value"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="number" label="Asset Value" value={softwarePartFormData.asset_value || ""} name="asset_value"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="number" label="Sensor Value" value={softwarePartFormData.sensor_value || ""} name="sensor_value"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                </Box>
                <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
                    <TextField fullWidth variant="filled" type="number" label="Hot Storage" value={softwarePartFormData.hot_storage || ""} name="hot_storage"
                            onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                    {/* Internal Order Number */}
                    <TextField fullWidth variant="filled" type="number" label="Cold Storage" value={softwarePartFormData.cold_storage || ""} name="cold_storage"
                        onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
                </Box>



                <Box m="30px">
                    <Button type="submit" color="secondary" variant="contained">
                    Create Order
                    </Button>
                </Box>

                </form>
            </Box>
            <Box m="20px">
            <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteSelected}
                disabled={selectedOrdersData.length === 0}
                >
                Delete Selected Items
            </Button>


            </Box>


            <Box m="40px 0 0 0" height="75vh"
                sx={{"& .MuiDataGrid-root": {border: "none",},
                    "& .MuiDataGrid-cell": {borderBottom: "none",},
                    "& .name-column--cell": {color: colors.greenAccent[1],},
                    "& .MuiDataGrid-columnHeaders": {backgroundColor: colors.blueAccent[1],borderBottom: "none",},
                    "& .MuiDataGrid-virtualScroller": {backgroundColor: colors.primary[1],},
                    "& .MuiDataGrid-footerContainer": {borderTop: "none",backgroundColor: colors.blueAccent[1],},
                    "& .MuiCheckbox-root": {color: `${colors.greenAccent[1]} !important`,},
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {color: `${colors.grey[1]} !important`,},
                }}>
    
            <DataGrid 
              checkboxSelection
              rows={rows}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedOrdersData(newSelection);
              }}
              selectionModel={selectedOrdersData}
            />
          </Box>
        </Box>
    
    )

};

export default SoftwarePartNames;


