import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useState } from "react";
import { exportedorders } from "./exportedorders";
import { exportedordershardware } from "./exportedordershardware";
import { exportedorderssoftware } from "./exportedorderssoftware";


import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify } from 'aws-amplify'
import awsExports from "../../aws-exports";
Amplify.configure(awsExports);



const Inventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrdersData, setSelectedOrdersData] = useState([]);

  const [displayType, setDisplayType] = useState("orders");



  let returnmessage = '';
  const handleTestImportToAWS = async () => {
    const returnmessage = await DataStore.save(
      new ImportedOrders({
      "internal_order_number": "Lorem ipsum dolor sit amet",
      "external_order_number": "Lorem ipsum dolor sit amet",
      "order_type": "Lorem ipsum dolor sit amet",
      "order_date": "Lorem ipsum dolor sit amet",
      "partner_name": "Lorem ipsum dolor sit amet",
      "end_customer_name": "Lorem ipsum dolor sit amet",
      "sales_rep": "Lorem ipsum dolor sit amet",
      "ship_country": "Lorem ipsum dolor sit amet",
      "ship_address": "Lorem ipsum dolor sit amet",
      "bill_country": "Lorem ipsum dolor sit amet",
      "bill_address": "Lorem ipsum dolor sit amet",
      "contact_name": "Lorem ipsum dolor sit amet",
      "contact_phone": "Lorem ipsum dolor sit amet",
      "contact_email": "Lorem ipsum dolor sit amet",
      "hardware": "Lorem ipsum dolor sit amet",
      "software": "Lorem ipsum dolor sit amet",
      "progress": "Lorem ipsum dolor sit amet",
      "additional_details": "Lorem ipsum dolor sit amet",
      "slack_channel": "Lorem ipsum dolor sit amet",
      "notifications": "Lorem ipsum dolor sit amet",
      "slack_channel_archive": "Lorem ipsum dolor sit amet",
      "internal_invoice": "Lorem ipsum dolor sit amet",
      "salesforce_link": "Lorem ipsum dolor sit amet",
      "software_cancelled": "Lorem ipsum dolor sit amet",
      "opportunityid": "Lorem ipsum dolor sit amet",
      "orderid": "Lorem ipsum dolor sit amet"
    })
  );
  console.log(returnmessage);
    
  };

  const handleImportToInventory = async () => {
    if (selectedOrdersData.length === 0) {return;}
    // Create an array to store the selected rows' data
    const selectedDataArray = [];
    // Iterate through selectedInventoryData and use inventory data
    selectedOrdersData.forEach((selectedRowId) => {
      const selectedRow = exportedorders.find((row) => row.internal_order_number === selectedRowId);
      if (selectedRow) {selectedDataArray.push(selectedRow);}});
    //Iterate through the selected products to try to import each one
    for (const selectedProduct of selectedDataArray) {
      try {
        const existingOrder = await DataStore.query(ImportedOrders, order => order.internal_order_number.eq(selectedProduct.internal_order_number,));
        if (existingOrder && existingOrder.length > 0) {
          console.log(`Order ${selectedProduct.internal_order_number} already exists in imported_orders.`);
          continue; // Skip this order and move to the next one
        }
        else
        {
          try
          {
            returnmessage = await DataStore.save(
              new ImportedOrders({
                internal_order_number: selectedProduct.internal_order_number,
                external_order_number: selectedProduct.external_order_number,
                order_type: selectedProduct.order_type,
                order_date: selectedProduct.order_date,
                partner_name: selectedProduct.partner_name,
                end_customer_name: selectedProduct.end_customer_name,
                sales_rep: selectedProduct.sales_rep,
                ship_country: selectedProduct.ship_country,
                ship_address: selectedProduct.ship_address,
                bill_country: selectedProduct.bill_country,
                bill_address: selectedProduct.bill_address,
                contact_name: selectedProduct.contact_name,
                contact_phone: selectedProduct.contact_phone,
                contact_email: selectedProduct.contact_email,
                hardware: selectedProduct.hardware,
                software: selectedProduct.software,
                progress: selectedProduct.progress,
                additional_details: selectedProduct.additional_details,
                slack_channel: selectedProduct.slack_channel,
                notifications: String(selectedProduct.notifications),
                slack_channel_archive: String(selectedProduct.slack_channel_archive),
                internal_invoice: selectedProduct.internal_invoice,
                salesforce_link: selectedProduct.salesforce_link,
                software_cancelled: String(selectedProduct.software_cancelled),
                opportunityid: selectedProduct.opportunityid,
                orderid: selectedProduct.orderid,
              }));
          }
          catch
          {
            console.log(returnmessage);
          }
          if(selectedProduct.hardware === "yes")
          {
            const selectedRowHardware = exportedordershardware.find((row) => row.internal_order_number === selectedProduct.internal_order_number);
            try {
              returnmessage = await DataStore.save(
                new ImportedOrdersHardware({
                  internal_order_number: selectedRowHardware.internal_order_number,
                  M6000: selectedRowHardware.M6000,
                  M3000: selectedRowHardware.M3000,
                  Photon_400: selectedRowHardware.Photon_400,
                  Photon_300: selectedRowHardware.Photon_300,
                  Photon_250: selectedRowHardware.Photon_250,
                  Photon_150: selectedRowHardware.Photon_150,
                  Photon_100: selectedRowHardware.Photon_100,
                  additional_details: selectedRowHardware.additional_details,
                  additional_warranty: selectedRowHardware.additional_warranty,
                })
              );
            } catch (saveError) {
              console.log('Error saving selected items :', saveError);
            }};
          
            const softwareLowerCase = selectedProduct.software;
            if(softwareLowerCase === "yes")
            {
              const selectedRowSoftware = exportedorderssoftware.find((row) => row.internal_order_number === selectedProduct.internal_order_number);
              try {
                returnmessage = await DataStore.save(
                  new ImportedOrdersSoftware({
                    internal_order_number: selectedRowSoftware.internal_order_number,
                    items: selectedRowSoftware.items,
                    otp: selectedRowSoftware.otp,
                    ramp: String(selectedRowSoftware.ramp),
                    additional_details: selectedRowSoftware.additional_details,
                    end_date: selectedRowSoftware.end_date,
                    SaaS: String(selectedRowSoftware.SaaS),
                    domain_name: selectedRowSoftware.domain_name,
                    item_1 : selectedRowSoftware.item_1,
                    item_2 : selectedRowSoftware.item_2,
                    item_3 : selectedRowSoftware.item_3,
                    item_4 : selectedRowSoftware.item_4,
                    item_5 : selectedRowSoftware.item_5,
                    item_6 : selectedRowSoftware.item_6,
                    item_7 : selectedRowSoftware.item_7,
                    item_8 : selectedRowSoftware.item_8,
                    item_9 : selectedRowSoftware.item_9,
                    item_10 : selectedRowSoftware.item_10,
                    item_11 : selectedRowSoftware.item_11,
                    item_12 : selectedRowSoftware.item_12,
                    item_13 : selectedRowSoftware.item_13,
                    item_14 : selectedRowSoftware.item_14,
                    item_15 : selectedRowSoftware.item_15,
                    item_16 : selectedRowSoftware.item_16,
                    item_17 : selectedRowSoftware.item_17,
                    item_18 : selectedRowSoftware.item_18,
                    item_19 : selectedRowSoftware.item_19,
                    item_20 : selectedRowSoftware.item_20,
                    item_21 : selectedRowSoftware.item_21,
                    item_22 : selectedRowSoftware.item_22,
                    item_23 : selectedRowSoftware.item_23,
                    item_24 : selectedRowSoftware.item_24,
                    item_25 : selectedRowSoftware.item_25,
                    item_26 : selectedRowSoftware.item_26,
                    item_27 : selectedRowSoftware.item_27,
                    item_28 : selectedRowSoftware.item_28,
                    item_29 : selectedRowSoftware.item_29,
                    item_30 : selectedRowSoftware.item_30,
                    term_1 : selectedRowSoftware.term_1,
                    term_2 : selectedRowSoftware.term_2,
                    term_3 : selectedRowSoftware.term_3,
                    term_4 : selectedRowSoftware.term_4,
                    term_5 : selectedRowSoftware.term_5,
                    term_6 : selectedRowSoftware.term_6,
                    term_7 : selectedRowSoftware.term_7,
                    term_8 : selectedRowSoftware.term_8,
                    term_9 : selectedRowSoftware.term_9,
                    term_10 : selectedRowSoftware.term_10,
                    term_11 : selectedRowSoftware.term_11,
                    term_12 : selectedRowSoftware.term_12,
                    term_13 : selectedRowSoftware.term_13,
                    term_14 : selectedRowSoftware.term_14,
                    term_15 : selectedRowSoftware.term_15,
                    term_16 : selectedRowSoftware.term_16,
                    term_17 : selectedRowSoftware.term_17,
                    term_18 : selectedRowSoftware.term_18,
                    term_19 : selectedRowSoftware.term_19,
                    term_20 : selectedRowSoftware.term_20,
                    term_21 : selectedRowSoftware.term_21,
                    term_22 : selectedRowSoftware.term_22,
                    term_23 : selectedRowSoftware.term_23,
                    term_24 : selectedRowSoftware.term_24,
                    term_25 : selectedRowSoftware.term_25,
                    term_26 : selectedRowSoftware.term_26,
                    term_27 : selectedRowSoftware.term_27,
                    term_28 : selectedRowSoftware.term_28,
                    term_29 : selectedRowSoftware.term_29,
                    term_30 : selectedRowSoftware.term_30,
                    quantity_1 : selectedRowSoftware.quantity_1,
                    quantity_2 : selectedRowSoftware.quantity_2,
                    quantity_3 : selectedRowSoftware.quantity_3,
                    quantity_4 : selectedRowSoftware.quantity_4,
                    quantity_5 : selectedRowSoftware.quantity_5,
                    quantity_6 : selectedRowSoftware.quantity_6,
                    quantity_7 : selectedRowSoftware.quantity_7,
                    quantity_8 : selectedRowSoftware.quantity_8,
                    quantity_9 : selectedRowSoftware.quantity_9,
                    quantity_10 : selectedRowSoftware.quantity_10,
                    quantity_11 : selectedRowSoftware.quantity_11,
                    quantity_12 : selectedRowSoftware.quantity_12,
                    quantity_13 : selectedRowSoftware.quantity_13,
                    quantity_14 : selectedRowSoftware.quantity_14,
                    quantity_15 : selectedRowSoftware.quantity_15,
                    quantity_16 : selectedRowSoftware.quantity_16,
                    quantity_17 : selectedRowSoftware.quantity_17,
                    quantity_18 : selectedRowSoftware.quantity_18,
                    quantity_19 : selectedRowSoftware.quantity_19,
                    quantity_20 : selectedRowSoftware.quantity_20,
                    quantity_21 : selectedRowSoftware.quantity_21,
                    quantity_22 : selectedRowSoftware.quantity_22,
                    quantity_23 : selectedRowSoftware.quantity_23,
                    quantity_24 : selectedRowSoftware.quantity_24,
                    quantity_25 : selectedRowSoftware.quantity_25,
                    quantity_26 : selectedRowSoftware.quantity_26,
                    quantity_27 : selectedRowSoftware.quantity_27,
                    quantity_28 : selectedRowSoftware.quantity_28,
                    quantity_29 : selectedRowSoftware.quantity_29,
                    quantity_30 : selectedRowSoftware.quantity_30,
                    start_date_1 : selectedRowSoftware.start_date_1,
                    start_date_2 : selectedRowSoftware.start_date_2,
                    start_date_3 : selectedRowSoftware.start_date_3,
                    start_date_4 : selectedRowSoftware.start_date_4,
                    start_date_5 : selectedRowSoftware.start_date_5,
                    start_date_6 : selectedRowSoftware.start_date_6,
                    start_date_7 : selectedRowSoftware.start_date_7,
                    start_date_8 : selectedRowSoftware.start_date_8,
                    start_date_9 : selectedRowSoftware.start_date_9,
                    start_date_10 : selectedRowSoftware.start_date_10,
                    start_date_11 : selectedRowSoftware.start_date_11,
                    start_date_12 : selectedRowSoftware.start_date_12,
                    start_date_13 : selectedRowSoftware.start_date_13,
                    start_date_14 : selectedRowSoftware.start_date_14,
                    start_date_15 : selectedRowSoftware.start_date_15,
                    start_date_16 : selectedRowSoftware.start_date_16,
                    start_date_17 : selectedRowSoftware.start_date_17,
                    start_date_18 : selectedRowSoftware.start_date_18,
                    start_date_19 : selectedRowSoftware.start_date_19,
                    start_date_20 : selectedRowSoftware.start_date_20,
                    start_date_21 : selectedRowSoftware.start_date_21,
                    start_date_22 : selectedRowSoftware.start_date_22,
                    start_date_23 : selectedRowSoftware.start_date_23,
                    start_date_24 : selectedRowSoftware.start_date_24,
                    start_date_25 : selectedRowSoftware.start_date_25,
                    start_date_26 : selectedRowSoftware.start_date_26,
                    start_date_27 : selectedRowSoftware.start_date_27,
                    start_date_28 : selectedRowSoftware.start_date_28,
                    start_date_29 : selectedRowSoftware.start_date_29,
                    start_date_30 : selectedRowSoftware.start_date_30,
                  })
                );
    
              } catch (saveError) {
                console.log('Error saving selected items :', saveError);
              }
              
            };

        }
      
        
    



      }
      catch
      {

      }
        console.log(returnmessage)
      }
  };

  const columnsForOrders = [
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
    },
    {
      field: "internal_order_number",
      headerName: "Internal Order Number",
      flex: 1,
      renderCell: (params) => (
        <a href={`link/to/internal_order/${params.row.internal_order_number}`}>
          {params.value}
        </a>
      ),
    },
    {
      field: "order_date",
      headerName: "Order Date",
      flex: 1,
    },
    {
      field: "partner_name",
      headerName: "Partner Name",
      flex: 1,
    },
    {
      field: "end_customer_name",
      headerName: "End Customer Name",
      flex: 1,
    },
    {
      field: "additional_details",
      headerName: "Additional Details",
      flex: 1,
    },
    {
      field: "internal_invoice",
      headerName: "Internal Invoice",
      flex: 1,
    },
  ];
  const columnsForOrdersHardware = [
    {
      field: "internal_order_number",
      headerName: "Internal Order Number",
      flex: 1,
      renderCell: (params) => (
        <a href={`link/to/internal_order/${params.row.internal_order_number}`}>
          {params.value}
        </a>
      ),
    },
    {
      field: "M6000",
      headerName: "M6000",
      flex: 1,
    },
    {
      field: "M3000",
      headerName: "M3000",
      flex: 1,
    },
    {
      field: "Photon_400",
      headerName: "Photon 400",
      flex: 1,
    },
    {
      field: "Photon_300",
      headerName: "Photon 300",
      flex: 1,
    },
    {
      field: "Photon_250",
      headerName: "Photon 250",
      flex: 1,
    },
    {
      field: "Photon_150",
      headerName: "Photon 150",
      flex: 1,
    },
    {
      field: "Photon_100",
      headerName: "Photon 100",
      flex: 1,
    },

  ];
  const columnsForOrdersSoftware = [
    {
      field: "internal_order_number",
      headerName: "Internal Order Number",
      flex: 1,
      renderCell: (params) => (
        <a href={`link/to/internal_order/${params.row.internal_order_number}`}>
          {params.value}
        </a>
      ),
    },
    {
      field: "items",
      headerName: "Items",
      flex: 1,
    },
    {
      field: "otp",
      headerName: "OTP",
      flex: 1,
    },
    {
      field: "SaaS",
      headerName: "SaaS",
      flex: 1,
    },
    {
      field: "domain_name",
      headerName: "Domain Name",
      flex: 1,
    },
    {
      field: "ramp",
      headerName: "Ramp",
      flex: 1,
    },
  ];
  let columns;
  switch (displayType) {
    case "orders":
      columns = columnsForOrders;
      break;
    case "hardware":
      columns = columnsForOrdersHardware;
      break;
    case "software":
      columns = columnsForOrdersSoftware;
      break;
    default:
      columns = columnsForOrders;
      break;
  }
  let rows;
  switch (displayType) {
    case "orders":
      rows = exportedorders;
      break;
    case "hardware":
      rows = exportedordershardware;
      break;
    case "software":
      rows = exportedorderssoftware;
      break;
    default:
      rows = exportedorders;
      break;
  }



  const handleToggleDisplay = (type) => {
    setDisplayType(type);
  };


  return (
    <Box m="20px">
      <Header title="Orders from OP Server" subtitle="these are orders that were moved in from operations server" />
      <Box m="20px">
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "orders"}
          onClick={() => handleToggleDisplay("orders")}
        >
          Show Orders
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "hardware"}
          onClick={() => handleToggleDisplay("hardware")}
        >
          Show Hardware
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "software"}
          onClick={() => handleToggleDisplay("software")}
        >
          Show Software
        </Button>
      </Box>

      <Box m="20px">
        <Button
            variant="contained"
            color="secondary"
            onClick={handleImportToInventory}
            disabled={selectedOrdersData.length === 0}
          >
          Import to Inventory
        </Button>
        <Button
            variant="contained"
            color="secondary"
            onClick={handleTestImportToAWS}
          >
          Test Import to AWS
        </Button>

    </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}

          getRowId={(row) => row.internal_order_number}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedOrdersData(newSelection);
          }}
          selectionModel={selectedOrdersData}
        />
      </Box>
    </Box>
  );

};

export default Inventory;

