import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';
import { Box, Typography, useTheme, Button } from "@mui/material";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { get } from 'aws-amplify/api';



Amplify.configure(awsExports); // Configure Amplify with your project settings

const SFtoZSyncQuote = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(true);
    const [salesforceData, setSalesforceData] = useState([]);
    const [zendeskData, setZendeskData] = useState([]);
    const [newList, setNewList] = useState([]);
    const [deleteList, setDeleteList] = useState([]);
    const [currentList, setCurrentList] = useState([]);



    const fetchSalesforceData = async () => {
        try {
            const queryDataSF = get({
              apiName: 'salesforceapi',
              path: '/getQuery',
              options:{
                queryParams:{
                  queryArrayItems: "AccountId, Account.Name, Account.Id",
                  queryObject: "Opportunity"
              }
              }
            });
            const { body } = await queryDataSF.response;
            const json = await body.json();

            if (json.result.records && json.result.records.length > 0) {
                const uniqueNames = new Set();
                json.result.records.forEach((record) => {
                    const accountName = record['Account']['Name'];
                    uniqueNames.add(accountName);
                });
                setSalesforceData(Array.from(uniqueNames));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }
    const fetchZendeskData = async () => {
        try {
            const uniqueNames = new Set();
            // let queryDataZendesk = await API.get("zendeskapi", "/getOrganizations", {
            //     queryStringParameters:{
            //         page: "1"
            //     }
            // })
            let queryDataZendesk = get({
              apiName: 'zendeskapi',
              path: '/getOrganizations',
              options:{
                queryParams:{
                  page: "1"

              }
              }
            });
            let { body } = await queryDataZendesk.response;
            let json = await body.json();
            console.log(json);
            
            if (json.data.organizations && json.data.organizations.length > 0) {
                json.data.organizations.forEach((record) => {
                    const accountName = record['name'];
                    uniqueNames.add(accountName);
                });
            }

            let istheredata = json.data.organizations.length;
            let pagecount = 2;
            while(istheredata > 0)
            {
                console.log(pagecount);
                let queryDataZendesk = get({
                  apiName: 'zendeskapi',
                  path: '/getOrganizations',
                  options:{
                    queryParams:{
                      page: pagecount
    
                  }
                  }
                });
                let { body } = await queryDataZendesk.response;
                let json = await body.json();
                if (json.data.organizations && json.data.organizations.length > 0) {
                    json.data.organizations.forEach((record) => {
                        const accountName = record['name'];
                        uniqueNames.add(accountName);
                    });
                }

                pagecount++;
                istheredata = json.data.organizations.length;
            }

            setZendeskData(Array.from(uniqueNames));

        }catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }

    }
    
    const fetchData = async () => {
        try {
            const sfData = await fetchSalesforceData();
            const zendeskData = await fetchZendeskData();
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }
    
    const compareData = () => {
        if (salesforceData && zendeskData) {
            const sfNames = salesforceData;
            const zendeskNames = zendeskData;
    
            const newItems = sfNames.filter((item) => !zendeskNames.includes(item));
            const deleteItems = zendeskNames.filter((item) => !sfNames.includes(item));
            const currentItems = sfNames.filter((item) => zendeskNames.includes(item));
    
            setNewList(newItems);
            setDeleteList(deleteItems);
            setCurrentList(currentItems);
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetchData();
    }



    useEffect(() => {
        compareData();
    }, [salesforceData, zendeskData]);


    const [displayType, setDisplayType] = useState("New");


    const columns = [
        {
          field: "name", // Change "names" to "name" to match the property name in your data objects
          headerName: "Name", // Specify the header text for the "name" field
          flex: 1,
        }
    ];

    let rows;
        switch (displayType) {
            case "New":
            rows = newList;
            break;
            case "Delete":
            rows = deleteList;
            break;
            case "Current":
            rows = currentList;
            break;
            case "SalesForce":
            rows = salesforceData;
            break;
            case "Zendesk":
            rows = zendeskData;
            break;
            default:
            rows = newList;
            break;
        }
    const handleToggleDisplay = (type) => {
        setDisplayType(type);
        };


    return (

        <Box m="20px">
        <Header title="Orders from OP Server" subtitle="these are orders that were moved in from operations server" />

        <Box m="20px">
        <Button
            variant="contained"
            color="secondary"
            onClick={handleFormSubmit}
          >
          Get Data
        </Button>

        </Box>



        <Box m="20px">
                <Button
                variant="contained"
                color="secondary"
                disabled={displayType === "New"}
                onClick={() => handleToggleDisplay("orders")}
                >
                Show New
                </Button>
                <Button
                variant="contained"
                color="secondary"
                disabled={displayType === "Delete"}
                onClick={() => handleToggleDisplay("Delete")}
                >
                Show Delete
                </Button>
                <Button
                variant="contained"
                color="secondary"
                disabled={displayType === "Current"}
                onClick={() => handleToggleDisplay("Current")}
                >
                Show Current
                </Button>
                <Button
                variant="contained"
                color="secondary"
                disabled={displayType === "SalesForce"}
                onClick={() => handleToggleDisplay("SalesForce")}
                >
                Show SalesForce
                </Button>
                <Button
                variant="contained"
                color="secondary"
                disabled={displayType === "Zendesk"}
                onClick={() => handleToggleDisplay("Zendesk")}
                >
                Show Zendesk
                </Button>
        </Box>

        <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
          },
        }}
      >
       <DataGrid 
            checkboxSelection
            rows={rows.map((name, id) => ({ id, name }))} // Convert the array of names into an array of objects with an 'id' and 'name' field.
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row.id} // Define the 'id' field as the unique row identifier.
        />

      </Box>



        </Box>
        
    );
    
}

export default SFtoZSyncQuote;


// <div>
//             <form onSubmit={handleFormSubmit}>
//                 <button type="submit">Get Data</button>
//             </form>
    
//             {loading ? (
//                 <p>Loading...</p>
//             ) : (
//                 <div>
//                     <p>Salesforce Data:</p>
//                     <pre>{JSON.stringify(salesforceData, null, 2)}</pre>
    
//                     <p>Zendesk Data:</p>
//                     <pre>{JSON.stringify(zendeskData, null, 2)}</pre>
    
//                     <p>New List:</p>
//                     <pre>{JSON.stringify(newList, null, 2)}</pre>
    
//                     <p>Delete List:</p>
//                     <pre>{JSON.stringify(deleteList, null, 2)}</pre>
    
//                     <p>Current List:</p>
//                     <pre>{JSON.stringify(currentList, null, 2)}</pre>
//                 </div>
//             )}
//         </div>