import React, { useState } from 'react';
import { Box, Button, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { DataStore } from "@aws-amplify/datastore";
import { LoginInformation } from '../../models'; // Import your DataStore model

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");







  // Initialize userData as an empty object
  const [userData, setUserData] = useState({
    username: '',
    privilege: '',
    name: '',
    slackid: '',
    email: '',
    title: '',
    se_support: '',
    phone_number: '',
    salesforce_name: '',
  });

  // Handles changes made to user fields
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    // Update the form data state with the new value
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  async function handleFormSubmit(event) {
    event.preventDefault();
    try {
      const test = DataStore.start();
      console.log(test);
      const createUser = await DataStore.save(new LoginInformation(userData));
      console.log("User created:", createUser);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  return (
    <Box m="20px">
      <h1>Create User</h1>
      <form onSubmit={handleFormSubmit}>
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
          {/* UserName */}
          <TextField fullWidth variant="filled" type="text" label="Username" value={userData.username} name="username"
            onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
          {/* Privilege */}
          <TextField fullWidth variant="filled" type="text" label="Privilege" value={userData.privilege} name="privilege"
            onChange={handleFieldChange} sx={{ gridColumn: "span 1" }} />
        </Box>
        <Box m="20px">
          <Button type="submit" color="secondary" variant="contained">
            Create User
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
