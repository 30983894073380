import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';


import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";

import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped, ImportedProducts } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";
import { useParams } from 'react-router-dom';

import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';

import {fetchInventoryDataTest} from "../../functions/order_functions";

import {orderinformation_setone, orderinformation_settwo, orderinformation_setthree, orderinformation_setfour} from '../../functions/create_order';



Amplify.configure(awsExports);

const DisplayOrderInformation = () => {
    const { internalOrderNumber } = useParams();

    const [q_order, set_q_order] = useState([]);
    const [user_order, set_user_order] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const query = await fetchInventoryDataTest(internalOrderNumber);
          set_q_order(query);
          set_user_order(query);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [internalOrderNumber]);

    const hardwareFields = ["M6000", "M3000", "Photon_400", "Photon_300", "Photon_250", "Photon_160", "Photon_150", "Photon_100"];


  //Update the entire order
  async function handleUpdateOrder(event) 
  {
      event.preventDefault();
      const updateOrderData = await DataStore.save(ImportedOrders.copyOf(q_order.orderData, (updated) => {
        updated.external_order_number = user_order.orderData.external_order_number;
        updated.order_type = user_order.orderData.order_type;
        updated.order_date = user_order.orderData.order_date;
        updated.partner_name = user_order.orderData.partner_name;
        updated.end_customer_name = user_order.orderData.end_customer_name;
        updated.sales_rep = user_order.orderData.sales_rep;
        updated.ship_country = user_order.orderData.ship_country;
        updated.ship_address = user_order.orderData.ship_address;
        updated.bill_country = user_order.orderData.bill_address;
        updated.contact_name = user_order.orderData.contact_name;
        updated.contact_phone = user_order.orderData.contact_phone;
        updated.contact_email = user_order.orderData.contact_email;
        updated.hardware = user_order.orderData.hardware;
        updated.software = user_order.orderData.software;
        updated.progress = user_order.orderData.progress;
        updated.additional_information = user_order.orderData.additional_information;
        updated.internal_invoices = user_order.orderData.internal_invoices;
        updated.salesforce_link = user_order.orderData.salesforce_link;
        updated.slack_channel = user_order.orderData.slack_channel;
        updated.notifications = user_order.orderData.notifications;
        updated.slack_channel_archive = user_order.orderData.slack_channel_archive;
        updated.software_cancelled = user_order.orderData.software_cancelled;
        updated.opportunityid = user_order.orderData.opportunityid;
        updated.orderid = user_order.orderData.orderid;
      }));

      if(user_order.orderData.hardware == "yes")
      {
        const updateOrderDataHardware = await DataStore.save(ImportedOrdersHardware.copyOf(q_order.orderDataHardware, (updated) => {
          updated.M6000 = user_order.orderDataHardware.M6000;
          updated.M3000 = user_order.orderDataHardware.M3000;
          updated.Photon_400 = user_order.orderDataHardware.Photon_400;
          updated.Photon_300 = user_order.orderDataHardware.Photon_300;
          updated.Photon_250 = user_order.orderDataHardware.Photon_250;
          updated.Photon_160 = user_order.orderDataHardware.Photon_160;
          updated.Photon_150 = user_order.orderDataHardware.Photon_150;
          updated.Photon_100 = user_order.orderDataHardware.Photon_100;
          updated.additional_information = user_order.orderDataHardware.additional_information;
        }));
        //Iterate through every field
      for (const field of hardwareFields) {
        
        const target_value = Object.keys(user_order.orderDataHardwareItems[field]).length;

        for(let i = 0; i < target_value; i++)
        {
          const old_sn = q_order.orderDataHardwareItems[field][i].serial_number;
          const new_sn = user_order.orderDataHardwareItems[field][i].serial_number;

          if(old_sn == "" || old_sn == null)
          {
            const q_exists = await DataStore.query(ImportedProducts, (product) => product.serial_number.eq(new_sn));
            if(q_exists.length > 0)
            {
              DataStore.delete(q_exists[0]);
              const q_create = await DataStore.save(
                new ImportedProductsShipped({
                serial_number: q_exists[0].serial_number || "",
                model: q_exists[0].model || "",
                version: q_exists[0].version || "",
                location: q_exists[0].location || "",
                additional_information: q_exists[0].additional_information || "",
                manufacturer_warranty: q_exists[0].manufacturer_warranty || "",
                ship_date: q_exists[0].ship_date || "",
                internal_order_number: internalOrderNumber || "",
                tracking_number: q_exists[0].tracking_number || "",
                received_date: q_exists[0].received_date || "",
                stellarcyber_warranty: q_exists[0].stellarcyber_warranty || ""
              })
            );
            }
          }
        else if(old_sn != new_sn)
        {
          const q_old_sn = await DataStore.query(ImportedProductsShipped, (product) => product.serial_number.eq(old_sn));
          DataStore.delete(q_old_sn[0]);
            const q_move_old_sn = await DataStore.save(
              new ImportedProducts({
              serial_number: q_old_sn[0].serial_number || "",
              model: q_old_sn[0].model || "",
              version: q_old_sn[0].version || "",
              location: q_old_sn[0].location || "",
              additional_information: q_old_sn[0].additional_information || "",
              manufacturer_warranty: q_old_sn[0].manufacturer_warranty || "",
              recieved_date: q_old_sn[0].recieved_date || "",
            })
          );
          if(new_sn != "" && new_sn != null)
          {
            const q_new_sn_products =  await DataStore.query(ImportedProducts, (product) => product.serial_number.eq(new_sn));
            DataStore.delete(q_new_sn_products[0]);
            const q_new_sn_moveToShipped = await DataStore.save(
              new ImportedProductsShipped({
              serial_number: q_new_sn_products[0].serial_number || "",
              model: q_new_sn_products[0].model || "",
              version: q_new_sn_products[0].version || "",
              location: q_new_sn_products[0].location || "",
              additional_information: q_new_sn_products[0].additional_information || "",
              manufacturer_warranty: q_new_sn_products[0].manufacturer_warranty || "",
              ship_date: q_new_sn_products[0].ship_date || "",
              internal_order_number: internalOrderNumber || "",
              tracking_number: q_new_sn_products[0].tracking_number || "",
              received_date: q_new_sn_products[0].received_date || "",
              stellarcyber_warranty: q_new_sn_products[0].stellarcyber_warranty || ""
            }));
          }
        }
        else if(old_sn == new_sn)
        {
          const q_sn_data = await DataStore.query(ImportedProductsShipped, (product) => product.serial_number.eq(old_sn));
          const q_update_sn = await DataStore.save(ImportedProductsShipped.copyOf(q_sn_data[0], update => {
            update.serial_number = user_order.orderDataHardwareItems[field][i].serial_number;
            update.model = user_order.orderDataHardwareItems[field][i].model;
            update.version = user_order.orderDataHardwareItems[field][i].version;
            update.location = user_order.orderDataHardwareItems[field][i].location;
            update.additional_information = user_order.orderDataHardwareItems[field][i].additional_information;
            update.manufacturer_warranty = user_order.orderDataHardwareItems[field][i].manufacturer_warranty;
            update.ship_date = user_order.orderDataHardwareItems[field][i].ship_date;
            update.internal_order_number = internalOrderNumber;
            update.tracking_number = user_order.orderDataHardwareItems[field][i].tracking_number;
            update.received_date = user_order.orderDataHardwareItems[field][i].received_date;
            update.stellarcyber_warranty = user_order.orderDataHardwareItems[field][i].stellarcyber_warranty;
          }));

         
        }


        }
      }
      }
      
      if(user_order.orderData.hardware == "no")
      {
        /* Models in DataStore are immutable. To update a record you must use the copyOf function
        to apply updates to the item’s fields rather than mutating the instance directly */
        await DataStore.save(ImportedOrdersSoftware.copyOf(q_order.orderDataSoftware, updated => {
          updated.internal_order_number = user_order.orderDataSoftware.internal_order_number;
          updated.items = user_order.orderDataSoftware.items;
          updated.otp = user_order.orderDataSoftware.otp;
          updated.ramp = user_order.orderDataSoftware.ramp;
          updated.additional_details = user_order.orderDataSoftware.additional_details;
          updated.item_1 = user_order.orderDataSoftware.item_1;
          updated.item_2 = user_order.orderDataSoftware.item_2;
          updated.item_3 = user_order.orderDataSoftware.item_3;
          updated.item_4 = user_order.orderDataSoftware.item_4;
          updated.item_5 = user_order.orderDataSoftware.item_5;
          updated.item_6 = user_order.orderDataSoftware.item_6;
          updated.item_7 = user_order.orderDataSoftware.item_7;
          updated.item_8 = user_order.orderDataSoftware.item_8;
          updated.item_9 = user_order.orderDataSoftware.item_9;
          updated.item_10 = user_order.orderDataSoftware.item_10;
          updated.item_11 = user_order.orderDataSoftware.item_11;
          updated.item_12 = user_order.orderDataSoftware.item_12;
          updated.item_13 = user_order.orderDataSoftware.item_13;
          updated.item_14 = user_order.orderDataSoftware.item_14;
          updated.item_15 = user_order.orderDataSoftware.item_15;
          updated.item_16 = user_order.orderDataSoftware.item_16;
          updated.item_17 = user_order.orderDataSoftware.item_17;
          updated.item_18 = user_order.orderDataSoftware.item_18;
          updated.item_19 = user_order.orderDataSoftware.item_19;
          updated.item_20 = user_order.orderDataSoftware.item_20;
          updated.item_21 = user_order.orderDataSoftware.item_21;
          updated.item_22 = user_order.orderDataSoftware.item_22;
          updated.item_23 = user_order.orderDataSoftware.item_23;
          updated.item_24 = user_order.orderDataSoftware.item_24;
          updated.item_25 = user_order.orderDataSoftware.item_25;
          updated.item_26 = user_order.orderDataSoftware.item_26;
          updated.item_27 = user_order.orderDataSoftware.item_27;
          updated.item_28 = user_order.orderDataSoftware.item_28;
          updated.item_29 = user_order.orderDataSoftware.item_29;
          updated.item_30 = user_order.orderDataSoftware.item_30;
          updated.term_1 = user_order.orderDataSoftware.term_1;
          updated.term_2 = user_order.orderDataSoftware.term_2;
          updated.term_3 = user_order.orderDataSoftware.term_3;
          updated.term_4 = user_order.orderDataSoftware.term_4;
          updated.term_5 = user_order.orderDataSoftware.term_5;
          updated.term_6 = user_order.orderDataSoftware.term_6;
          updated.term_7 = user_order.orderDataSoftware.term_7;
          updated.term_8 = user_order.orderDataSoftware.term_8;
          updated.term_9 = user_order.orderDataSoftware.term_9;
          updated.term_10 = user_order.orderDataSoftware.term_10;
          updated.term_11 = user_order.orderDataSoftware.term_11;
          updated.term_12 = user_order.orderDataSoftware.term_12;
          updated.term_13 = user_order.orderDataSoftware.term_13;
          updated.term_14 = user_order.orderDataSoftware.term_14;
          updated.term_15 = user_order.orderDataSoftware.term_15;
          updated.term_16 = user_order.orderDataSoftware.term_16;
          updated.term_17 = user_order.orderDataSoftware.term_17;
          updated.term_18 = user_order.orderDataSoftware.term_18;
          updated.term_19 = user_order.orderDataSoftware.term_19;
          updated.term_20 = user_order.orderDataSoftware.term_20;
          updated.term_21 = user_order.orderDataSoftware.term_21;
          updated.term_22 = user_order.orderDataSoftware.term_22;
          updated.term_23 = user_order.orderDataSoftware.term_23;
          updated.term_24 = user_order.orderDataSoftware.term_24;
          updated.term_25 = user_order.orderDataSoftware.term_25;
          updated.term_26 = user_order.orderDataSoftware.term_26;
          updated.term_27 = user_order.orderDataSoftware.term_27;
          updated.term_28 = user_order.orderDataSoftware.term_28;
          updated.term_29 = user_order.orderDataSoftware.term_29;
          updated.term_30 = user_order.orderDataSoftware.term_30;
          updated.quantity_1 = user_order.orderDataSoftware.quantity_1;
          updated.quantity_2 = user_order.orderDataSoftware.quantity_2;
          updated.quantity_3 = user_order.orderDataSoftware.quantity_3;
          updated.quantity_4 = user_order.orderDataSoftware.quantity_4;
          updated.quantity_5 = user_order.orderDataSoftware.quantity_5;
          updated.quantity_6 = user_order.orderDataSoftware.quantity_6;
          updated.quantity_7 = user_order.orderDataSoftware.quantity_7;
          updated.quantity_8 = user_order.orderDataSoftware.quantity_8;
          updated.quantity_9 = user_order.orderDataSoftware.quantity_9;
          updated.quantity_10 = user_order.orderDataSoftware.quantity_10;
          updated.quantity_11 = user_order.orderDataSoftware.quantity_11;
          updated.quantity_12 = user_order.orderDataSoftware.quantity_12;
          updated.quantity_13 = user_order.orderDataSoftware.quantity_13;
          updated.quantity_14 = user_order.orderDataSoftware.quantity_14;
          updated.quantity_15 = user_order.orderDataSoftware.quantity_15;
          updated.quantity_16 = user_order.orderDataSoftware.quantity_16;
          updated.quantity_17 = user_order.orderDataSoftware.quantity_17;
          updated.quantity_18 = user_order.orderDataSoftware.quantity_18;
          updated.quantity_19 = user_order.orderDataSoftware.quantity_19;
          updated.quantity_20 = user_order.orderDataSoftware.quantity_20;
          updated.quantity_21 = user_order.orderDataSoftware.quantity_21;
          updated.quantity_22 = user_order.orderDataSoftware.quantity_22;
          updated.quantity_23 = user_order.orderDataSoftware.quantity_23;
          updated.quantity_24 = user_order.orderDataSoftware.quantity_24;
          updated.quantity_25 = user_order.orderDataSoftware.quantity_25;
          updated.quantity_26 = user_order.orderDataSoftware.quantity_26;
          updated.quantity_27 = user_order.orderDataSoftware.quantity_27;
          updated.quantity_28 = user_order.orderDataSoftware.quantity_28;
          updated.quantity_29 = user_order.orderDataSoftware.quantity_29;
          updated.quantity_30 = user_order.orderDataSoftware.quantity_30;
          updated.start_date_1 = user_order.orderDataSoftware.start_date_1;
          updated.start_date_2 = user_order.orderDataSoftware.start_date_2;
          updated.start_date_3 = user_order.orderDataSoftware.start_date_3;
          updated.start_date_4 = user_order.orderDataSoftware.start_date_4;
          updated.start_date_5 = user_order.orderDataSoftware.start_date_5;
          updated.start_date_6 = user_order.orderDataSoftware.start_date_6;
          updated.start_date_7 = user_order.orderDataSoftware.start_date_7;
          updated.start_date_8 = user_order.orderDataSoftware.start_date_8;
          updated.start_date_9 = user_order.orderDataSoftware.start_date_9;
          updated.start_date_10 = user_order.orderDataSoftware.start_date_10;
          updated.start_date_11 = user_order.orderDataSoftware.start_date_11;
          updated.start_date_12 = user_order.orderDataSoftware.start_date_12;
          updated.start_date_13 = user_order.orderDataSoftware.start_date_13;
          updated.start_date_14 = user_order.orderDataSoftware.start_date_14;
          updated.start_date_15 = user_order.orderDataSoftware.start_date_15;
          updated.start_date_16 = user_order.orderDataSoftware.start_date_16;
          updated.start_date_17 = user_order.orderDataSoftware.start_date_17;
          updated.start_date_18 = user_order.orderDataSoftware.start_date_18;
          updated.start_date_19 = user_order.orderDataSoftware.start_date_19;
          updated.start_date_20 = user_order.orderDataSoftware.start_date_20;
          updated.start_date_21 = user_order.orderDataSoftware.start_date_21;
          updated.start_date_22 = user_order.orderDataSoftware.start_date_22;
          updated.start_date_23 = user_order.orderDataSoftware.start_date_23;
          updated.start_date_24 = user_order.orderDataSoftware.start_date_24;
          updated.start_date_25 = user_order.orderDataSoftware.start_date_25;
          updated.start_date_26 = user_order.orderDataSoftware.start_date_26;
          updated.start_date_27 = user_order.orderDataSoftware.start_date_27;
          updated.start_date_28 = user_order.orderDataSoftware.start_date_28;
          updated.start_date_29 = user_order.orderDataSoftware.start_date_29;
          updated.start_date_30 = user_order.orderDataSoftware.start_date_30;
          updated.end_date = user_order.orderDataSoftware.end_date;
          updated.SaaS = user_order.orderDataSoftware.SaaS;
          updated.domain_name = user_order.orderDataSoftware.domain_name;}));
      }

      
  }


  //Handle if there is change in order data
  const handleUpdateOrderDataInput = (event) => {
    const { name, value } = event.target;

    set_user_order((prevUserOrder) => {
      // Create a new instance of orderData with the updated property
      const updatedOrderData = {
        ...prevUserOrder.orderData,
        [name]: value,
      };
  
      // Create a new object with the updated orderData
      const updatedUserOrder = {
        ...prevUserOrder,
        orderData: updatedOrderData,
      };
  
      return updatedUserOrder;
    });
  };

  //Handle if there is change in order data
  const handleUpdateOrderDataHardwareInput = (event) => {
    const { name, value } = event.target;

    set_user_order((prevUserOrder) => {
      // Create a new instance of orderData with the updated property
      const updatedOrderData = {
        ...prevUserOrder.orderDataHardware,
        [name]: value,
      };
  
      // Create a new object with the updated orderDataHardware
      const updatedUserOrder = {
        ...prevUserOrder,
        orderDataHardware: updatedOrderData,
      };
  
      return updatedUserOrder;
    });
  };

   //Handle if there is change in order data
const handleUpdateOrderDataHardwareItemInput = (event) => {
    const { name, value } = event.target;
    let substringChecked;
    let secondString;
    let thirdString;
    
    for (const substring of hardwareFields) {
      if (name.includes(substring)) {
        substringChecked = substring;
        // Remove the prefix and split the remaining string into an array using "_" as the separator
        const stringWithoutPrefix = name.replace(`${substring}_#`, '');
        const parts = stringWithoutPrefix.split('_');
        // Assign the second and third strings
        secondString = parseInt(parts[0]); // "1"
        thirdString = parts.slice(1).join('_'); // "ship_date" (everything after 1_)
        break; // Break out of the loop since we found a matching substring
      }
    }
    set_user_order((prevUserOrder) => {
      // Create a new instance of orderData with the updated property
      const updatedOrderData = {
        ...prevUserOrder.orderDataHardwareItems[substringChecked][secondString],
        [thirdString]: value,
      };
      const updatedUserOrder = {
        ...prevUserOrder,
        orderDataHardwareItems: {
          ...prevUserOrder.orderDataHardwareItems,
          [substringChecked]: {
            ...prevUserOrder.orderDataHardwareItems[substringChecked],
            [secondString]: updatedOrderData,
          },
        },
      };
  
      return updatedUserOrder;
    });

  };

  //Handle if there is change in order data
  const handleUpdateOrderDataSoftwareInput = (event) => {
    const { name, value } = event.target;
    console.log(name + " with value" +  value);

    set_user_order((prevUserOrder) => {
      // Create a new instance of orderData with the updated property
      const updatedOrderData = {
        ...prevUserOrder.orderDataSoftware,
        [name]: value,
      };
  
      // Create a new object with the updated orderDataSoftware
      const updatedUserOrder = {
        ...prevUserOrder,
        orderDataSoftware: updatedOrderData,
      };
  
      return updatedUserOrder;
    });
  };




    const isNonMobile = useMediaQuery("(min-width:600px)");   
    
    const generateItemBox = (index) => {
      // Check if user_order and orderDataSoftware exist
      if (!user_order || !user_order.orderDataSoftware) {
        return null; // or handle the error accordingly
      }
    
      return (
        <Box
          display="grid"
          margin="30px"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 4fr))"
          alignItems="center"
          sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
          key={index}
        >
          {/* Item  */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label={`Item ${index}`}
            value={user_order.orderDataSoftware[`item_${index}`] || ""}
            name={`item_${index}`}
            onChange={handleUpdateOrderDataSoftwareInput}
            sx={{ gridColumn: "span 1" }}
          />
          {/* QTY */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label={`Quantity ${index}`}
            value={user_order.orderDataSoftware[`quantity_${index}`] || ""}
            name={`quantity_${index}`}
            onChange={handleUpdateOrderDataSoftwareInput}
            sx={{ gridColumn: "span 1" }}
          />
          {/* Term */}
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label={`Term ${index}`}
            value={user_order.orderDataSoftware[`term_${index}`] || ""}
            name={`term_${index}`}
            onChange={handleUpdateOrderDataSoftwareInput}
            sx={{ gridColumn: "span 1" }}
          />
        </Box>
      );
    };

const components = [];
let itemsCount;

// Check if user_order exists and has the expected structure
if (user_order && user_order.orderDataSoftware && user_order.orderDataSoftware.items) {
  itemsCount = parseInt(user_order.orderDataSoftware.items, 10);

  // Now itemsCount is the integer representation of user_order.orderDataSoftware.items
} else {
  console.error("user_order or its properties are undefined or have unexpected structure");
}


for (let index = 1; index <= itemsCount; index++) {
  components.push(generateItemBox(index));
}
 

const components_order_setone = [];

console.log("the userorder data is " + user_order);



  return (


 <Box m="20px">
      <Box m="20px">
        <h1>Edit Order Information for {internalOrderNumber}</h1>
      </Box>
      <form onSubmit={handleUpdateOrder}>
        {/* IOT, EOT, Order Type, Order Date, Partner Name, End Customer Name, Sales Rep */}
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* Internal Order Number */}
            <TextField fullWidth variant="filled" type="text" label="Internal Order Number" value={internalOrderNumber} name="internal_order_number"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} disabled />
            {/* External Order Number */}
            <TextField fullWidth variant="filled" type="text" label="External Order Number" value={user_order?.orderData?.external_order_number || ""} name="external_order_number"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/* Order Type */}
            <TextField fullWidth variant="filled" type="text" label="Order Type" value={user_order?.orderData?.order_type || " "} name="order_type"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/* Order Date */}
            <TextField fullWidth variant="filled" type="text" label="Order Date" value={user_order?.orderData?.order_date || " "} name="order_date"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/* Partner Name */}
            <TextField fullWidth variant="filled" type="text" label="Partner Name" value={user_order?.orderData?.partner_name || " "} name="partner_name"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/* End Customer Name*/}
            <TextField fullWidth variant="filled" type="text" label="End Customer Name" value={user_order?.orderData?.end_customer_name || " "} name="end_customer_name"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*Sales Rep*/}
            <TextField fullWidth variant="filled" type="text" label="Sales Rep" value={user_order?.orderData?.sales_rep || " "} name="sales_rep"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
        </Box>
        {/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Ship Country*/}
            <TextField fullWidth variant="filled" type="text" label="Ship Country" value={user_order?.orderData?.ship_country || " "} name="ship_country"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*Ship Address*/}
            <TextField fullWidth multiline variant="filled" type="text" label="Ship Address" value={user_order?.orderData?.ship_address || " "} name="ship_address"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 3" }} />
            {/*Bill Country*/}
            <TextField fullWidth variant="filled" type="text" label="Bill Country" value={user_order?.orderData?.bill_country || " "} name="bill_country"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*Bill Address*/}
            <TextField fullWidth multiline variant="filled" type="text" label="Bill Address" value={user_order?.orderData?.bill_address || " "} name="bill_address"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 3" }} />
            {/*Contact Name*/} 
            <TextField fullWidth variant="filled" type="text" label="Contact Name" value={user_order?.orderData?.contact_name || " "} name="contact_name"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*Contact Phone*/} 
            <TextField fullWidth variant="filled" type="text" label="Contact Phone" value={user_order?.orderData?.contact_phone || " "} name="contact_phone"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*Contact Email*/} 
            <TextField fullWidth variant="filled" type="text" label="Contact Email" value={user_order?.orderData?.contact_email || " "} name="contact_email"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 2" }} />
        </Box>
        {/* Hardware,Software,Progress,Additional Information,Internal Invoices,Salesforce Link */}
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Hardware*/} 
            <TextField fullWidth variant="filled" type="text" label="Hardware" value={user_order?.orderData?.hardware || " "} name="hardware"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*software*/} 
            <TextField fullWidth variant="filled" type="text" label="Software" value={user_order?.orderData?.software || " "} name="software"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*progress*/} 
            <TextField fullWidth variant="filled" type="text" label="Progress" value={user_order?.orderData?.progress || " "} name="progress"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*additional_information*/} 
            <TextField fullWidth variant="filled" type="text" label="Additional Information" value={user_order?.orderData?.additional_information || " "} name="additional_information"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 4" }} />
            {/*internal_invoices*/} 
            <TextField fullWidth variant="filled" type="text" label="Internal Invoice #" value={user_order?.orderData?.internal_invoices || " "} name="internal_invoices"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*salesforce_link*/} 
            <TextField fullWidth variant="filled" type="text" label="Salesforce Link" value={user_order?.orderData?.salesforce_link || " "} name="salesforce_link"
            onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 2" }} />
        </Box>
        {/* slack channel, notifications, slack channel archive, software cancelled, opportunityid, orderid */}
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*slack_channel*/} 
            <TextField fullWidth variant="filled" type="text" label="Slack Channel" value={user_order?.orderData?.slack_channel || " "} name="slack_channel"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*notifications*/} 
            <TextField fullWidth variant="filled" type="text" label="Notifications" value={user_order?.orderData?.notifications || " "} name="notifications"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*slack_channel_archive*/} 
            <TextField fullWidth variant="filled" type="text" label="Slack Channel Archive" value={user_order?.orderData?.slack_channel_archive || " "} name="slack_channel_archive"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*software_cancelled*/} 
            <TextField fullWidth variant="filled" type="text" label="Software Cancelled" value={user_order?.orderData?.software_cancelled || " "} name="software_cancelled"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*opportunityid*/} 
            <TextField fullWidth variant="filled" type="text" label="Opportunity ID" value={user_order?.orderData?.opportunityid || " "} name="opportunityid"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
            {/*orderid*/} 
            <TextField fullWidth variant="filled" type="text" label="Order ID" value={user_order?.orderData?.orderid || " "} name="orderid"
                onChange={handleUpdateOrderDataInput} sx={{ gridColumn: "span 1" }} />
        </Box>

        {q_order?.orderData?.hardware === "yes" && q_order?.orderDataHardware && (<>
          <h1>Hardware Information</h1>
          {/* M6000, M3000, Photon 400, Photon 300, Photon 250, Photon 150, Photon 100, Additional Information, Additonal Warranty*/}
          <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
              {/*M6000*/} 
              <TextField fullWidth variant="filled" type="text" label="M6000" value={user_order?.orderDataHardware?.M6000 || " "} name="M6000"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*M3000*/} 
              <TextField fullWidth variant="filled" type="text" label="M3000" value={user_order?.orderDataHardware?.M3000 || " "} name="M3000"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 400*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 400" value={user_order?.orderDataHardware?.Photon_400 || " "} name="Photon_400"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 300*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 300" value={user_order?.orderDataHardware?.Photon_300 || " "} name="Photon_300"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 250*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 250" value={user_order?.orderDataHardware?.Photon_250 || " "} name="Photon_250"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 160*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 160" value={user_order?.orderDataHardware?.Photon_160 || " "} name="Photon_160"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 150*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 150" value={user_order?.orderDataHardware?.Photon_150 || " "} name="Photon_150"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Photon 100*/} 
              <TextField fullWidth variant="filled" type="text" label="Photon 100" value={user_order?.orderDataHardware?.Photon_100 || " "} name="Photon_100"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />
              {/*Additional Information*/} 
              <TextField fullWidth variant="filled" type="text" label="Additional Information" value={user_order?.orderDataHardware?.additional_information || " "} name="additional_information"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 4" }} />
              {/*Additional Warranty*/} 
              <TextField fullWidth variant="filled" type="text" label="Additional Warranty" value={user_order?.orderDataHardware?.additional_warranty || " "} name="additional_warranty"
                      onChange={handleUpdateOrderDataHardwareInput} sx={{ gridColumn: "span 1" }} />     
          </Box>
        </>)}

        {q_order?.orderData?.hardware === "yes" && q_order?.orderDataHardware &&(<>
          {/* iterates through every itme in hardwareFields */}
          {hardwareFields.map(field => {
            //Gets the target value/number of items associated to the model. 
            const value = parseInt(q_order?.orderDataHardware?.[field], 10) || 0; 
            //Formats and removes _ to repalce with upper case
            const formattedField = field.replace(/_/g, ' ');

            const itemOnlyFieldArray = [];
  // Check if value is in the array and not equal to 0 or ""
  if (hardwareFields.includes(field) && value !== 0 && value !== "" && value !== " " && value !== null)
  {
    const components = [];
    components.push(<h2 >{formattedField}</h2> )
    for (let i = 0; i < value; i++)
    {
      components.push(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center"
        sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>

        <TextField fullWidth variant="filled" type="text" label={`Serial Number`} value={user_order?.orderDataHardwareItems[field][i].serial_number || ""} name={`${field}_#${i}_serial_number`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} /> 
        <TextField fullWidth variant="filled" type="text" label={`Version`} value={user_order?.orderDataHardwareItems[field][i].version || ""} name={`${field}_#${i}_version`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Location`} value={user_order?.orderDataHardwareItems[field][i].location || ""} name={`${field}_#${i}_location`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Additional Information`} value={user_order?.orderDataHardwareItems[field][i].additional_information || ""} name={`${field}_#${i}_additional_information`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Manufacturer Warranty`} value={user_order?.orderDataHardwareItems[field][i].manufacturer_warranty || ""} name={`${field}_#${i}_manufacturer_warranty`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Ship Date`} value={user_order?.orderDataHardwareItems[field][i].ship_date || ""} name={`${field}_#${i}_ship_date`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Tracking Number`} value={user_order?.orderDataHardwareItems[field][i].tracking_number || ""} name={`${field}_#${i}_tracking_number`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   
        <TextField fullWidth variant="filled" type="text" label={`Stellar Cyber Warranty`} value={user_order?.orderDataHardwareItems[field][i].stellarcyber_warranty || ""} name={`${field}_#${i}_stellarcyber_warranty`}
          onChange={handleUpdateOrderDataHardwareItemInput} sx={{ gridColumn: "span 1" }} />   


                </Box>
                );        
    }


    return components;    
}})}


        </>)}

        {q_order?.orderData?.software === "yes" && q_order?.orderDataSoftware && (<>
          <h1>Software Information</h1>
          <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* Number of Items */}
            <TextField fullWidth variant="filled" type="text" label="Number of Items" value={user_order.orderDataSoftware.items || ""} name="items"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
            {/* Ramp */}
            <TextField fullWidth variant="filled" type="text" label="Ramp" value={user_order.orderDataSoftware.ramp || ""} name="ramp"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
            {/* Software Cancelled*/}
            <TextField fullWidth variant="filled" type="text" label="Software Cancelled" value={user_order.orderDataSoftware.software_cancelled || ""} name="software_cancelled"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
          </Box>
          <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
          {/* OTP */}
          <TextField fullWidth variant="filled" type="text" label="OTP" value={user_order.orderDataSoftware.otp || ""} name="otp"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
          {/* End Date */}
          <TextField fullWidth variant="filled" type="text" label="End Date" value={user_order.orderDataSoftware.end_date || ""} name="end_date"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
          </Box>
          <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
          {/* SaaS */}
          <TextField fullWidth variant="filled" type="text" label="SaaS" value={user_order.orderDataSoftware.SaaS || ""} name="SaaS"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
          {/* Domain Name */}
          <TextField fullWidth variant="filled" type="text" label="Domain Name" value={user_order.orderDataSoftware.domain_name || ""} name="domain_name"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 1" }} />
          </Box>
          <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
          {/* Additional Information */}
          <TextField fullWidth variant="filled" type="text" label="Additional Information" value={user_order.orderDataSoftware.software_additional_information_data || ""} name="software_additional_information_data"
                  onChange={handleUpdateOrderDataSoftwareInput} sx={{ gridColumn: "span 4" }} />
          </Box>

          {components}
          
  
        
        </>)}

               
                  
        <Box m="20px">
            <Button type="submit" color="secondary" variant="contained">
            Update Order
            </Button>
        </Box>

      </form>

      {/* <pre>{JSON.stringify(q_order, null, 2)}</pre> */}



      
              
    </Box>
  );

};

export default DisplayOrderInformation;








// <h1>Order Information for {internalOrderNumber}</h1>
// <form onSubmit={handleSubmit}>
// {/* IOT, EOT, Order Type, Order Date, Partner Name, End Customer Name, Sales Rep */}
// <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
// {/* Internal Order Number */}
// <TextField fullWidth variant="filled" type="text" label="Internal Order Number" value={internalOrderNumber} name="internal_order_number"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} disabled />
// {/* External Order Number */}
// <TextField fullWidth variant="filled" type="text" label="External Order Number" value={orderInformation.external_order_number || " "} name="external_order_number"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/* Order Type */}
// <TextField fullWidth variant="filled" type="text" label="Order Type" value={orderInformation.order_type || " "} name="order_type"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/* Order Date */}
// <TextField fullWidth variant="filled" type="text" label="Order Date" value={orderInformation.order_date || " "} name="order_date"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/* Partner Name */}
// <TextField fullWidth variant="filled" type="text" label="Partner Name" value={orderInformation.partner_name || " "} name="partner_name"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/* End Customer Name*/}
// <TextField fullWidth variant="filled" type="text" label="End Customer Name" value={orderInformation.end_customer_name || " "} name="end_customer_name"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*Sales Rep*/}
// <TextField fullWidth variant="filled" type="text" label="Sales Rep" value={orderInformation.sales_rep || " "} name="sales_rep"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// </Box>
// {/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
// <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
// {/*Ship Country*/}
// <TextField fullWidth variant="filled" type="text" label="Ship Country" value={orderInformation.ship_country || " "} name="ship_country"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*Ship Address*/}
// <TextField fullWidth multiline variant="filled" type="text" label="Ship Address" value={orderInformation.ship_address || " "} name="ship_address"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 3" }} />
// {/*Bill Country*/}
// <TextField fullWidth variant="filled" type="text" label="Bill Country" value={orderInformation.bill_country || " "} name="bill_country"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*Bill Address*/}
// <TextField fullWidth multiline variant="filled" type="text" label="Bill Address" value={orderInformation.bill_address || " "} name="bill_address"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 3" }} />
// {/*Contact Name*/} 
// <TextField fullWidth variant="filled" type="text" label="Contact Name" value={orderInformation.contact_name || " "} name="contact_name"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*Contact Phone*/} 
// <TextField fullWidth variant="filled" type="text" label="Contact Phone" value={orderInformation.contact_phone || " "} name="contact_phone"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*Contact Email*/} 
// <TextField fullWidth variant="filled" type="text" label="Contact Email" value={orderInformation.contact_email || " "} name="contact_email"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 2" }} />
// </Box>
// {/* Hardware,Software,Progress,Additional Information,Internal Invoices,Salesforce Link */}
// <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
// {/*Hardware*/} 
// <TextField fullWidth variant="filled" type="text" label="Hardware" value={orderInformation.hardware || " "} name="hardware"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*software*/} 
// <TextField fullWidth variant="filled" type="text" label="Software" value={orderInformation.software || " "} name="software"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*progress*/} 
// <TextField fullWidth variant="filled" type="text" label="Progress" value={orderInformation.progress || " "} name="progress"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*additional_information*/} 
// <TextField fullWidth variant="filled" type="text" label="Additional Information" value={orderInformation.additional_information || " "} name="additional_information"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 4" }} />
// {/*internal_invoices*/} 
// <TextField fullWidth variant="filled" type="text" label="Internal Invoice #" value={orderInformation.internal_invoices || " "} name="internal_invoices"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*salesforce_link*/} 
// <TextField fullWidth variant="filled" type="text" label="Salesforce Link" value={orderInformation.salesforce_link || " "} name="salesforce_link"
// onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 2" }} />
// </Box>
// {/* slack channel, notifications, slack channel archive, software cancelled, opportunityid, orderid */}
// <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
// {/*slack_channel*/} 
// <TextField fullWidth variant="filled" type="text" label="Slack Channel" value={orderInformation.slack_channel || " "} name="slack_channel"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*notifications*/} 
// <TextField fullWidth variant="filled" type="text" label="Notifications" value={orderInformation.notifications || " "} name="notifications"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*slack_channel_archive*/} 
// <TextField fullWidth variant="filled" type="text" label="Slack Channel Archive" value={orderInformation.slack_channel_archive || " "} name="slack_channel_archive"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*software_cancelled*/} 
// <TextField fullWidth variant="filled" type="text" label="Software Cancelled" value={orderInformation.software_cancelled || " "} name="software_cancelled"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*opportunityid*/} 
// <TextField fullWidth variant="filled" type="text" label="Opportunity ID" value={orderInformation.opportunityid || " "} name="opportunityid"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// {/*orderid*/} 
// <TextField fullWidth variant="filled" type="text" label="Order ID" value={orderInformation.orderid || " "} name="orderid"
//     onChange={handleFieldChangeOrder} sx={{ gridColumn: "span 1" }} />
// </Box>

// {inventoryData.hardware === "yes" && (<>
// <h1>Hardware Information</h1>
// {/* M6000, M3000, Photon 400, Photon 300, Photon 250, Photon 150, Photon 100, Additional Information, Additonal Warranty*/}
// <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
// {/*M6000*/} 
// <TextField fullWidth variant="filled" type="text" label="M6000" value={orderInformationHardware.M6000 || " "} name="M6000"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*M3000*/} 
// <TextField fullWidth variant="filled" type="text" label="M3000" value={orderInformationHardware.M3000 || " "} name="M3000"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Photon 400*/} 
// <TextField fullWidth variant="filled" type="text" label="Photon 400" value={orderInformationHardware.Photon_400 || " "} name="Photon_400"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Photon 300*/} 
// <TextField fullWidth variant="filled" type="text" label="Photon 300" value={orderInformationHardware.Photon_300 || " "} name="Photon_300"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Photon 250*/} 
// <TextField fullWidth variant="filled" type="text" label="Photon 250" value={orderInformationHardware.Photon_250 || " "} name="Photon_250"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Photon 150*/} 
// <TextField fullWidth variant="filled" type="text" label="Photon 150" value={orderInformationHardware.Photon_150 || " "} name="Photon_150"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Photon 100*/} 
// <TextField fullWidth variant="filled" type="text" label="Photon 100" value={orderInformationHardware.Photon_100 || " "} name="Photon_100"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />
// {/*Additional Information*/} 
// <TextField fullWidth variant="filled" type="text" label="Additional Information" value={orderInformationHardware.additional_information || " "} name="additional_information"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 4" }} />
// {/*Additional Warranty*/} 
// <TextField fullWidth variant="filled" type="text" label="Additional Warranty" value={orderInformationHardware.additional_warranty || " "} name="additional_warranty"
//         onChange={handleFieldChangeOrderHardware} sx={{ gridColumn: "span 1" }} />     
// </Box>
// </>)}



// <Box m="20px">
// <Button type="submit" color="secondary" variant="contained">
// Update Order
// </Button>
// </Box>

// </form>          

// {hardwareFields.map(field => {

// const value = importedProductsDataHardware[field];

// const formattedField = field.replace(/_/g, ' ');
// const itemOnlyFieldArray = [];
// for (let i = 0; i < importedProductsDataHardwareItems.length; i++)
// {
//     if(importedProductsDataHardwareItems[i].model === formattedField)
//     {
//         itemOnlyFieldArray.push(importedProductsDataHardwareItems[i]);
//     }
// }

// // Check if value is in the array and not equal to 0 or ""
// if (hardwareArray.includes(field) && value !== 0 && value !== "" && value !== " " && value !== null)
// {
//     const components = [];

//     // Place the <h1> element here to display the section heading
//     components.push(<h2 key={`${field}-header`}>{field.replace(/_/g, ' ')}</h2>);
//     let itemCounter = 0;
//     // console.log("this will run", value, " times. With ", itemOnlyFieldArray.length, "times", "for item", field);
//     for (let i = 0; i < value; i++)
//     {
//         if(itemCounter < itemOnlyFieldArray.length)
//         {
//             components.push(
//                 <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//                     key="`${field}"
//                 >
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`${field} #${i}`}
//                     value={itemOnlyFieldArray[itemCounter].serial_number || " "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Ship Date`}
//                     value={itemOnlyFieldArray[itemCounter].ship_date || " "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Tracking Number`}
//                     value={itemOnlyFieldArray[itemCounter].tracking_number || " "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Stellar Cyber Warranty`}
//                     value={itemOnlyFieldArray[itemCounter].stellarcyber_warranty || " "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                 </Box>
//                 );
//             itemCounter++;
//         }
//         else
//         {
//             components.push(
//                 <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//                     key="`${field}"
//                 >
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`${field} #${i}`}
//                     value={" "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Ship Date`}
//                     value={" "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Tracking Number`}
//                     value={" "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                     <TextField
//                     fullWidth
//                     type="text"
//                     label={`Stellar Cyber Warranty`}
//                     value={" "}
//                     name="additional_hardware_info"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                     />
//                 </Box>
//                 );
//         }
//     }
//     return components;    
// }})}



            
        


//         {inventoryData.software === "yes" && (
//               <>
//                     <h1>Software Information</h1>

//               <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                       "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//               >
//                 <TextField
//                   fullWidth
//                   type="text"
//                   label="Number of Items"
//                   value={importedProductsDataSoftware.items || " "}
//                   name="items"
//                   sx={{ gridColumn: "span 1" }}
//                   disabled
//                 />
//                 <TextField
//                     fullWidth
//                     type="text"
//                     label="Ramp"
//                     value={importedProductsDataSoftware.ramp || " "}
//                     name="ramp"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />


//                   <TextField
//                     fullWidth
//                     type="text"
//                     label="Software Cancelled"
//                     value={importedProductsDataSoftware.software_cancelled || " "}
//                     name="software_cancelled"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />

//               </Box>

//               <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                       "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//               >
//                 <TextField
//                     fullWidth
//                     type="text"
//                     label="OTP"
//                     value={importedProductsDataSoftware.otp || " "}
//                     name="otp"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />
//                   <TextField
//                     fullWidth
//                     type="text"
//                     label="End Date"
//                     value={importedProductsDataSoftware.end_date || " "}
//                     name="end_date"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />

//                 </Box>

//                 <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                       "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//               >
//                   <TextField
//                     fullWidth
//                     type="text"
//                     label="SaaS"
//                     value={importedProductsDataSoftware.SaaS || " "}
//                     name="end_date"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />
//                   <TextField
//                     fullWidth
//                     type="text"
//                     label="Domain Name"
//                     value={importedProductsDataSoftware.domain_name || " "}
//                     name="domain_name"
//                     sx={{ gridColumn: "span 1" }}
//                     disabled
//                   />
                  

//                 <TextField
//                     fullWidth
//                     type="text"
//                     label="Additional Software Information"
//                     value={importedProductsDataSoftware.software_additional_information_data || " "}
//                     name="additional_sofatware_info"
//                     sx={{ gridColumn: "span 4" }}
//                     disabled
//                   />


//                 </Box>
                  
//               <Box  
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                       "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//               >
              
//               {/* Display all items, quantities, and terms from importedProductsDataSoftware in separate TextFields */
//               }
//               {Array.from({ length: importedProductsDataSoftware.items }).map((_, index) => {
//                   const item = importedProductsDataSoftware[`item_${index+1}`] || '';
//                   const quantity = importedProductsDataSoftware[`quantity_${index+1}`] || '';
//                   const term = importedProductsDataSoftware[`term_${index+1}`] || '';

//                   return (
//                       <React.Fragment key={index}>
//                           <TextField
//                               fullWidth
//                               type="text"
//                               label={`Item ${index + 1}`}
//                               value={item}
//                               name={`item_${index}`}
//                               sx={{ gridColumn: "span 2" }}
//                               disabled
//                           />
//                           <TextField
//                               fullWidth
//                               type="text"
//                               label={`Quantity ${index + 1}`}
//                               value={quantity}
//                               name={`quantity_${index}`}
//                               sx={{ gridColumn: "span 1" }}
//                               disabled
//                           />
//                           <TextField
//                               fullWidth
//                               type="text"
//                               label={`Term ${index + 1}`}
//                               value={term}
//                               name={`term_${index}`}
//                               sx={{ gridColumn: "span 1" }}
//                               disabled
//                           />
//                       </React.Fragment>
//                   );
//               })}
//               </Box>

//               <Box
//                     display="grid"
//                     margin="30px"
//                     gap="30px"
//                     gridTemplateColumns="repeat(4, minmax(0, 4fr))"
//                     alignItems="center"
//                     sx={{
//                       "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//                     }}
//                   >
//               </Box>

//               </>
//         )}