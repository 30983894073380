import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped, LoginInformation } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";
import { useParams } from 'react-router-dom';

import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import React from 'react';
import { getUrl, getProperties, uploadData } from 'aws-amplify/storage';
import { Storage } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';

import { createPackingListNewWindow } from "../../functions/create_packinglist";
import { createWelcomeLetter } from "../../functions/create_welcomeletter";

import {fetchInventoryDataTest} from "../../functions/order_functions";
import {displayorderinformation_setone, displayorderinformation_settwo, displayorderinformation_setthree, displayorderinformation_setfour, displayorderinformation_hardware_setone, displayorderinformation_hardware_settwo, displayorderinformation_hardware_setthree
  ,displayorderinformation_software_setone, displayorderinformation_software_settwo, displayorderinformation_software_setthree, displayorderinformation_software_setfour} from '../../functions/create_order';

  

// import { Auth } from 'aws-amplify';

Amplify.configure(awsExports);



const DisplayOrderInformation = () => {

  const hardwareFields = ["M6000", "M3000", "Photon_400", "Photon_300", "Photon_250", "Photon_160", "Photon_150", "Photon_100"];
  const { internalOrderNumber } = useParams();

    const [q_order, set_q_order] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const query = await fetchInventoryDataTest(internalOrderNumber);
          set_q_order(query);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [internalOrderNumber]);

    console.log(q_order);

  const components_order_setone = [];
  components_order_setone.push(displayorderinformation_setone(q_order.orderData, isNonMobile));
  const components_order_settwo = [];
  components_order_settwo.push(displayorderinformation_settwo(q_order.orderData, isNonMobile));
  const components_order_setthree = [];
  components_order_setthree.push(displayorderinformation_setthree(q_order.orderData, isNonMobile));
  const components_order_setfour = [];
  components_order_setfour.push(displayorderinformation_setfour(q_order.orderData, isNonMobile));

  const components_hardwareorder_setone = [];
  components_hardwareorder_setone.push(displayorderinformation_hardware_setone(q_order.orderDataHardware, hardwareFields, isNonMobile));
  const components_hardwareorder_settwo = [];
  components_hardwareorder_settwo.push(displayorderinformation_hardware_settwo(q_order.orderDataHardware, isNonMobile));
  const components_hardwareorder_setthree = [];
  components_hardwareorder_setthree.push(displayorderinformation_hardware_setthree(q_order.orderDataHardware, isNonMobile));

  const components_software_setone = [];
  components_software_setone.push(displayorderinformation_software_setone(q_order.orderDataSofware, isNonMobile));
  const components_software_settwo = [];
  components_software_settwo.push(displayorderinformation_software_settwo(q_order.orderDataSofware, isNonMobile));
  const components_software_setthree = [];
  components_software_setthree.push(displayorderinformation_software_setthree(q_order.orderDataSofware, isNonMobile));
  const components_software_setfour = [];
  components_software_setfour.push(displayorderinformation_software_setfour(q_order.orderDataSofware, isNonMobile));




    const [userprivelege, setuserprivelege] = useState(null);
    const [username, setusername] = useState(null);

    // useEffect(() => {
    //   async function fetchUser() {
    //     try {
    //       const user = await Auth.currentAuthenticatedUser();
    //       setusername(user.username);
  
    //       const awsuserdata = await DataStore.query(LoginInformation, (order) =>
    //         order.username.eq(user.username)
    //       );
    //       setuserprivelege(awsuserdata[0].privilege);
    //     } catch (error) {
    //       console.error('Error fetching user:', error);
    //     }
    //   }
    
    //   fetchUser();
    // }, []);
  
  


    //Variable for userInformation
    const [inventoryData, setInventoryData] = useState([]);

    //Variable for selectedUsers
    const [selectedInventoryData, setSelectedInventoryData] = useState([]);
    const [external_order_number_data, setExternal_order_number_data] = useState([]);
    const [order_type_data, set_order_type_data] = useState([]);
    const [order_date_data, set_order_date_data] = useState([]);
    const [partner_name_data, set_partner_name_data] = useState([]);
    const [end_customer_name_data, set_end_customer_name_data] = useState([]);
    const [sales_rep_data, set_sales_rep_data] = useState([]);
    const [ship_country_data, set_ship_country_data] = useState([]);
    const [ship_address_data, set_ship_address_data] = useState([]);
    const [bill_country_data, set_bill_country_data] = useState([]);
    const [bill_address_data, set_bill_address_data] = useState([]);
    const [contact_name_data, set_contact_name_data] = useState([]);
    const [contact_phone_data, set_contact_phone_data] = useState([]);
    const [contact_email_data, set_contact_email_data] = useState([]);

    const [hardware_data, set_hardware_data] = useState([]);
    const [software_data, set_software_data] = useState([]);
    const [progress_data, set_progress_data] = useState([]);

    const [additional_information_data, set_additional_information_data] = useState([]);

    const [slack_channel_data, set_slack_channel_data] = useState([]);
    const [notifications_data, set_notifications_data] = useState([]);

    const [slack_channel_archive_data, set_slack_channel_archive_data] = useState([]);
    const [internal_invoice_data, set_internal_invoice] = useState([]);
    const [salesforce_link_data, set_salesforce_link_data] = useState([]);
    const [software_cancelled_data, set_software_cancelled_data] = useState([]);

    const [opportunityid_data, set_opportunityid_data] = useState([]);
    const [orderid_data, set_orderid_data] = useState([]);



    const [hardware_additional_information_data, set_hardware_dditional_information_data] = useState([]);
    const [hardware_additional_warranty_data, set_hardware_additional_warranty_data] = useState([]);

    const [software_additional_information_data, set_software_additional_information_data] = useState([]);
    const [importedProductsDataSoftware, set_importedProductsDataSoftware] = useState([]);
    const [importedProductsDataHardware, set_importedProductsDataHardware] = useState([]);
    const [importedProductsDataHardwareItems, set_importedProductsDataHardwareItems] = useState([]);

    //Fetch the Inventory Data
    async function fetchInventoryData() {
        try {
        // const importedProductsData = await DataStore.query(ImportedOrders, (order) => order.internal_order_number('eq', internalOrderNumber));
        // const importedProductsData = await DataStore.query(ImportedOrders, internal_order_number => ('eq',  internalOrderNumber));
        const importedProductsData = await DataStore.query(ImportedOrders, order => order.internal_order_number.eq(internalOrderNumber,));
        
        if (importedProductsData) 
        {
            setInventoryData(importedProductsData);
            setExternal_order_number_data(importedProductsData[0].external_order_number);
            set_order_type_data(importedProductsData[0].order_type);
            set_order_date_data(importedProductsData[0].order_date);
            set_partner_name_data(importedProductsData[0].partner_name);
            set_end_customer_name_data(importedProductsData[0].end_customer_name);
            set_sales_rep_data(importedProductsData[0].sales_rep);
            set_ship_country_data(importedProductsData[0].ship_country);
            set_ship_address_data(importedProductsData[0].ship_address);
            set_bill_country_data(importedProductsData[0].bill_country);
            set_bill_address_data(importedProductsData[0].bill_address);
            set_contact_name_data(importedProductsData[0].contact_name);
            set_contact_phone_data(importedProductsData[0].contact_phone);
            set_contact_email_data(importedProductsData[0].contact_email);
            set_hardware_data(importedProductsData[0].hardware);
            set_software_data(importedProductsData[0].software);
            set_progress_data(importedProductsData[0].progress);
            set_additional_information_data(importedProductsData[0].additional_details);
            set_internal_invoice(importedProductsData[0].internal_invoice);
            set_salesforce_link_data(importedProductsData[0].salesforce_link);
            set_slack_channel_data(importedProductsData[0].slack_channel);
            set_notifications_data(importedProductsData[0].notifications);
            set_slack_channel_archive_data(importedProductsData[0].slack_channel_archive);
            set_software_cancelled_data(importedProductsData[0].software_cancelled);
            set_opportunityid_data(importedProductsData[0].opportunityid);
            set_orderid_data(importedProductsData[0].orderid);

            if(importedProductsData[0].hardware === "yes")
            {
              const importedProductsDataHardwareQ = await DataStore.query(ImportedOrdersHardware, order => order.internal_order_number.eq(internalOrderNumber,));
              set_importedProductsDataHardware(importedProductsDataHardwareQ[0]);
              console.log(importedProductsDataHardwareQ);

              const importedProductsDataHardwareItems = await DataStore.query(ImportedProductsShipped, order => order.internal_order_number.eq(internalOrderNumber,));
              // console.log(importedProductsDataHardwareItems);
              set_importedProductsDataHardwareItems(importedProductsDataHardwareItems);

            }

            if(importedProductsData[0].software === "yes")
            {
              const awsOrderSoftwareData = await DataStore.query(ImportedOrdersSoftware, order => order.internal_order_number.eq(internalOrderNumber,));
              set_importedProductsDataSoftware(awsOrderSoftwareData[0]);

            }
        } 
        else 
        {
        }
        } catch (error) {
        console.error('Error fetching data:', error);
        }
    }
    //Calls the Fetch Inventory Data
    useEffect(() => 
    {
        fetchInventoryData();
    }, []);








    const isNonMobile = useMediaQuery("(min-width:600px)");  

    const hardwareArray = ["M6000", "M3000", "Photon_400", "Photon_300", "Photon_250", "Photon_150", "Photon_100"];

    const viewPackingListClick = async () => {
      const filename = internalOrderNumber + "_PackingList.pdf";
      let file_exists = false;

      try{
        file_exists = await getUrl({
          key:filename,
          options:{
            validateObjectExistence: true
          }
        });
      }
      catch (error) {
        console.log(error);
      }

      console.log("THE FILE DOES EXISTS : " + file_exists);

      if(file_exists == false)
      {
        createPackingListNewWindow(internalOrderNumber, filename);
      }

      try{
        file_exists = await getUrl({
          key:filename
        });
      }
      catch (error) {
        console.log(error);
      }

      console.log(file_exists.url.href);
      window.open(file_exists.url.href, '_blank');
    };

    const viewWelcomeLetterClick = async () => {
      const filename = internalOrderNumber + "_WelcomeLetter.pdf";
      let file_exists = false;

      try{
        file_exists = await getUrl({
          key:filename,
          options:{
            validateObjectExistence: true
          }
        });
      }
      catch (error) {
        console.log(error);
      }

      console.log("THE FILE DOES EXISTS : " + file_exists);

      if(file_exists == false)
      {
        createWelcomeLetter(internalOrderNumber, filename);
      }
      try{
        file_exists = await getUrl({
          key:filename
        });
      }
      catch (error) {
        console.log(error);
      }

      console.log(file_exists.url.href);
      window.open(file_exists.url.href, '_blank');



    }


  return (
    <Box m="20px">
      <h1>Order Information for {internalOrderNumber}</h1>
              <Box
                display="grid"
                margin="30px"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                alignItems="center"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                 {/* {userprivelege === "admin" && (
                  <Link to={`/editorderinformation/${internalOrderNumber}`}>
                  <Button
                    variant="contained"
                    color="secondary"
                  >
                    Edit Order
                  </Button>
                </Link>
                 )} */}
                 <Link to={`/editorderinformation/${internalOrderNumber}`}>
                  <Button
                    variant="contained"
                    color="secondary"
                  >
                    Edit Order
                  </Button>
                </Link>
              </Box>

            {/* Internal Order Number, External Order Number, Order Type/Date, Partner/End/Sales */}
            <Box
              display="grid"
              margin="30px"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 4fr))"
              alignItems="center"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
            {/* Internal Order Number */}
              <TextField
              fullWidth
              type="text"
              label="Internal Order Number"
              value={internalOrderNumber}
              name="internal_order_number"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
            {/* External Order Number */}
              <TextField
              fullWidth
              type="text"
              label="External Order Number"
              value={external_order_number_data}
              name="external_order_number"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
            {/* Order Type */}
              <TextField
              fullWidth
              type="text"
              label="Order Type"
              value={order_type_data}
              name="order_type"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
            {/* Order Date */}
            <TextField
              fullWidth
              type="text"
              label="Order Date"
              value={order_date_data}
              name="order_date"
              sx={{ gridColumn: "span 1" }}
              disabled
              /> 
            {/* Partner Name */}
            <TextField
              fullWidth
              type="text"
              label="Partner Name"
              value={partner_name_data}
              name="partner_name"
              sx={{ gridColumn: "span 1" }}
              disabled
              /> 
            {/* End Customer Name*/}
            <TextField
              fullWidth
              type="text"
              label="End Customer Name"
              value={end_customer_name_data}
              name="partner_name"
              sx={{ gridColumn: "span 1" }}
              disabled
            /> 
            {/*Sales Rep*/}
            <TextField
              fullWidth
              type="text"
              label="Sales Rep"
              value={sales_rep_data}
              name="sales_rep"
              sx={{ gridColumn: "span 1" }}
              disabled
            /> 
            </Box>
            {/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
            <Box
              display="grid"
              margin="30px"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 4fr))"
              alignItems="center"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
            {/*Ship Country*/}
            <TextField
              fullWidth
              type="text"
              label="Ship Country"
              value={ship_country_data}
              name="ship_country"
              sx={{ gridColumn: "span 1" }}
              disabled
            /> 
            {/*Ship Address*/}
            <TextField
              fullWidth
              multiline
              type="text"
              label="Ship Address"
              value={ship_address_data}
              name="ship_address"
              sx={{ gridColumn: "span 3" }}
              disabled
            />
            {/*Bill Country*/}
            <TextField
              fullWidth
              multiline
              type="text"
              label="Bill Country"
              value={bill_country_data}
              name="bill_country"
              sx={{ gridColumn: "span 1" }}
              disabled
            /> 
            {/*Bill Address*/}
            <TextField
              fullWidth
              type="text"
              label="Bill Address"
              value={bill_address_data}
              name="bill_address"
              sx={{ gridColumn: "span 3" }}
              disabled
            />
            {/*Contact Name*/} 
            <TextField
            fullWidth
            type="text"
            label="Contact Name"
            value={contact_name_data}
            name="contact_name"
            sx={{ gridColumn: "span 1" }}
            disabled
            />
            {/*Contact Phone*/} 
            <TextField
            fullWidth
            type="text"
            label="Contact Phone"
            value={contact_phone_data}
            name="contact_phone"
            sx={{ gridColumn: "span 1" }}
            disabled
            />
            {/*Contact Email*/} 
            <TextField
            fullWidth
            type="text"
            label="Contact Email"
            value={contact_email_data}
            name="contact_email"
            sx={{ gridColumn: "span 1" }}
            disabled
            />
            
            


            </Box>
            {/* Hardware, Software, Progress, Additional Information, Internal Invoice, Salesforce Link */}
            <Box
              display="grid"
              margin="30px"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 4fr))"
              alignItems="center"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Hardware*/}
              <TextField
                fullWidth
                type="text"
                label="Hardware"
                value={hardware_data}
                name="hardware"
                sx={{ gridColumn: "span 1" }}
                disabled
                />  
              {/* Software */}
              <TextField
              fullWidth
              type="text"
              label="Software"
              value={software_data}
              name="software"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
              {/* Progress */}
              <TextField
              fullWidth
              type="text"
              label="Progresss"
              value={progress_data}
              name="progress"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
              {/* Additional Details */}
              <TextField
              fullWidth
              type="text"
              label="Additional Information"
              value={additional_information_data}
              name="additional_information"
              sx={{ gridColumn: "span 4" }}
              disabled
              />
               {/* Internal Invoice */}
               <TextField
              fullWidth
              type="text"
              label="Internal Invoice"
              value={internal_invoice_data}
              name="internal_invoices"
              sx={{ gridColumn: "span 1" }}
              disabled
              />
               {/* Salesforce Link*/}
               <TextField
              fullWidth
              type="text"
              label="Salesforce Link"
              value={salesforce_link_data}
              name="salesforce_link"
              sx={{ gridColumn: "span 2" }}
              disabled
              />
            </Box>
            {/**/}
            <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center"
              sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
               <TextField
                fullWidth
                type="text"
                label="Slack Channel"
                value={slack_channel_data}
                name="slack_channel"
                sx={{ gridColumn: "span 1" }}
                disabled
                />  
               <TextField
                fullWidth
                type="text"
                label="Notifications"
                value={notifications_data}
                name="notifications"
                sx={{ gridColumn: "span 1" }}
                disabled
                /> 
                <TextField
                fullWidth
                type="text"
                label="Slack Channel Archive"
                value={slack_channel_archive_data}
                name="slack_channel_archive"
                sx={{ gridColumn: "span 1" }}
                disabled
                /> 
                 <TextField
                fullWidth
                type="text"
                label="Software Cancelled"
                value={software_cancelled_data}
                name="software_cancelled"
                sx={{ gridColumn: "span 1" }}
                disabled
                /> 
                 <TextField
                fullWidth
                type="text"
                label="Opportunity ID"
                value={opportunityid_data}
                name="opportunityid"
                sx={{ gridColumn: "span 1" }}
                disabled
                /> 
                <TextField
                fullWidth
                type="text"
                label="Order ID"
                value={orderid_data}
                name="orderid"
                sx={{ gridColumn: "span 1" }}
                disabled
                /> 

            </Box>
           
            {hardware_data === "yes" && importedProductsDataHardware && Object.keys(importedProductsDataHardware).length > 0 &&(<>
            <h1>Hardware Information</h1>
            <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center"
              sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={viewPackingListClick}
                  >
                    View Packing List
                  </Button>
            </Box>
            {/* Hardware Order Information */}
            <Box
                  display="grid"
                  margin="30px"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                  alignItems="center"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <TextField
                  fullWidth
                  type="text"
                  label="M6000"
                  value={importedProductsDataHardware.M6000 || ""}
                  name="M6000"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  /> 
                   <TextField
                  fullWidth
                  type="text"
                  label="M3000"
                  value={importedProductsDataHardware.M3000 ||  ""}
                  name="M3000"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  />  
                   <TextField
                  fullWidth
                  type="text"
                  label="Photon 400"
                  value={importedProductsDataHardware.Photon_400 || " "}
                  name="Photon_400"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  />  
                  <TextField
                  fullWidth
                  type="text"
                  label="Photon 300"
                  value={importedProductsDataHardware.Photon_300 || " "}
                  name="Photon_300"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  />  
                  <TextField
                  fullWidth
                  type="text"
                  label="Photon 250"
                  value={importedProductsDataHardware.Photon_250 || " "}
                  name="Photon_250"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  />  
                   <TextField
                  fullWidth
                  type="text"
                  label="Photon 150"
                  value={importedProductsDataHardware.Photon_150 || " "}
                  name="Photon_150"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  />  
                  <TextField
                  fullWidth
                  type="text"
                  label="Photon 100"
                  value={importedProductsDataHardware.Photon_100 || " "}
                  name="Photon_100"
                  sx={{ gridColumn: "span 1" }}
                  disabled
                  /> 
                  {/* Additional items to display when hardware is "yes" */}
                <TextField
                  fullWidth
                  type="text"
                  label="Additional Hardware Information"
                  value={importedProductsDataHardware.addtional_details || ""}
                  name="additional_details"
                  sx={{ gridColumn: "span 4" }}
                  disabled
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Additional Hardware Information"
                  value={importedProductsDataHardware.additional_warranty || ""}
                  name="additional_hardware_info"
                  sx={{ gridColumn: "span 4" }}
                  disabled
                />
            </Box>
              {hardwareFields.map(field => {
                  const value = importedProductsDataHardware[field];

                  // Check if value is in the array and not equal to 0 or ""
                  if (hardwareArray.includes(field) && value !== 0 && value !== "" && value !== null) {
                    const components = [];

                    // Place the <h1> element here to display the section heading
                    components.push(
                      <h2 key={`${field}-header`}>{`${field}`}</h2>
                    );

                    for (let i = 0; i < importedProductsDataHardwareItems.length; i++) {
                      components.push(
                        <Box
                          display="grid"
                          margin="30px"
                          gap="30px"
                          gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                          alignItems="center"
                          sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                          }}
                          key="`${field}"
                        >
                          <TextField
                            fullWidth
                            type="text"
                            label={`${field} #${i}`}
                            value={importedProductsDataHardwareItems[i].serial_number || " "}
                            name="additional_hardware_info"
                            sx={{ gridColumn: "span 1" }}
                            disabled
                          />
                          <TextField
                          fullWidth
                          type="text"
                          label={`Ship Date`}
                          value={importedProductsDataHardwareItems[i].ship_date || " "}
                          name="additional_hardware_info"
                          sx={{ gridColumn: "span 1" }}
                          disabled
                          />
                          <TextField
                          fullWidth
                          type="text"
                          label={`Tracking Number`}
                          value={importedProductsDataHardwareItems[i].tracking_number || " "}
                          name="additional_hardware_info"
                          sx={{ gridColumn: "span 1" }}
                          disabled
                          />
                          <TextField
                          fullWidth
                          type="text"
                          label={`Stellar Cyber Warranty`}
                          value={importedProductsDataHardwareItems[i].stellarcyber_warranty || " "}
                          name="additional_hardware_info"
                          sx={{ gridColumn: "span 1" }}
                          disabled
                          />
                        </Box>
                      );
                    }

                    return components;
                  }
                  return null; // Render nothing if the condition is not met
                })}

                
            </>)}


            {software_data === "yes" && importedProductsDataSoftware && Object.keys(importedProductsDataSoftware).length > 0 && (
                  <>
                        <h1>Software Information</h1>
                        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center"
                          sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={viewWelcomeLetterClick}
                              >
                                View Welcome Letter
                              </Button>
                        </Box>
                  <Box
                        display="grid"
                        margin="30px"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                        alignItems="center"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      label="Number of Items"
                      value={importedProductsDataSoftware.items || ""}
                      name="items"
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                        fullWidth
                        type="text"
                        label="Ramp"
                        value={importedProductsDataSoftware.ramp || " "}
                        name="ramp"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />


                      <TextField
                        fullWidth
                        type="text"
                        label="Software Cancelled"
                        value={importedProductsDataSoftware.software_cancelled || " "}
                        name="software_cancelled"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />

                  </Box>

                  <Box
                        display="grid"
                        margin="30px"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                        alignItems="center"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                  >
                    <TextField
                        fullWidth
                        type="text"
                        label="OTP"
                        value={importedProductsDataSoftware.otp || " "}
                        name="otp"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />
                      <TextField
                        fullWidth
                        type="text"
                        label="End Date"
                        value={importedProductsDataSoftware.end_date || " "}
                        name="end_date"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />

                    </Box>

                    <Box
                        display="grid"
                        margin="30px"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                        alignItems="center"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                  >
                      <TextField
                        fullWidth
                        type="text"
                        label="SaaS"
                        value={importedProductsDataSoftware.SaaS || " "}
                        name="end_date"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />
                      <TextField
                        fullWidth
                        type="text"
                        label="Domain Name"
                        value={importedProductsDataSoftware.domain_name || " "}
                        name="domain_name"
                        sx={{ gridColumn: "span 1" }}
                        disabled
                      />
                      

                    <TextField
                        fullWidth
                        type="text"
                        label="Additional Software Information"
                        value={importedProductsDataSoftware.software_additional_information_data || " "}
                        name="additional_sofatware_info"
                        sx={{ gridColumn: "span 4" }}
                        disabled
                      />


                    </Box>
                      
                  <Box  
                        display="grid"
                        margin="30px"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                        alignItems="center"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                  >
                  
                  {/* Display all items, quantities, and terms from importedProductsDataSoftware in separate TextFields */
                  }
                  {Array.from({ length: importedProductsDataSoftware.items }).map((_, index) => {
                      const item = importedProductsDataSoftware[`item_${index+1}`] || '';
                      const quantity = importedProductsDataSoftware[`quantity_${index+1}`] || '';
                      const term = importedProductsDataSoftware[`term_${index+1}`] || '';

                      return (
                          <React.Fragment key={index}>
                              <TextField
                                  fullWidth
                                  type="text"
                                  label={`Item ${index + 1}`}
                                  value={item}
                                  name={`item_${index}`}
                                  sx={{ gridColumn: "span 2" }}
                                  disabled
                              />
                              <TextField
                                  fullWidth
                                  type="text"
                                  label={`Quantity ${index + 1}`}
                                  value={quantity}
                                  name={`quantity_${index}`}
                                  sx={{ gridColumn: "span 1" }}
                                  disabled
                              />
                              <TextField
                                  fullWidth
                                  type="text"
                                  label={`Term ${index + 1}`}
                                  value={term}
                                  name={`term_${index}`}
                                  sx={{ gridColumn: "span 1" }}
                                  disabled
                              />
                          </React.Fragment>
                      );
                  })}
                  </Box>

                  <Box
                        display="grid"
                        margin="30px"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 4fr))"
                        alignItems="center"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                      >
                  </Box>

                  </>

                  
            )}
              
    </Box>
  );

};

export default DisplayOrderInformation;
