import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useState,useEffect } from "react";
import { inventory } from "./inventory";
import {hardwareout} from "./hardwareout";


import { ImportedProducts,ImportedProductsShipped, LoginInformation } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify } from 'aws-amplify'
import awsExports from "../../aws-exports";
// import { Auth } from 'aws-amplify';


Amplify.configure(awsExports);



const Inventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const [username, setusername] = useState(null);
  const [userprivelege, setuserprivelege] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      // try {
      //   const user = await Auth.currentAuthenticatedUser();
      //   setusername(user.username);

      //   const awsuserdata = await DataStore.query(LoginInformation, (order) =>
      //     order.username.eq(user.username)
      //   );
      //   setuserprivelege(awsuserdata[0].privilege);
      // } catch (error) {
      //   console.error('Error fetching user:', error);
      // }
    }
  
    fetchUser();
  }, []);







  const [displayType, setDisplayType] = useState("inventory");
  const handleToggleDisplay = (type) => {setDisplayType(type);};

  //Variable for selectedUsers
  const [selectedInventoryData, setSelectedInventoryData] = useState([]);

  const handleTestImportToInventory = async () => {
    console.log("test import starting");
    try{
        const returnmessage = await DataStore.save(
          new ImportedProducts({
          "serial_number": "testsn1",
          "model": "Photon300",
          "version": "test",
          "location": "test",
          "additional_information": "test",
          "manufacturer_warranty": "test",
          "recieved_date": "1970-01-01Z",
        })
      );
    }
    catch(error)
    {
      console.error(error);
    }

  };

  const handleImportToInventory = async () => {
    if (selectedInventoryData.length === 0) {return;}
    // Create an array to store the selected rows' data
    const selectedDataArray = [];

    if(displayType === "inventory")
    {
      // Iterate through selectedInventoryData and use inventory data
      selectedInventoryData.forEach((selectedRowId) => 
        {const selectedRow = inventory.find((row) => row.serial_number === selectedRowId);
          if (selectedRow) {selectedDataArray.push(selectedRow);}
        });
    }
    else
    {
          // Iterate through selectedInventoryData and use inventory data
          selectedInventoryData.forEach((selectedRowId) => 
          {const selectedRow = hardwareout.find((row) => row.serial_number === selectedRowId);
            if (selectedRow) {selectedDataArray.push(selectedRow);}
          });
    }

    for (const selectedProduct of selectedDataArray) {
      if(displayType === "inventory")
        {
          
          try {
            const existingSerialNumber = await DataStore.query(ImportedProducts, product => product.serial_number.eq(selectedProduct.serial_number,));
            if (existingSerialNumber && existingSerialNumber.length > 0) 
              {
                console.log(`Order ${selectedProduct.serial_number} already exists in imported_orders.`);
                continue; // Skip this order and move to the next one
              }
            else
              {
                await DataStore.save(
                  new ImportedProducts({
                    serial_number: selectedProduct.serial_number,
                    recieved_date:  selectedProduct.recieved_date,
                    model: selectedProduct.model,
                    version: selectedProduct.version,
                    location: selectedProduct.location,
                    additional_information: selectedProduct.additional_information,
                    manufacturer_warranty: selectedProduct.manufacturer_warranty,
                    
                  })
                );
              }
          } catch (saveError) {
            console.error('Error saving selected items :', saveError);
          }
        }
      else
        {
          try {
            const existingSerialNumber = await DataStore.query(ImportedProductsShipped, product => product.serial_number.eq(selectedProduct.serial_number,));
            if (existingSerialNumber && existingSerialNumber.length > 0) 
              {
                console.log(`Order ${selectedProduct.serial_number} already exists in imported_orders.`);
                continue; // Skip this order and move to the next one
              }
            else
              {
                await DataStore.save(
                  new ImportedProductsShipped({
                    serial_number: selectedProduct.serial_number,
                    model: selectedProduct.model,
                    version: selectedProduct.version,
                    location: selectedProduct.location,
                    additional_information: selectedProduct.additional_information,
                    manufacturer_warranty: selectedProduct.manufacturer_warranty,
                    stellarcyber_warranty: selectedProduct.stellarcyber_warranty,
                    ship_date: selectedProduct.ship_date,
                    internal_order_number: selectedProduct.internal_order_number,
                    tracking_number: selectedProduct.tracking_number,
                    received_date: selectedProduct.received_date,
                  })
                );
              }
          } catch (saveError) {
            console.error('Error saving selected items :', saveError);
          }
        }
        
      }
  };

  const columnsInventory = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "additional_information",
      headerName: "Additional Information",
      flex: 1,
    },
    {
      field: "manufacturer_warranty",
      headerName: "Manufacturer Warranty",
      flex: 1,
    },
    {
      field: "recieved_date",
      headerName: "Received Date",
      flex: 1,
    },
  ];
  const columnsHardwareOut = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "internal_order_number",
      headerName: "Internal Order Number",
      flex: 1,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "additional_information",
      headerName: "Additional Information",
      flex: 1,
    },
    {
      field: "manufacturer_warranty",
      headerName: "Manufacturer Warranty",
      flex: 1,
    },
    {
      field: "recieved_date",
      headerName: "Received Date",
      flex: 1,
    },
  ];
  let columns;
  switch (displayType) {
    case "inventory":
      columns = columnsInventory;
      break;
    case "shipped_inventory":
      columns = columnsHardwareOut;
      break;
    default:
      columns = columnsInventory;
      break;
  }
  let rows;
  switch (displayType) {
    case "inventory":
      rows = inventory;
      break;
    case "shipped_inventory":
      rows = hardwareout;
      break;
    default:
      rows = inventory;
      break;
  }

  return (
    <Box m="20px">
      <Header title="INVOICES" subtitle="List of Invoice Balances" />

      <Box m="20px">
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "inventory"}
          onClick={() => handleToggleDisplay("inventory")}
        >
          Show Inventory
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={displayType === "shipped_inventory"}
          onClick={() => handleToggleDisplay("shipped_inventory")}
        >
          Show Shipped Inventory
        </Button>
      </Box>


      <Box m="20px">

        
      <Button
          variant="contained"
          color="secondary"
          onClick={handleImportToInventory}
          disabled={selectedInventoryData.length === 0}
        >
          Import to Inventory
        </Button>

       
      <Button
        variant="contained"
        color="secondary"
        onClick={handleTestImportToInventory}
      >
        Import Test Data
      </Button>
    </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[1],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[1],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[1],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[1],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[1]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[1]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={rows}
          columns={columns}
          getRowId={(row) => row.serial_number}
          components={{ Toolbar: GridToolbar }}

          onRowSelectionModelChange={(newSelection) => {
            setSelectedInventoryData(newSelection);
          }}
          selectionModel={selectedInventoryData}
        />
      </Box>
    </Box>
  );

};

export default Inventory;

