import React from 'react';

const SalesforceOAuth = () => {
  const clientId = '3MVG9g9rbsTkKnAU77b4iwU42d9CVblck89jFVXLnrS4iZPVJUiSxpZGqqGHA8VU1hwbwyzWSnbxMAfiTgLax';
  const redirectUri = 'http://localhost:3000/2salesforce'; // Should match your Connected App settings
  const oauthScopes = 'api'; // Specify the desired OAuth scopes

  const handleOAuthClick = () => {
    const authUrl = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${oauthScopes}`;

    // Open the Salesforce OAuth page in a new window
    window.open(authUrl, 'Salesforce OAuth', 'width=600,height=400');
  };

  // In your actual component, you'll need to handle the redirect from Salesforce
  // and extract the authorization code, then exchange it for tokens.

  return (
    <div>
      <button onClick={handleOAuthClick}>Authorize with Salesforce</button>
    </div>
  );
};

export default SalesforceOAuth;
