import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useState,useEffect } from "react";



import { DataStore } from '@aws-amplify/datastore';
import { Amplify } from 'aws-amplify'
import awsExports from "../../aws-exports";

import { Item, ItemLog } from "../../models";

const ProductDisplay = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // Inventory Movement Log
    // Movement Date (will act as the ship date)
    // In/Out
    // Location (In : Texas, California (HQ), CCI) (Out : Customer)
    // Tracking Number (If it is out, if in will be blank or return tracking number)
    const [displayType, setDisplayType] = useState("item");


    //Variable for userInformation
    const [itemData, set_itemData] = useState([]);
    const [itemLogData, set_itemLogData] = useState([]);





  async function fetchData()
  {
      try {
      const importedItemData = await DataStore.query(Item);
      if (importedItemData) {set_itemData(importedItemData);}

      const importedItemLogData = await DataStore.query(ItemLog);
      if (importedItemLogData) {set_itemLogData(importedItemLogData);}
      } 
      catch (error) {console.error('Error fetching Login Information:', error);}
  }

  useEffect(() => {fetchData()}, []);

  //Variable for selected items
  const [selected_data, set_selected_data] = useState([]);
  const handleToggleDisplay = (type) => {setDisplayType(type);};

//Delete Selected Inventory Handler
const handleDeleteSelectedData = async () => 
  {
    //If no selected data, return
    if (selected_data.length === 0) {return;}
    //If display is Item_Log
    if(displayType === "itemLog")
    {
      // Perform deletion logic here
      try {
        // Delete the selected users based on their IDs
         await Promise.all(
          selected_data.map(async (delete_selected_items) => {
            await DataStore.delete(ItemLog, delete_selected_items);
          })
        );
        // After successful deletion, update the userInformation state
        const updatedItemLog = rows.filter(
          (delete_selected_items) => !selected_data.includes(delete_selected_items.id)
        );
        set_itemLogData(updatedItemLog);
        // Clear the selectedUserIds state
        set_selected_data([]);
      }
      catch (error) {}
    }
  
  // if(displayType === "ite")
  // {
  //   // Perform deletion logic here
  //   try {
  //     // Delete the selected users based on their IDs
  //     await Promise.all(
  //       selected_data.map(async (deleteinventoryitem) => {
  //         await DataStore.delete(ImportedProductsShipped, deleteinventoryitem);
  //       })
  //     );
  //     // After successful deletion, update the userInformation state
  //     const updatedIventoryData = rows.filter(
  //       (deleteinventoryitem) => !selected_data.includes(deleteinventoryitem.id)
  //     );
  //     set_selected_data(updatedIventoryData);
  //     // Clear the selectedUserIds state
  //     set_selected_data([]);
  //   }
  //   catch (error) {}
  // }
}

 


    const columnsForItem = [
        {
          field: "id",
          headerName: "id",
          flex: 1,
        },
        {
          field: "serial_number",
          headerName: "Serial Number",
          flex: 1,
        //   renderCell: (params) => (
        //     <a href={`link/to/internal_order/${params.row.internal_order_number}`}>
        //       {params.value}
        //     </a>
        //   ),
        },
        {
          field: "model",
          headerName: "Model",
          flex: 1,
        },
        {
          field: "version",
          headerName: "Version",
          flex: 1,
        },
        {
          field: "stellarcyber_warranty",
          headerName: "Stellar Cyber Warranty",
          flex: 1,
        },
        {
          field: "manufactuer_warranty",
          headerName: "Manufactuer Warranty",
          flex: 1,
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1,
        },
        {
            field: "ship_date",
            headerName: "Ship Date",
            flex: 1,
        },
        {
            field: "received_date",
            headerName: "Received Date",
            flex: 1,
        },
        {
            field: "tracking_number",
            headerName: "Tracking Number",
            flex: 1,
        },
        {
            field: "pool",
            headerName: "Pool",
            flex: 1,
        },
        {
            field: "additional_details",
            headerName: "Additional Details",
            flex: 1,
        },
        {
            field: "internal_order_number",
            headerName: "Internal Order Number",
            flex: 1,
        },
      ];
    const columnsForItemLog = [
        {
          field: "id",
          headerName: "id",
          flex: 1,
        },
        {
          field: "serial_number",
          headerName: "Serial Number",
          flex: 1,
        //   renderCell: (params) => (
        //     <a href={`link/to/internal_order/${params.row.internal_order_number}`}>
        //       {params.value}
        //     </a>
        //   ),
        },
        {
            field: "movement_date",
            headerName: "Movement Date",
            flex: 1,
        },
        {
            field: "tracking_number",
            headerName: "Tracking Number",
            flex: 1,
        },
        {
            field: "from_location",
            headerName: "From",
            flex: 1,
        },
        {
            field: "to_location",
            headerName: "To",
            flex: 1,
        },
        {
            field: "internal_order_number",
            headerName: "Internal Order Number",
            flex: 1,
        },
        {
            field: "additional_details",
            headerName: "Additional Details",
            flex: 1,
        },
        {
            field: "code",
            headerName: "Code",
            flex: 1,
        },
    ];
    let rows;
    switch (displayType) {
        case "item":
        rows = itemData;
        break;
        case "itemLog":
        rows = itemLogData;
        break;
        default:
        rows = itemData;
        break;
    }
    let columns;
    switch (displayType) {
        case "item":
        columns = columnsForItem;
        break;
        case "itemLog":
        columns = columnsForItemLog;
        break;
        default:
        columns = columnsForItem;
        break;
    }


  return (
    <Box m="20px">
      <Box m="20px">
        <Button variant="contained" color="secondary" disabled={displayType === "item"} onClick={() => handleToggleDisplay("item")}>
          Show Item
        </Button>
        <Button variant="contained" color="secondary" disabled={displayType === "itemLog"} onClick={() => handleToggleDisplay("itemLog")}>
          Show Item Log
        </Button>
        {/* <Button variant="contained" color="secondary" disabled={displayType === "shipped_inventory"} onClick={() => handleToggleDisplay("shipped_inventory")}>
          Show Shipped Inventory
        </Button> */}
      </Box>
      <Box m="20px">
        <Button variant="contained" color="secondary" onClick={handleDeleteSelectedData} disabled={selected_data.length === 0}> Delete Selected Items
        </Button>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{ "& .MuiDataGrid-root": { border: "none",}, "& .MuiDataGrid-cell": { borderBottom: "none",}, "& .name-column--cell": { color: colors.greenAccent[1],}, 
        "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[1], borderBottom: "none",}, "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[1],}, 
        "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[1],}, "& .MuiCheckbox-root": { color: `${colors.greenAccent[1]} !important`,}, 
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[1]} !important`,},}}
      >
        <DataGrid 
          checkboxSelection
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: GridToolbar }}
          onRowSelectionModelChange={(newSelection) => {
            set_selected_data(newSelection);
          }}
          selectionModel={selected_data}
        />
      </Box>
    </Box>
  );

    

};
export default ProductDisplay;
