export const inventory = [{"serial_number":"10BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"1CP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"1QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"1Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"21BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"2CP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"2QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"2Y9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"2Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"30BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"31BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"3Y9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"3Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"40BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"41BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"4LDN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"4QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"4Y9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"4Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"51BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"51CD0R3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"5BP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"5CP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"5L8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"5QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"5Y9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"5Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"61BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"61CD0R3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"640B0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"6BP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"6L8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"6QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"71CD0R3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"7QW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"7SHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"7Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"80BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"81CD0R3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"8CP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"8L8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"8SHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"8Y9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"8Z9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"91CD0R3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"9BP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"9SHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"B40B0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"BSHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"C0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"CCP3VH3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"CL8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"CPHN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"CSHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"CZ9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"DL8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"DSHWKQ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"DZ9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"F0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"FZ9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"G0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"GL8H7Y3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"H0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"HZ9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"J0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"JPW90R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-12-01"},
{"serial_number":"JY9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"JZ9DQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2021-12-01"},
{"serial_number":"LR202207026472", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026486", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-04"},
{"serial_number":"LR202211014122", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014131", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014133", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014138", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014139", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014141", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014142", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014143", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014183", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014194", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014322", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014359", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014484", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014486", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014489", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-05-31"},
{"serial_number":"TEMP-150-032", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-033", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-034", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-035", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-036", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-037", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-038", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-039", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-040", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-041", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-042", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-043", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-044", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-045", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-046", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-047", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-048", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-049", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"TEMP-150-050", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202208005", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP249", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2023-09-01"},
{"serial_number":"YL-TMP85", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP86", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP87", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP88", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP89", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP9", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP90", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP91", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP92", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP93", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP94", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP95", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP96", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP97", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP98", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-TMP99", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "recieved_date":"2022-01-01"}]