export const hardwareout = [{"serial_number":" YL-NANO202209003", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-28", "internal_order_number":"RMA-0000641", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"16615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"Converted from POC", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-11", "ship_date":"2023-05-11", "internal_order_number":"SO-0000572", "tracking_number":"1Z9VV3040333084718", "recieved_date":"2022-12-01"},
{"serial_number":"17615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"1NCK1V3", "model":"Photon 300", "version":"4.3.5v2.0", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-11", "ship_date":"2023-04-11", "internal_order_number":"SO-0000547", "tracking_number":"1Z9VV3040318109854", "recieved_date":"2022-12-01"},
{"serial_number":"1QHN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000491", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"1ZFYFT3", "model":"Photon 300", "version":"4.3.4", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-03", "ship_date":"2023-03-03", "internal_order_number":"SO-0000501", "tracking_number":"771460178752", "recieved_date":"2022-12-01"},
{"serial_number":"20BDQJ3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000401", "tracking_number":"1Z8597900362097161", "recieved_date":"2021-12-01"},
{"serial_number":"26615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"2NCK1V3", "model":"Photon 300", "version":"4.3.5", "location":"CCI", "additional_information":"10GBase-SR Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040307781540", "recieved_date":"2022-12-01"},
{"serial_number":"2QHN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"2ZFYFT3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2022-12-01"},
{"serial_number":"36615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000387", "tracking_number":"1Z8597900361088360", "recieved_date":"2022-12-01"},
{"serial_number":"37615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000387", "tracking_number":"1Z8597900361088360", "recieved_date":"2022-12-01"},
{"serial_number":"3F9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"3QW90R3", "model":"Photon 400", "version":"5.0.4", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-27", "ship_date":"2023-09-27", "internal_order_number":"SO-0000737", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"3ZFYFT3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2022-12-01"},
{"serial_number":"440B0R3", "model":"Photon 400", "version":"4.3.6 SDS", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-02", "ship_date":"2023-10-02", "internal_order_number":"SO-0000761", "tracking_number":"9F06886", "recieved_date":"2022-12-01"},
{"serial_number":"45615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-02", "ship_date":"2022-11-01", "internal_order_number":"SO-0000384", "tracking_number":"1Z9VV3040324522574", "recieved_date":"2022-12-01"},
{"serial_number":"4CP3VH3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-09-02", "ship_date":"2022-09-02", "internal_order_number":"SO-0000344", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"4F9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"4ZFYFT3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2022-12-01"},
{"serial_number":"50BDQJ3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2021-12-01"},
{"serial_number":"540B0R3", "model":"Photon 400", "version":"4.3.6 SDS", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-02", "ship_date":"2023-10-02", "internal_order_number":"SO-0000761", "tracking_number":"9F06886", "recieved_date":"2022-12-01"},
{"serial_number":"55615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"56615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"5C9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"5LDN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040314025988", "recieved_date":"2022-12-01"},
{"serial_number":"5XWGQJ3", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-08", "ship_date":"2022-07-08", "internal_order_number":"SO-0000310", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"5ZFYFT3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2022-12-01"},
{"serial_number":"65615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"6XWGQJ3", "model":"M3000", "version":"4.3.5", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-06-11", "ship_date":"2023-04-11", "internal_order_number":"SO-0000530", "tracking_number":"1833538022", "recieved_date":"2021-12-01"},
{"serial_number":"6ZFYFT3", "model":"Photon 300", "version":"4.3.4", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-03", "ship_date":"2023-03-03", "internal_order_number":"SO-0000497", "tracking_number":"2776063065", "recieved_date":"2022-12-01"},
{"serial_number":"740B0R3", "model":"Photon 400", "version":"5.0.4 Ubuntu 22.04", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-24", "ship_date":"2023-10-24", "internal_order_number":"SO-0000763", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"79ML1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-16", "ship_date":"2023-06-16", "internal_order_number":"SO-0000589", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"7BP3VH3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2027-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000333", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"7L8H7Y3", "model":"Photon 300", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000770", "tracking_number":"1Z8597900397282707", "recieved_date":"2023-09-01"},
{"serial_number":"840B0R3", "model":"Photon 400", "version":"4.3.6 SDS", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-02", "ship_date":"2023-10-02", "internal_order_number":"SO-0000761", "tracking_number":"9F06886", "recieved_date":"2022-12-01"},
{"serial_number":"86615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"87615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-02-14", "ship_date":"2022-02-14", "internal_order_number":"SO-0000224", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"89ML1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-16", "ship_date":"2023-06-16", "internal_order_number":"SO-0000589", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"8BP3VH3", "model":"M6000", "version":"4.3.4", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-03-20", "internal_order_number":"POC-0000515", "tracking_number":"1Z8597900396139123", "recieved_date":"2021-12-01"},
{"serial_number":"91BDQJ3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-09-06", "ship_date":"2022-09-06", "internal_order_number":"SO-0000349", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"940B0R3", "model":"Photon 400", "version":"4.3.6 SDS", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-02", "ship_date":"2023-10-02", "internal_order_number":"SO-0000761", "tracking_number":"9F06886", "recieved_date":"2022-12-01"},
{"serial_number":"96615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000387", "tracking_number":"1Z8597900361088360", "recieved_date":"2022-12-01"},
{"serial_number":"9L8H7Y3", "model":"Photon 300", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-12", "ship_date":"2023-09-12", "internal_order_number":"SO-0000723", "tracking_number":"1Z9VV3040212671973", "recieved_date":"2023-09-01"},
{"serial_number":"9XWGQJ3", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2022-01-06", "internal_order_number":"POC-0000566", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"9Z9DQJ3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000401", "tracking_number":"1Z8597900362097161", "recieved_date":"2021-12-01"},
{"serial_number":"B0BDQJ3", "model":"M6000", "version":"", "location":"CCI", "additional_information":" ", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-18", "ship_date":"2023-01-18", "internal_order_number":"SO-0000433", "tracking_number":"6772901376", "recieved_date":"2021-12-01"},
{"serial_number":"B4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"B7615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"BBP3VH3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-18", "ship_date":"2022-07-18", "internal_order_number":"SO-0000324", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"BL8H7Y3", "model":"Photon 300", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-06", "ship_date":"2023-09-06", "internal_order_number":"", "tracking_number":"1Z8597900395971734", "recieved_date":"2023-09-01"},
{"serial_number":"BXWGQJ3", "model":"M3000", "version":"4.3.7", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-11-13", "ship_date":"2023-11-13", "internal_order_number":"SO-0000783", "tracking_number":"1971237111", "recieved_date":"2021-12-01"},
{"serial_number":"C4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2022-02-10", "internal_order_number":"POC-0000568", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"C5615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"C6615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"C7615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2027-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000333", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"CMCK1V3", "model":"Photon 300", "version":"4.3.5v2.0", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-11", "ship_date":"2023-05-11", "internal_order_number":"SO-0000572", "tracking_number":"1Z9VV3040333084718", "recieved_date":"2022-12-01"},
{"serial_number":"CXWGQJ3", "model":"M3000", "version":"4.3.5", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-17", "internal_order_number":"POC-0000708", "tracking_number":"4075971491", "recieved_date":"2021-12-01"},
{"serial_number":"D0BDQJ3", "model":"M6000", "version":"4.3.7", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-07", "ship_date":"2023-11-07", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040313492832", "recieved_date":"2021-12-01"},
{"serial_number":"D3315K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"D4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-01-04", "ship_date":"2022-01-04", "internal_order_number":"SO-0000215", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"D5615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2022-12-08", "internal_order_number":"SO-0000333", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"D7615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-01", "ship_date":"2022-11-01", "internal_order_number":"SO-0000384", "tracking_number":"1Z9VV3040324522574", "recieved_date":"2022-12-01"},
{"serial_number":"DB9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"DBP3VH3", "model":"M6000", "version":"4.3.4", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-23", "ship_date":"2023-03-20", "internal_order_number":"SO-0000509", "tracking_number":"1Z8597900396139123", "recieved_date":"2021-12-01"},
{"serial_number":"DMCK1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"10GBase-LR Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040301025378", "recieved_date":"2022-12-01"},
{"serial_number":"F3315K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"F4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"F5615K3", "model":"Photon 300", "version":"4.3.6 SDS 300C", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-05", "internal_order_number":"RMA-0000660", "tracking_number":"1Z9VV3041335707648", "recieved_date":""},
{"serial_number":"F7615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000387", "tracking_number":"1Z8597900361088360", "recieved_date":"2022-12-01"},
{"serial_number":"FB9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"FBCSZC3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-29", "ship_date":"2022-06-29", "internal_order_number":"SO-0000301", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"FBCTZC3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-14", "ship_date":"2022-06-14", "internal_order_number":"SO-0000292", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"FBCVZC3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000294", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"FBDPZC3", "model":"M6000", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-02-14", "ship_date":"2022-02-14", "internal_order_number":"SO-0000242", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"FBP3VH3", "model":"M6000", "version":"4.3.6", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-03", "internal_order_number":"POC-0000798", "tracking_number":"7902191043", "recieved_date":"2021-12-01"},
{"serial_number":"FCP3VH3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2022-12-08", "internal_order_number":"SO-0000333", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"FL8H7Y3", "model":"Photon 300", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-07", "ship_date":"2023-11-07", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040313492832", "recieved_date":"2023-09-01"},
{"serial_number":"FMCK1V3", "model":"Photon 300", "version":"4.3.5", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-13", "ship_date":"2023-06-13", "internal_order_number":"SO-0000604", "tracking_number":"1Z9VV3040307819590", "recieved_date":"2022-12-01"},
{"serial_number":"FPHN0R3", "model":"Photon 400", "version":"", "location":"CCI", "additional_information":"10GBase-SR Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040303803158", "recieved_date":"2022-12-01"},
{"serial_number":"FYFYFT3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"G4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"G5615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000294", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"G7615K3", "model":"Photon 300", "version":"4.3.5 (4.3.4 Upgrade)", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-08", "internal_order_number":"RMA-0000603", "tracking_number":"1Z9VV3040334629968", "recieved_date":"2022-12-01"},
{"serial_number":"GB9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"GBP3VH3", "model":"M6000", "version":"4.3.7", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-07", "ship_date":"2023-11-07", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040313492832", "recieved_date":"2021-12-01"},
{"serial_number":"GJDLQJ3", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-01-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000385", "tracking_number":"1Z8597900360701411", "recieved_date":"2021-12-01"},
{"serial_number":"GMCK1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-16", "ship_date":"2023-06-16", "internal_order_number":"SO-0000589", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"GPHN0R3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-08-11", "ship_date":"2022-08-11", "internal_order_number":"SO-0000313", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"GYFYFT3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000491", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"H416KD3", "model":"M6000", "version":"", "location":"", "additional_information":"1", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000279", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"H41PZC3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-02-14", "ship_date":"2022-02-14", "internal_order_number":"SO-0000224", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"H4615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-19", "ship_date":"2022-10-19", "internal_order_number":"SO-0000358", "tracking_number":"4234646264", "recieved_date":"2022-12-01"},
{"serial_number":"H6615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2022-11-02", "internal_order_number":"SO-0000387", "tracking_number":"1Z8597900361088360", "recieved_date":"2022-12-01"},
{"serial_number":"HB9HNK3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-28", "ship_date":"2022-06-28", "internal_order_number":"SO-0000302", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"HBP3VH3", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-29", "ship_date":"2022-06-29", "internal_order_number":"SO-0000304", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"HMCK1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"10GBase-LR Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040307917760", "recieved_date":"2022-12-01"},
{"serial_number":"HPHN0R3", "model":"Photon 400", "version":"5.0.4 Ubuntu 22.04", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-24", "ship_date":"2023-10-24", "internal_order_number":"SO-0000766", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"HXWGQJ3", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-15", "ship_date":"2022-12-15", "internal_order_number":"SO-0000353", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"HYFYFT3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-29", "ship_date":"2022-12-29", "internal_order_number":"SO-0000424", "tracking_number":"770916221172", "recieved_date":"2022-12-01"},
{"serial_number":"J6615K3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"JJDLQJ3", "model":"M3000", "version":"4.3.5", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-04-25", "ship_date":"2023-04-25", "internal_order_number":"SO-0000553", "tracking_number":"3834869732", "recieved_date":"2021-12-01"},
{"serial_number":"JMCK1V3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"10GBase-SR Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040323484000", "recieved_date":"2022-12-01"},
{"serial_number":"JPHN0R3", "model":"Photon 400", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-08-11", "ship_date":"2022-08-11", "internal_order_number":"SO-0000313", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"JYFYFT3", "model":"Photon 300", "version":"", "location":"CCI", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-16", "ship_date":"2023-06-16", "internal_order_number":"SO-0000589", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"LR201912016759", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-08", "ship_date":"2021-03-08", "internal_order_number":"SO-0000083", "tracking_number":"", "recieved_date":"2020-01-01"},
{"serial_number":"LR202001000114", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-06", "ship_date":"2021-01-06", "internal_order_number":"SO-0000076", "tracking_number":"", "recieved_date":"2020-01-01"},
{"serial_number":"LR202002005367", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-23", "ship_date":"2020-12-23", "internal_order_number":"SO-0000068", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LR202002005384", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-08", "ship_date":"2022-07-08", "internal_order_number":"SO-0000310", "tracking_number":"", "recieved_date":"2020-01-01"},
{"serial_number":"LR202002020188", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-02", "ship_date":"2020-10-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LR202002020212", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-23", "ship_date":"2020-12-23", "internal_order_number":"SO-0000068", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"LR202003004224", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000086", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LR202004012943", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-31", "ship_date":"2020-12-31", "internal_order_number":"SO-0000059", "tracking_number":"", "recieved_date":"2020-01-01"},
{"serial_number":"LR202004022813", "model":"M3000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-27", "ship_date":"2020-12-27", "internal_order_number":"SO-0000069", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LR202005008416", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-23", "ship_date":"2020-12-23", "internal_order_number":"SO-0000068", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"LR202005008426", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-23", "ship_date":"2020-12-23", "internal_order_number":"SO-0000068", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"LR202010016399", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202010016405", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202011008878", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011008880", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011008882", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011008888", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008891", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008892", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008893", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008894", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008896", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008897", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008898", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008900", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-05-24", "ship_date":"2022-05-24", "internal_order_number":"SO-0000245", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008909", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008910", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008912", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008916", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-29", "ship_date":"2022-06-29", "internal_order_number":"SO-0000304", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008918", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008920", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000308", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008922", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008923", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008929", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008930", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008931", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008932", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008934", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008935", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008937", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008938", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008939", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008940", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008943", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-20", "ship_date":"2022-07-20", "internal_order_number":"SO-0000323", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008949", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008956", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008957", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008959", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008960", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-05-20"},
{"serial_number":"LR202011008969", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008974", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008977", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008979", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008981", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202011008984", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000277", "tracking_number":"2111414616", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008986", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008988", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011008992", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017610", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017612", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017620", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017622", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017623", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017624", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017625", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017628", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017629", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017634", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017637", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017638", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017639", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017640", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017641", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-03", "ship_date":"2022-10-03", "internal_order_number":"SO-0000365", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017645", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017648", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017651", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017655", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017656", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2022-01-20", "internal_order_number":"POC-0000567", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017658", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-03-15", "ship_date":"2022-03-15", "internal_order_number":"SO-0000246", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017659", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-14", "ship_date":"2022-06-14", "internal_order_number":"SO-0000292", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017664", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017665", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017666", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017668", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202011017669", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-21", "internal_order_number":"RMA-0000713", "tracking_number":"1Z9VV3040305958658", "recieved_date":""},
{"serial_number":"LR202011017672", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-08-06"},
{"serial_number":"LR202011017673", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202012003054", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202012003056", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-13", "ship_date":"2022-06-13", "internal_order_number":"SO-0000285", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202012003062", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-05-24", "ship_date":"2022-05-24", "internal_order_number":"SO-0000245", "tracking_number":"", "recieved_date":"2021-07-23"},
{"serial_number":"LR202111008334", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-02-14", "ship_date":"2022-02-14", "internal_order_number":"SO-0000224", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202111008354", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-01-16", "ship_date":"2022-01-16", "internal_order_number":"SO-0000243", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202111008389", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-01-31", "ship_date":"2022-01-31", "internal_order_number":"SO-0000238", "tracking_number":"", "recieved_date":"2021-12-11"},
{"serial_number":"LR202201003255", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003267", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-21", "ship_date":"2022-10-21", "internal_order_number":"SO-0000381", "tracking_number":"1Z9VV3040303984132", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003278", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-20", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003286", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-20", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003293", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003298", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-06", "ship_date":"2022-09-06", "internal_order_number":"SO-0000348", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003304", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-15", "ship_date":"2022-12-15", "internal_order_number":"SO-0000353", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003305", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003325", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-08-25", "ship_date":"2022-08-25", "internal_order_number":"SO-0000340", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003360", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-11", "ship_date":"2022-11-11", "internal_order_number":"SO-0000390", "tracking_number":"1Z9VV3040337199623", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003369", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-09", "ship_date":"2022-08-09", "internal_order_number":"SO-0000335", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003396", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-09", "ship_date":"2022-08-09", "internal_order_number":"SO-0000334", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003407", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-12", "ship_date":"2022-09-12", "internal_order_number":"SO-0000352", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003411", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003414", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003417", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-09-02", "ship_date":"2022-09-02", "internal_order_number":"SO-0000345", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003422", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003431", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003450", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-12", "ship_date":"2022-09-12", "internal_order_number":"SO-0000352", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003453", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003472", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003476", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-03", "ship_date":"2022-10-03", "internal_order_number":"SO-0000365", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202201003478", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"1Z9VV3040318774813", "recieved_date":"2022-07-21"},
{"serial_number":"LR202204019051", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2022-07-21"},
{"serial_number":"LR202207025512", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-23", "ship_date":"2022-11-23", "internal_order_number":"SO-0000398", "tracking_number":"9452508424", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025565", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-06", "ship_date":"2022-12-06", "internal_order_number":"SO-0000403", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025615", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-22", "ship_date":"2022-12-22", "internal_order_number":"SO-0000418", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025710", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-11", "ship_date":"2022-11-11", "internal_order_number":"SO-0000392", "tracking_number":"1Z9VV3040328958838", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025715", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-23", "ship_date":"2022-11-23", "internal_order_number":"SO-0000398", "tracking_number":"9452508424", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025738", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-16", "ship_date":"2022-12-16", "internal_order_number":"SO-0000412", "tracking_number":"2625001960", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025746", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-15", "ship_date":"2022-12-15", "internal_order_number":"SO-0000353", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025775", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"1Z9VV3040318774813", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025791", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-15", "ship_date":"2022-12-15", "internal_order_number":"SO-0000353", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025841", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-15", "ship_date":"2022-12-15", "internal_order_number":"SO-0000353", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025868", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-09", "ship_date":"2022-11-09", "internal_order_number":"SO-0000372", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025873", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-22", "ship_date":"2022-12-22", "internal_order_number":"SO-0000417", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025874", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-01-24", "internal_order_number":"SO-0000471", "tracking_number":"9032363023", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025877", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-01-24", "internal_order_number":"SO-0000471", "tracking_number":"9032363023", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025881", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-09", "ship_date":"2022-11-09", "internal_order_number":"SO-0000372", "tracking_number":"", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025903", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-16", "ship_date":"2022-12-16", "internal_order_number":"SO-0000412", "tracking_number":"2625001960", "recieved_date":"2022-09-09"},
{"serial_number":"LR202207025945", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-22", "ship_date":"2023-02-22", "internal_order_number":"SO-0000473", "tracking_number":"26 5713 9446", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207025953", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-06", "ship_date":"2023-04-06", "internal_order_number":"SO-0000545", "tracking_number":"1Z9VV3040339818512", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207025955", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-23", "internal_order_number":"POC-0000489", "tracking_number":"1Z9VV3040201478757", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207025959", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-01", "ship_date":"2023-06-01", "internal_order_number":"SO-0000587", "tracking_number":"1Z9VV3040318281837", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207025988", "model":"Photon 250", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-26", "ship_date":"2023-09-26", "internal_order_number":"SO-0000746", "tracking_number":"773534056525", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026001", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseLX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040316625102", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026006", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseSX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040334044590", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026012", "model":"Photon 250", "version":"4.3.6 SDS Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-18", "internal_order_number":"RMA-0000819", "tracking_number":"8616477332", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026016", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000480", "tracking_number":"1Z9VV3040313252921", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026021", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-21", "ship_date":"2023-03-21", "internal_order_number":"SO-0000513", "tracking_number":"1Z9VV3040302381802", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026037", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-02", "internal_order_number":"SO-0000471", "tracking_number":"3091206705", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026038", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026040", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000844", "tracking_number":"6994435081", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026045", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000844", "tracking_number":"6994435081", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026047", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-07", "ship_date":"2023-09-07", "internal_order_number":"SO-0000728", "tracking_number":" 3279209555", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026051", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000844", "tracking_number":"6994435081", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026052", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-22", "internal_order_number":"SO-0000470", "tracking_number":"1Z9VV3040332898841", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026053", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-21", "internal_order_number":"RMA-0000713", "tracking_number":"1Z9VV3040305958658", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026057", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026060", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseSX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040306417329", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026061", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-07", "ship_date":"2023-11-07", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040313492832", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026064", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-03", "internal_order_number":"POC-0000798", "tracking_number":"7902191043", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026066", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026068", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026070", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-06", "ship_date":"2023-06-06", "internal_order_number":"SO-0000593", "tracking_number":"1Z9VV3040335226909", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026071", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseSX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040309274719", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026098", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026120", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-29", "ship_date":"2023-08-29", "internal_order_number":"SO-0000719", "tracking_number":"1Z9VV3040330669502", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026128", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-21", "ship_date":"2023-03-21", "internal_order_number":"SO-0000513", "tracking_number":"1Z9VV3040302381802", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026138", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-17", "internal_order_number":"POC-0000708", "tracking_number":"4075971491", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026139", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026141", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026143", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-09", "internal_order_number":"POC-0000477", "tracking_number":"1Z9VV3040231084081", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026160", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseSX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040308952934", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026165", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-06", "ship_date":"2023-07-06", "internal_order_number":"SO-0000640", "tracking_number":"2701530123", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026166", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000479", "tracking_number":"1Z9VV3040313830314", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026170", "model":"Photon 250", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-06", "ship_date":"2023-07-06", "internal_order_number":"SO-0000636", "tracking_number":"1Z9VV3040136004883", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026175", "model":"Photon 250", "version":"4.3.5", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-23", "ship_date":"2023-05-23", "internal_order_number":"SO-0000579", "tracking_number":"1Z9VV3040309244000", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026176", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-04-19", "ship_date":"2023-04-19", "internal_order_number":"SO-0000550", "tracking_number":"1Z9VV3040328815607", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026178", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-02", "internal_order_number":"SO-0000470", "tracking_number":"1Z9VV3040332898841", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026180", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1000BaseSX Included", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-02", "ship_date":"2023-06-13", "internal_order_number":"SO-0000606", "tracking_number":"1Z9VV3040323379786", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026185", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-21", "ship_date":"2023-03-21", "internal_order_number":"SO-0000513", "tracking_number":"1Z9VV3040302381802", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026197", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000479", "tracking_number":"1Z9VV3040313830314", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026199", "model":"Photon 250", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-20", "ship_date":"2023-07-20", "internal_order_number":"SO-0000675", "tracking_number":"1Z9VV3040321929266", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026353", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-06", "ship_date":"2023-04-06", "internal_order_number":"SO-0000545", "tracking_number":"1Z9VV3040339818512", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026354", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-02", "internal_order_number":"SO-0000470", "tracking_number":"1Z9VV3040332898841", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026355", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-13", "ship_date":"2023-03-13", "internal_order_number":"SO-0000502", "tracking_number":"2652622560", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026357", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-01", "ship_date":"2023-06-01", "internal_order_number":"SO-0000591", "tracking_number":"1Z9VV3040309272051", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026358", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-03", "internal_order_number":"RMA-0000002", "tracking_number":"1Z9VV3040309564094", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026405", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-16", "internal_order_number":"RMA-0000484", "tracking_number":"3232594886", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026421", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-02", "internal_order_number":"SO-0000470", "tracking_number":"1Z9VV3040332898841", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026424", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-16", "internal_order_number":"POC-0000485", "tracking_number":"1361893750", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026427", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-02", "internal_order_number":"SO-0000471", "tracking_number":"3091206705", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026428", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-21", "ship_date":"2023-03-21", "internal_order_number":"SO-0000513", "tracking_number":"1Z9VV3040302381802", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026431", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026443", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-02", "internal_order_number":"SO-0000471", "tracking_number":"3091206705", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026444", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-20", "ship_date":"2023-03-20", "internal_order_number":"SO-0000513", "tracking_number":"1Z9VV3041330956021", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026452", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-02", "internal_order_number":"SO-0000471", "tracking_number":"3091206705", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026454", "model":"Photon 250", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-01", "ship_date":"2023-06-01", "internal_order_number":"SO-0000587", "tracking_number":"1Z9VV3040318281837", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026460", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-02", "internal_order_number":"SO-0000470", "tracking_number":"1Z9VV3040332898841", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026464", "model":"Photon 250", "version":"5.0.4 Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-25", "ship_date":"2023-10-25", "internal_order_number":"SO-0000825", "tracking_number":"1Z9VV3040311394782", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026465", "model":"Photon 250", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000773", "tracking_number":"773619399882", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026476", "model":"Photon 250", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-02", "internal_order_number":"SO-0000468", "tracking_number":"1Z9VV3040331729034", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026480", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-18", "ship_date":"2023-04-18", "internal_order_number":"SO-0000551", "tracking_number":"1Z9VV3040313462687", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026484", "model":"Photon 250", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000479", "tracking_number":"1Z9VV3040313830314", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026488", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000769", "tracking_number":"1Z9VV3040305920858", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026494", "model":"Photon 250", "version":"4.3.5", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-06", "ship_date":"2023-04-06", "internal_order_number":"SO-0000544", "tracking_number":"1Z9VV3040329834335", "recieved_date":"2023-01-26"},
{"serial_number":"LR202207026497", "model":"Photon 250", "version":"4.3.6 SDS Ubuntu 16.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-18", "internal_order_number":"RMA-0000819", "tracking_number":"8616477332", "recieved_date":"2023-05-04"},
{"serial_number":"LR202207026502", "model":"Photon 250", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-18", "ship_date":"2023-04-18", "internal_order_number":"SO-0000551", "tracking_number":"1Z9VV3040313462687", "recieved_date":"2023-01-26"},
{"serial_number":"LR202211014120", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-11", "ship_date":"2023-09-11", "internal_order_number":"SO-0000732", "tracking_number":"1Z9VV3040337030385", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014132", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-29", "ship_date":"2023-08-29", "internal_order_number":"SO-0000719", "tracking_number":"1Z9VV3040330669502", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014134", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-04", "ship_date":"2023-08-04", "internal_order_number":"SO-0000696", "tracking_number":" 1619472503", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014136", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000844", "tracking_number":"6994435081", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014140", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-11", "ship_date":"2023-08-11", "internal_order_number":"SO-0000701", "tracking_number":"4850727464", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014149", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-02", "ship_date":"2023-08-02", "internal_order_number":"SO-0000692", "tracking_number":"3425684080", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014182", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000845", "tracking_number":"1Z9VV3040317163930", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014184", "model":"Photon 250", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-22", "ship_date":"2023-09-22", "internal_order_number":"SO-0000752", "tracking_number":"1Z9VV3040126742658", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014228", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-12", "ship_date":"2023-12-12", "internal_order_number":"SO-0000876", "tracking_number":"1Z9VV3040335665739", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014233", "model":"Photon 250", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-05", "internal_order_number":"POC-0000861", "tracking_number":"1Z9VV3040316232796", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014319", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-14", "ship_date":"2023-11-14", "internal_order_number":"SO-0000844", "tracking_number":"6994435081", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014383", "model":"Photon 250", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014491", "model":"Photon 250", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-25", "internal_order_number":"POC-0000762", "tracking_number":"4344205412", "recieved_date":"2023-05-31"},
{"serial_number":"LR202211014510", "model":"Photon 250", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-02", "ship_date":"2023-08-02", "internal_order_number":"SO-0000692", "tracking_number":"3425684080", "recieved_date":"2023-05-31"},
{"serial_number":"LRuk1", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-02", "ship_date":"2020-10-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LRuk2", "model":"M300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"SO-0000063", "tracking_number":"", "recieved_date":""},
{"serial_number":"LRuk3", "model":"Photon 300", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000066", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"LRuk4", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000066", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"LRuk5", "model":"M6000", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-26", "ship_date":"2021-01-26", "internal_order_number":"SO-0000079", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"OHDOK", "model":"M6000", "version":"2.0", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"0000-00-00", "internal_order_number":"SO-0000404", "tracking_number":"", "recieved_date":"2021-12-01"},
{"serial_number":"YL-NAN0202310023", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO1502023001", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-02", "internal_order_number":"RMA-0000811", "tracking_number":"9401439913", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202011030", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-12", "internal_order_number":"RMA-0000735", "tracking_number":"7018808790", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202110009", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110010", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110021", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110023", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-05", "internal_order_number":"POC-0000724", "tracking_number":"1Z9VV3040135236125", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202110024", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-12", "ship_date":"2022-12-12", "internal_order_number":"SO-0000410", "tracking_number":"1Z9VV3040314426330", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110025", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-10", "internal_order_number":"RMA-0000663", "tracking_number":"1Z9VV3041324264932", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202110027", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-12", "ship_date":"2022-09-12", "internal_order_number":"SO-0000351", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110028", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-12", "ship_date":"2022-09-12", "internal_order_number":"SO-0000351", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110029", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-12", "ship_date":"2022-09-12", "internal_order_number":"SO-0000350", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110036", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110037", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110038", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO20211004", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-25", "internal_order_number":"RMA-0000583", "tracking_number":"1Z9VV3040106463612", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110045", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110071", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-12", "ship_date":"2022-07-12", "internal_order_number":"SO-0000322", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110072", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110073", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110074", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-22", "ship_date":"2022-07-22", "internal_order_number":"SO-0000326", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110075", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110076", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-17", "ship_date":"2022-06-17", "internal_order_number":"SO-0000296", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110077", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110078", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-17", "ship_date":"2022-06-17", "internal_order_number":"SO-0000296", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110079", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110080", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-27", "ship_date":"2022-06-27", "internal_order_number":"SO-0000303", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110091", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000287", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110092", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-23", "ship_date":"2022-09-23", "internal_order_number":"SO-0000357", "tracking_number":"1Z9VV3040316806765", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110093", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110094", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110095", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110096", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110097", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110098", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110099", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110100", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-27", "ship_date":"2022-05-27", "internal_order_number":"SO-0000280", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110101", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-16", "ship_date":"2022-08-16", "internal_order_number":"SO-0000337", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110102", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110103", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110104", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-28", "ship_date":"2022-07-28", "internal_order_number":"SO-0000327", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110105", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-07-22", "ship_date":"2022-07-22", "internal_order_number":"SO-0000326", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110106", "model":"Photon 100", "version":"4.3.5 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"RMA-0000726", "tracking_number":"1Z9VV3040131085331", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202110107", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110108", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-30", "ship_date":"2022-08-30", "internal_order_number":"SO-0000341", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110109", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-28", "ship_date":"2022-07-28", "internal_order_number":"SO-0000329", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110112", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110114", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-09-23", "ship_date":"2022-09-23", "internal_order_number":"SO-0000356", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110115", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000288", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110116", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000288", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110117", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000288", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110118", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000288", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110119", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-09-23", "ship_date":"2022-09-23", "internal_order_number":"SO-0000359", "tracking_number":"1Z9VV3040327363473", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110141", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110142", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110143", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110144", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110145", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110146", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110147", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110148", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110149", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110150", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-05-17", "ship_date":"2022-05-17", "internal_order_number":"SO-0000281", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110183", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-02", "ship_date":"2022-06-02", "internal_order_number":"SO-0000288", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110186", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110211", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-10", "internal_order_number":"RMA-0000478", "tracking_number":"1Z9Y39954100480030", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110212", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110216", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110217", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000293", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110221", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-06-17", "ship_date":"2022-06-17", "internal_order_number":"SO-0000296", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110229", "model":"Photon 100", "version":"4.3.5", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-15", "ship_date":"2023-03-15", "internal_order_number":"SO-0000506", "tracking_number":"1Z9VV3040302615194", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110231", "model":"Photon 100", "version":"4.3.5", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-15", "ship_date":"2023-03-15", "internal_order_number":"SO-0000508", "tracking_number":"1Z9VV3040331557998", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110232", "model":"Photon 100", "version":"4.3.5", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-15", "ship_date":"2023-03-15", "internal_order_number":"SO-0000507", "tracking_number":"1Z9VV3040335758979", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110233", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-03-27", "internal_order_number":"RMA-0000525", "tracking_number":"1Z9VV3041302594028", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110234", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-15", "ship_date":"2023-03-15", "internal_order_number":"SO-0000508", "tracking_number":"1Z9VV3040331557998", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110235", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-03-15", "ship_date":"2023-03-15", "internal_order_number":"SO-0000507", "tracking_number":"1Z9VV3040335758979", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110236", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110238", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110239", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110240", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-07-06", "ship_date":"2022-07-06", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110241", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"SO-0000311", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110251", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110252", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110253", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110254", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110255", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110256", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110257", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110258", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110259", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110260", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110271", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110272", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110273", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110274", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110275", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110276", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110277", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110278", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110279", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110280", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-06-24", "ship_date":"2022-06-24", "internal_order_number":"SO-0000295", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110282", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110284", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110286", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110287", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110288", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110289", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110290", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-12", "ship_date":"2022-08-12", "internal_order_number":"SO-0000336", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110291", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110293", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110294", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110296", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110297", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110298", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110299", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202110300", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-08-23", "ship_date":"2022-08-23", "internal_order_number":"SO-0000315", "tracking_number":"", "recieved_date":"2021-01-01"},
{"serial_number":"YL-NANO202200001", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-01", "ship_date":"2022-11-01", "internal_order_number":"SO-0000388", "tracking_number":"1Z9VV3040329428784", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202200002", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-11-27", "ship_date":"2022-11-27", "internal_order_number":"SO-0000383", "tracking_number":"1Z9VV3040303372749", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202200006", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-11", "ship_date":"2022-11-11", "internal_order_number":"SO-0000393", "tracking_number":"1Z9VV3040314897019", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202200007", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-11-27", "ship_date":"2022-11-27", "internal_order_number":"SO-0000383", "tracking_number":"1Z9VV3040303372749", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202200008", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-11-27", "ship_date":"2022-11-27", "internal_order_number":"SO-0000383", "tracking_number":"1Z9VV3040303372749", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202200295", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-11-27", "ship_date":"2022-11-27", "internal_order_number":"SO-0000383", "tracking_number":"1Z9VV3040303372749", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208001", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-01", "ship_date":"2023-06-01", "internal_order_number":"SO-0000588", "tracking_number":"1Z9VV3040328313499", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208002", "model":"Photon 100", "version":"4.3.5v2", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-08", "ship_date":"2023-06-08", "internal_order_number":"SO-0000600", "tracking_number":"1Z9VV3040317568495", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208003", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-06", "internal_order_number":"RMA-0000599", "tracking_number":"1Z9VV3041324990915", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208004", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-16", "internal_order_number":"RMA-0000612", "tracking_number":"1Z9VV3041312958429", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208006", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-27", "ship_date":"2023-06-27", "internal_order_number":"SO-0000625", "tracking_number":"1Z9VV3040310765356", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208008", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-30", "ship_date":"2023-05-30", "internal_order_number":"SO-0000586", "tracking_number":"1Z9VV3040329516867", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208009", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-06", "internal_order_number":"RMA-0000598", "tracking_number":"1Z9VV3041306634272", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208010", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-30", "ship_date":"2023-05-30", "internal_order_number":"SO-0000586", "tracking_number":"1Z9VV3040329516867", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208011", "model":"Photon 100", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-20", "ship_date":"2023-07-20", "internal_order_number":"SO-0000682", "tracking_number":"1Z9VV3040335250874", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208012", "model":"Photon 100", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-27", "internal_order_number":"RMA-0000690", "tracking_number":"1Z9VV3040134117925", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208013", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000661", "tracking_number":"7807376360", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208014", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-01", "internal_order_number":"RMA-0000693", "tracking_number":"1Z9VV3040132049977", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208015", "model":"Photon 100", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-18", "ship_date":"2023-07-18", "internal_order_number":"SO-0000673", "tracking_number":"1Z9VV3040337180035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208016", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-25", "ship_date":"2023-07-25", "internal_order_number":"SO-0000680", "tracking_number":"7076560492", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208018", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000661", "tracking_number":"7807376360", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208019", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000661", "tracking_number":"7807376360", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208020", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000661", "tracking_number":"7807376360", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208021", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208022", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208023", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208024", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208025", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208026", "model":"Photon 100", "version":"4.34", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208027", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208028", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208029", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208030", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-10", "ship_date":"2023-02-10", "internal_order_number":"SO-0000481", "tracking_number":"1Z9VV3040326586896", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208031", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208032", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-05-09", "ship_date":"2023-05-09", "internal_order_number":"SO-0000571", "tracking_number":"1Z9VV3040323892702", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208033", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208034", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208035", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208036", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208037", "model":"Photon 100", "version":"5.0.0", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-25", "ship_date":"2023-04-25", "internal_order_number":"SO-0000558", "tracking_number":"1Z9VV3040327971655", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208038", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208039", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208040", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-26", "ship_date":"2023-04-26", "internal_order_number":"SO-0000561", "tracking_number":"1Z9VV3040314040952", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208041", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208042", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208043", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208044", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208045", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208046", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208047", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208048", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208049", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208050", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-28", "ship_date":"2023-08-28", "internal_order_number":"SO-0000717", "tracking_number":"1Z9VV3040208575095", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208051", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-10", "ship_date":"2023-03-10", "internal_order_number":"SO-0000504", "tracking_number":"1Z9VV3040327010353", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208052", "model":"Photon 100", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-10", "ship_date":"2023-03-10", "internal_order_number":"SO-0000505", "tracking_number":"771538176603", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208053", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-23", "ship_date":"2023-02-23", "internal_order_number":"SO-0000492", "tracking_number":"1Z9VV3040324126921", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208054", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-23", "ship_date":"2023-02-23", "internal_order_number":"SO-0000488", "tracking_number":"1Z9VV3040333944306", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208056", "model":"Photon 100", "version":"4.3.5v1.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-30", "ship_date":"2023-03-30", "internal_order_number":"SO-0000526", "tracking_number":"1Z9VV3040334218072", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208057", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-02", "ship_date":"2023-03-02", "internal_order_number":"SO-0000499", "tracking_number":"1Z9VV3040324639949", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208058", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-03-10", "ship_date":"2023-03-10", "internal_order_number":"SO-0000504", "tracking_number":"1Z9VV3040327010353", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208059", "model":"Photon 100", "version":"4.3.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-23", "ship_date":"2023-02-23", "internal_order_number":"SO-0000492", "tracking_number":"1Z9VV3040324126921", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208060", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-01", "internal_order_number":"RMA-0000592", "tracking_number":"1Z9VV3041302956664", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208061", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000427", "tracking_number":"2078149043", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208062", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000426", "tracking_number":"2078151950", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208063", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-05", "internal_order_number":"RMA-0000725", "tracking_number":"1Z9VV3040127413145", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208065", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000429", "tracking_number":"2078147853", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208066", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-20", "ship_date":"2022-12-20", "internal_order_number":"SO-0000416", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208067", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-20", "ship_date":"2022-12-20", "internal_order_number":"SO-0000416", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208068", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-20", "ship_date":"2022-12-20", "internal_order_number":"SO-0000416", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208069", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000430", "tracking_number":"1Z9VV3040312719607", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208070", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-20", "ship_date":"2022-12-20", "internal_order_number":"SO-0000416", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208071", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-15", "internal_order_number":"RMA-0000574", "tracking_number":"1Z9VV3041329003320", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208072", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-16", "internal_order_number":"RMA-0000575", "tracking_number":"1Z9VV3041321078756", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208073", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-07", "internal_order_number":"RMA-0000602", "tracking_number":"1Z9VV3041339001527", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208074", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-15", "internal_order_number":"RMA-0000574", "tracking_number":"1Z9VV3041329003320", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208075", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-23", "ship_date":"2023-05-23", "internal_order_number":"SO-0000577", "tracking_number":"1Z9VV3040320872426", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208076", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-15", "internal_order_number":"RMA-0000574", "tracking_number":"1Z9VV3041329003320", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208077", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"1Z9VV3040334029802", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-19", "ship_date":"2023-05-19", "internal_order_number":"SO-0000576", "tracking_number":"1Z9VV3040334029802", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208078", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-19", "ship_date":"2023-05-19", "internal_order_number":"SO-0000576", "tracking_number":"1Z9VV3040334029802", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208079", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-23", "ship_date":"2023-05-23", "internal_order_number":"SO-0000578", "tracking_number":"1Z9VV3040336679633", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208080", "model":"Photon 100", "version":"4.3.5 MDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-23", "internal_order_number":"RMA-0000624", "tracking_number":"1Z9VV3041308799529", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208084", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-08-11", "ship_date":"2023-08-11", "internal_order_number":"SO-0000695", "tracking_number":"7874588842", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208085", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-08-11", "ship_date":"2023-08-11", "internal_order_number":"SO-0000700", "tracking_number":"1Z9VV3040324590232", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208086", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-14", "internal_order_number":"RMA-0000704", "tracking_number":"1Z9VV3040111808436", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208087", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-11", "internal_order_number":"RMA-0000703", "tracking_number":"1Z9VV3040134152048", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208088", "model":"Photon 100", "version":"4.3.5 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-28", "internal_order_number":"RMA-0000720", "tracking_number":"1Z9VV3040133109070", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208089", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-17", "internal_order_number":"RMA-0000672", "tracking_number":"1718839135", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208090", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-18", "internal_order_number":"RMA-0000710", "tracking_number":"6694545233", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208091", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208092", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208093", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208094", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208095", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208096", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208097", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208098", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208099", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208100", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-10-05", "ship_date":"2022-10-05", "internal_order_number":"SO-0000373", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208101", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208102", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208103", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208104", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208105", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208106", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-19", "internal_order_number":"RMA-0000677", "tracking_number":"1Z9VV3040136009842", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208107", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208108", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208109", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-02", "ship_date":"2023-05-02", "internal_order_number":"SO-0000565", "tracking_number":"1Z9VV3040307295567", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208110", "model":"Photon 100", "version":"5.0.0", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-09", "ship_date":"2023-05-09", "internal_order_number":"SO-0000569", "tracking_number":"1Z9VV3040332664487", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208111", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-30", "ship_date":"2022-11-30", "internal_order_number":"SO-0000405", "tracking_number":"1Z9VV3040321941671", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208112", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-23", "ship_date":"2022-11-23", "internal_order_number":"SO-0000397", "tracking_number":"1Z9VV3040319555236", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208113", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-23", "ship_date":"2022-11-23", "internal_order_number":"SO-0000397", "tracking_number":"1Z9VV3040319555236", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208114", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-14", "internal_order_number":"RMA-0000609", "tracking_number":"1Z9VV3041313306201", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208115", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-12-07", "ship_date":"2022-12-07", "internal_order_number":"SO-0000409", "tracking_number":"1Z9VV3040311838507", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208116", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-11-30", "ship_date":"2022-11-30", "internal_order_number":"SO-0000402", "tracking_number":"1Z9VV3040305587675", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208118", "model":"Photon 100", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-03", "internal_order_number":"RMA-0000001", "tracking_number":"2334979474", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208119", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2023-11-30", "ship_date":"2022-11-30", "internal_order_number":"SO-0000405", "tracking_number":"1Z9VV3040321941671", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208121", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000771", "tracking_number":"2488651082", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208123", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000771", "tracking_number":"2488651082", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208124", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000772", "tracking_number":"1Z9VV3040332007277", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208125", "model":"Photon 100", "version":"5.0.4 Ubuntu 16.04", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-30", "internal_order_number":"POC-0000852", "tracking_number":"1Z9VV3040302406133", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208126", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000767", "tracking_number":"1Z9VV3040104050633", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208127", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000771", "tracking_number":"2488651082", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208128", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000771", "tracking_number":"2488651082", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208130", "model":"Photon 100", "version":"5.0.4 Ubuntu 16.04", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-30", "internal_order_number":"POC-0000853", "tracking_number":"1Z9VV3040318710524", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208131", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-31", "ship_date":"2023-07-28", "internal_order_number":"SO-0000691", "tracking_number":"1Z9VV3044433981350", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208132", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-31", "ship_date":"2023-07-28", "internal_order_number":"SO-0000691", "tracking_number":"1Z9VV3044433981350", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208133", "model":"Photon 100", "version":"4.3.5 v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"RMA-0000667", "tracking_number":"1Z9VV3040119149509", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208134", "model":"Photon 100", "version":"4.3.5 v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"RMA-0000667", "tracking_number":"1Z9VV3040119149509", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208135", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000669", "tracking_number":"1Z9VV3040336489982", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208136", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"INT-0000665", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208137", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-10", "internal_order_number":"RMA-0000662", "tracking_number":"1Z9VV3041338039721", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208138", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000668", "tracking_number":"5242947636", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208139", "model":"Photon 100", "version":"5.0.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-14", "internal_order_number":"POC-0000741", "tracking_number":"1Z9VV3040137286603", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208140", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-13", "ship_date":"2023-07-13", "internal_order_number":"SO-0000661", "tracking_number":"7807376360", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208141", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208142", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208143", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208144", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208145", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208146", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208147", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208148", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208149", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208150", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000617", "tracking_number":"1Z9VV3040318924035", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208151", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000615", "tracking_number":"1Z9VV3040300182647", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208152", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000615", "tracking_number":"1Z9VV3040300182647", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208153", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"INT-0000665", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208154", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-18", "ship_date":"2023-06-20", "internal_order_number":"SO-0000614", "tracking_number":"1Z9VV3040317370699", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208155", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-06-27", "ship_date":"2023-06-27", "internal_order_number":"SO-0000623", "tracking_number":"1Z9VV3040301883745", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208156", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"Asset Tag : AG1003675\r\nMAC:00:e0:97:1c:5c:20", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000613", "tracking_number":"1Z9VV3040319287302", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208157", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000615", "tracking_number":"1Z9VV3040300182647", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208158", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000615", "tracking_number":"1Z9VV3040300182647", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208159", "model":"Photon 100", "version":"4.2.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"INT-0000665", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208160", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-20", "ship_date":"2023-06-20", "internal_order_number":"SO-0000616", "tracking_number":"1Z9VV3040306147086", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208161", "model":"Photon 100", "version":"5.0.0", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-25", "ship_date":"2023-04-25", "internal_order_number":"SO-0000558", "tracking_number":"1Z9VV3040327971655", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208162", "model":"Photon 100", "version":"5.0.0", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-25", "ship_date":"2023-04-25", "internal_order_number":"SO-0000559", "tracking_number":"1Z9VV3040310879340", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208163", "model":"Photon 100", "version":"4.3.5v1.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-30", "ship_date":"2023-03-30", "internal_order_number":"SO-0000524", "tracking_number":"1Z9VV3040336937452", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208164", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-06", "ship_date":"2023-04-06", "internal_order_number":"SO-0000546", "tracking_number":"1Z9VV3040332798502", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208165", "model":"Photon 100", "version":"4.3.5v1.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-30", "ship_date":"2023-03-30", "internal_order_number":"SO-0000523", "tracking_number":"1Z9VV3040321841047", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208166", "model":"Photon 100", "version":"4.3.5v1.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-30", "ship_date":"2023-03-30", "internal_order_number":"SO-0000524", "tracking_number":"1Z9VV3040336937452", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208167", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-04-20", "ship_date":"2023-04-20", "internal_order_number":"RMA-0000555", "tracking_number":"1Z9VV3040111162900", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208168", "model":"Photon 100", "version":"4.3.5v1.1", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-03-30", "ship_date":"2023-03-30", "internal_order_number":"SO-0000526", "tracking_number":"1Z9VV3040334218072", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208169", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-04-20", "ship_date":"2023-04-20", "internal_order_number":"RMA-0000555", "tracking_number":"1Z9VV3040111162900", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208170", "model":"Photon 100", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-04-11", "ship_date":"2023-04-11", "internal_order_number":"SO-0000549", "tracking_number":"1Z9VV3040306378247", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208181", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-18", "ship_date":"2023-09-18", "internal_order_number":"SO-0000743", "tracking_number":"1Z9VV3040309352410", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208182", "model":"Photon 100", "version":"4.3.7 Ubuntu 22.04", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-07", "internal_order_number":"POC-0000842", "tracking_number":"1Z9VV3040319045279", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208183", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-21", "ship_date":"2023-09-21", "internal_order_number":"SO-0000748", "tracking_number":"773494508405", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208184", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-18", "ship_date":"2023-09-18", "internal_order_number":"SO-6000748", "tracking_number":"1220018321", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208185", "model":"Photon 100", "version":"5.0.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-12", "ship_date":"2023-09-12", "internal_order_number":"SO-0000722", "tracking_number":"7141108833", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208186", "model":"Photon 100", "version":"5.0.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-21", "internal_order_number":"POC-0000753", "tracking_number":"1Z9VV3040138134248", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208187", "model":"Photon 100", "version":"5.0.4", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-12", "ship_date":"2023-09-12", "internal_order_number":"SO-0000722", "tracking_number":"7141108833", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208188", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-21", "ship_date":"2023-09-21", "internal_order_number":"SO-0000748", "tracking_number":"773494508405", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208189", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-18", "ship_date":"2023-09-18", "internal_order_number":"SO-6000748", "tracking_number":"1220018321", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208190", "model":"Photon 100", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-09-21", "ship_date":"2023-09-21", "internal_order_number":"SO-0000748", "tracking_number":"773494508405", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208191", "model":"Photon 100", "version":"4.3.5 MDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-05-25", "internal_order_number":"RMA-0000584", "tracking_number":"1Z9VV3041309176226", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208192", "model":"Photon 100", "version":"None", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"POC-0000476", "tracking_number":"1Z9VV3040304147651", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208193", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000428", "tracking_number":"2078151036", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208195", "model":"Photon 100", "version":"None", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"POC-0000476", "tracking_number":"1Z9VV3040304147651", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208197", "model":"Photon 100", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-02-02", "ship_date":"2023-02-02", "internal_order_number":"SO-0000472", "tracking_number":"1Z9VV3040312920488", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208198", "model":"Photon 100", "version":"4.3.5v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-05-09", "ship_date":"2023-05-09", "internal_order_number":"SO-0000570", "tracking_number":"1Z9VV3040331231295", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202208199", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000427", "tracking_number":"2078149990", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANO202209001", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-06", "ship_date":"2023-07-06", "internal_order_number":"SO-0000658", "tracking_number":"1Z9VV3040314372899", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209002", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-07", "internal_order_number":"INT-0000665", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209004", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"MAC:00:e0:97:1c:ca:4\r\nAgio Tag : AG1003672", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-28", "ship_date":"2023-06-28", "internal_order_number":"SO-0000627", "tracking_number":"1Z9VV3040107707571", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209005", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"MAC:00:e0:97:1c:ca:23\r\nAgio Tag : AG1003673", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-28", "ship_date":"2023-06-28", "internal_order_number":"SO-0000627", "tracking_number":"1Z9VV3040107707571", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209006", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-26", "ship_date":"2023-07-26", "internal_order_number":"SO-0000634", "tracking_number":"7897493041", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209007", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-26", "ship_date":"2023-07-26", "internal_order_number":"SO-0000634", "tracking_number":"7897493041", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209008", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-26", "ship_date":"2023-07-26", "internal_order_number":"SO-0000634", "tracking_number":"7897493041", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209009", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-26", "ship_date":"2023-07-26", "internal_order_number":"SO-0000634", "tracking_number":"7897493041", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209010", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-26", "ship_date":"2023-07-26", "internal_order_number":"SO-0000634", "tracking_number":"7897493041", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209011", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-05", "ship_date":"2022-12-05", "internal_order_number":"SO-0000395", "tracking_number":"6004575032", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209012", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-25", "ship_date":"2023-07-25", "internal_order_number":"SO-0000685", "tracking_number":"1Z9VV3040320835083", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209013", "model":"Photon 150", "version":"4.3.6 Modular", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-25", "ship_date":"2023-07-25", "internal_order_number":"SO-0000686", "tracking_number":"1Z9VV3040318310386", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209015", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-12-05", "ship_date":"2022-12-05", "internal_order_number":"SO-0000395", "tracking_number":"6004575032", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209018", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-07-10", "internal_order_number":"POC-0000666", "tracking_number":"1Z9VV3040323537113\t", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209019", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-25", "ship_date":"2023-07-25", "internal_order_number":"SO-0000687", "tracking_number":"1Z9VV3040331997898", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209021", "model":"Photon 150", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-01-30", "internal_order_number":"SO-0000466", "tracking_number":"4940371203", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209022", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209023", "model":"Photon 150", "version":"4.3.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-01-30", "internal_order_number":"SO-0000466", "tracking_number":"4940371203", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209024", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"SO-0000466", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209025", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209026", "model":"Photon 150", "version":"5.0.0v2", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-04-11", "ship_date":"2023-04-11", "internal_order_number":"SO-0000543", "tracking_number":"2671284350", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209031", "model":"Photon 150", "version":"4.3.5", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-08", "ship_date":"2023-06-08", "internal_order_number":"SO-0000601", "tracking_number":"1Z9VV3040330413573", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209032", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-04-18", "ship_date":"2023-04-18", "internal_order_number":"SO-0000554", "tracking_number":"1Z9VV3040327880191", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209033", "model":"Photon 150", "version":"4.3.7 Modular Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-20", "internal_order_number":"RMA-0000820", "tracking_number":"1Z9VV3040114851955", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209034", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209035", "model":"Photon 150", "version":"", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-02-01", "internal_order_number":"INT-0000490", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209036", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-28", "internal_order_number":"RMA-0000641", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209037", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"MAC:00:e0:97:1c:ca:9b\r\nAgio Tag : AG1003671", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-28", "ship_date":"2023-06-28", "internal_order_number":"SO-0000627", "tracking_number":"1Z9VV3040107707571", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209038", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"MAC:00:e0:97:1c:c4:41\r\nAgio Tag : AG1003674", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-06-28", "ship_date":"2023-06-28", "internal_order_number":"SO-0000627", "tracking_number":"1Z9VV3040107707571", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209039", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-06-28", "internal_order_number":"RMA-0000641", "tracking_number":"", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209040", "model":"Photon 150", "version":"4.3.6 Modular", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-25", "ship_date":"2023-07-25", "internal_order_number":"SO-0000688", "tracking_number":"1Z9VV3040330615300", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209041", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209042", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209043", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2025-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209044", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-01-05", "ship_date":"2023-01-05", "internal_order_number":"SO-0000448", "tracking_number":"32784", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209045", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-08-04", "internal_order_number":"RMA-0000697", "tracking_number":"1Z9VV3044412830212", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209046", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-07-07", "ship_date":"2023-07-10", "internal_order_number":"SO-0000684", "tracking_number":"4677777182", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209047", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-07-07", "ship_date":"2023-07-10", "internal_order_number":"SO-0000684", "tracking_number":"4677777182", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209048", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-07-07", "ship_date":"2023-07-10", "internal_order_number":"SO-0000684", "tracking_number":"4677777182", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209049", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-07-07", "ship_date":"2023-07-10", "internal_order_number":"SO-0000684", "tracking_number":"4677777182", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209050", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-07-07", "ship_date":"2023-07-10", "internal_order_number":"SO-0000684", "tracking_number":"4677777182", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202209107", "model":"Photon 150", "version":"4.3.6 SDS", "location":"Stellar Cyber", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-07-20", "ship_date":"2023-07-20", "internal_order_number":"SO-0000674", "tracking_number":"1Z9VV3040322514256", "recieved_date":"2022-12-01"},
{"serial_number":"YL-NANO202308001", "model":"Photon 150", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-14", "ship_date":"2023-09-14", "internal_order_number":"SO-0000739", "tracking_number":"1Z9VV3040308692804", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308002", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000751", "tracking_number":" 2396207284", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308003", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000751", "tracking_number":" 2396207284", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308004", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000751", "tracking_number":" 2396207284", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308005", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000751", "tracking_number":" 2396207284", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308006", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000805", "tracking_number":"1Z9VV3040310473902", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308007", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000699", "tracking_number":"8379059452", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308008", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000805", "tracking_number":"1Z9VV3040310473902", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308009", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000805", "tracking_number":"1Z9VV3040310473902", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308010", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000805", "tracking_number":"1Z9VV3040310473902", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308011", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-03", "internal_order_number":"RMA-0000840", "tracking_number":"1Z9VV3040100087227", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308014", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-13", "ship_date":"2023-11-10", "internal_order_number":"SO-0000838", "tracking_number":"1Z9VV3040339945474", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308015", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-10", "internal_order_number":"RMA-0000843", "tracking_number":"1Z9VV3040101936101", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308016", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000805", "tracking_number":"1Z9VV3040310473902", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308017", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-13", "ship_date":"2023-10-13", "internal_order_number":"SO-0000804", "tracking_number":"1Z9VV3040206563511", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308018", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-12", "internal_order_number":"RMA-0000807", "tracking_number":"1Z9VV3040329655538", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308019", "model":"Photon 150", "version":"4.3.7 Modular 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-18", "ship_date":"2023-10-18", "internal_order_number":"RMA-0000818", "tracking_number":"1Z9VV3040109121735", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308020", "model":"Photon 150", "version":"4.3.7 Modular 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-20", "ship_date":"2023-10-20", "internal_order_number":"SO-0000816", "tracking_number":"1Z9VV3040302406562", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308021", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-12", "ship_date":"2023-09-12", "internal_order_number":"SO-0000734", "tracking_number":"1Z9VV3040301688144", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308022", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-08", "ship_date":"2023-09-08", "internal_order_number":"SO-0000714", "tracking_number":"1Z9VV3040320095552", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308023", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000699", "tracking_number":"8379059452", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308024", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-08", "ship_date":"2023-09-08", "internal_order_number":"SO-0000733", "tracking_number":"1Z9VV3040323168567", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308025", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-12", "internal_order_number":"RMA-0000736", "tracking_number":"1Z9VV3040116289757", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308026", "model":"Photon 150", "version":"4.3.6 Modular", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000801", "tracking_number":"1Z9VV3040303045469", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308027", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"SO-0000788", "tracking_number":"1Z9VV3040329394329", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308028", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-03", "ship_date":"2023-10-03", "internal_order_number":"RMA-0000846", "tracking_number":"1Z9VV3040322963708", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308029", "model":"Photon 150", "version":"4.3.6 Modular", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-22", "ship_date":"2023-09-22", "internal_order_number":"SO-0000750", "tracking_number":"1Z9VV3040307505026", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308030", "model":"Photon 150", "version":"4.3.6 Modular", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-06", "internal_order_number":"RMA-0000803", "tracking_number":"1Z9VV3044402163079", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308031", "model":"Photon 150", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-14", "ship_date":"2023-09-14", "internal_order_number":"SO-0000705", "tracking_number":"1Z9VV3040314626196", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308032", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-09-26", "internal_order_number":"RMA-0000751", "tracking_number":" 2396207284", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308033", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000699", "tracking_number":"8379059452", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308034", "model":"Photon 150", "version":"5.0.4", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-09-14", "ship_date":"2023-09-14", "internal_order_number":"SO-0000706", "tracking_number":"1Z9VV3040337091195", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308035", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000699", "tracking_number":"8379059452", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308036", "model":"Photon 150", "version":"4.3.7 Modular Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-18", "ship_date":"2023-10-18", "internal_order_number":"SO-0000808", "tracking_number":"19VV3040319146124", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308037", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-17", "ship_date":"2023-10-17", "internal_order_number":"SO-0000812", "tracking_number":"1Z9VV3040330449759", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308038", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-03", "ship_date":"2023-11-03", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040333730635", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308039", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-03", "ship_date":"2023-11-03", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040333730635", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308040", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-03", "ship_date":"2023-11-03", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040333730635", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308041", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-12", "internal_order_number":"RMA-0000806", "tracking_number":"1Z9VV3040119977294", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308042", "model":"Photon 150", "version":"4.3.6 SDS", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-06", "ship_date":"2023-10-06", "internal_order_number":"SO-0000699", "tracking_number":"8379059452", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308043", "model":"Photon 150", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-23", "ship_date":"2023-10-23", "internal_order_number":"SO-0000827", "tracking_number":"1Z9VV3040323700801", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308044", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-10-12", "internal_order_number":"RMA-0000807", "tracking_number":"1Z9VV3040329655538", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308046", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-02", "ship_date":"2023-11-02", "internal_order_number":"SO-0000834", "tracking_number":"1Z9VV3040301555000", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308047", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-03", "ship_date":"2023-11-03", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040333730635", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308048", "model":"Photon 150", "version":"4.3.7 SDS 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-10-23", "ship_date":"2023-10-23", "internal_order_number":"SO-0000822", "tracking_number":"1Z9VV3040310954179", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308049", "model":"Photon 150", "version":"4.3.7 Ubuntu 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-03", "ship_date":"2023-03-07", "internal_order_number":"SO-0000828", "tracking_number":"1Z9VV3040333730635", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202308050", "model":"Photon 150", "version":"4.3.7 Modular 22.04", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-10-23", "ship_date":"2023-10-23", "internal_order_number":"SO-0000827", "tracking_number":"1Z9VV3040323700801", "recieved_date":"2023-09-01"},
{"serial_number":"YL-NANO202310002", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-14", "internal_order_number":"RMA-0000882", "tracking_number":"1Z9VV3040114395841", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310003", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-20", "internal_order_number":"RMA-0000884", "tracking_number":"1Z9VV3044422060964", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310007", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310016", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-14", "internal_order_number":"RMA-0000883", "tracking_number":"1Z9VV3040133043953", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310018", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-05", "ship_date":"2023-12-05", "internal_order_number":"SO-0000865", "tracking_number":"1Z9VV3040313279180", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310020", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-06", "ship_date":"2023-12-06", "internal_order_number":"SO-0000781", "tracking_number":"4963299165", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310021", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310022", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-01", "internal_order_number":"RMA-0000860", "tracking_number":"1Z9VV3044435497906", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310024", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-30", "ship_date":"2023-11-30", "internal_order_number":"SO-0000851", "tracking_number":"1Z9VV3040213127470", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310025", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-30", "ship_date":"2023-11-30", "internal_order_number":"SO-0000851", "tracking_number":"1Z9VV3040213127470", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310026", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310027", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-11-30", "ship_date":"2023-11-30", "internal_order_number":"SO-0000850", "tracking_number":"1Z9VV3040308407916", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310028", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310029", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-30", "ship_date":"2023-11-30", "internal_order_number":"SO-0000851", "tracking_number":"1Z9VV3040213127470", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310030", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-11-30", "ship_date":"2023-11-30", "internal_order_number":"SO-0000851", "tracking_number":"1Z9VV3040213127470", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310031", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310032", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310037", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-18", "ship_date":"2023-12-18", "internal_order_number":"SO-0000877", "tracking_number":"1147642381", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310039", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2024-12-05", "ship_date":"2023-12-05", "internal_order_number":"SO-0000865", "tracking_number":"1Z9VV3040324121917", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310040", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-12-06", "internal_order_number":"INT-0000874", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310041", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310042", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310043", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310044", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310045", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310046", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000830", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310047", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310048", "model":"Photon 160", "version":"4.3.7 Ubuntu 22.04", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2026-12-01", "ship_date":"2023-12-01", "internal_order_number":"SO-0000854", "tracking_number":"1Z9VV3040303576350", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310049", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-10", "internal_order_number":"INT-0000873", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANO202310050", "model":"Photon 160", "version":"", "location":"Texas Office", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"2023-11-10", "internal_order_number":"INT-0000873", "tracking_number":"", "recieved_date":"2023-11-01"},
{"serial_number":"YL-NANOuk1", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-01", "ship_date":"2020-12-03", "internal_order_number":"SO-0000058", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk10", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk100", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk101", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk102", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk103", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk104", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk105", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk11", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk12", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk13", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk14", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk15", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk16", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk17", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk18", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk19", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-24", "ship_date":"2020-10-24", "internal_order_number":"SO-0000054", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk2", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-01", "ship_date":"2020-12-03", "internal_order_number":"SO-0000058", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk20", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk21", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk22", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk23", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk24", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk25", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk26", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk27", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk28", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk29", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-02", "ship_date":"2020-11-02", "internal_order_number":"SO-0000055", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk3", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk30", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk31", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk32", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk33", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk34", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk35", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk36", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-24", "ship_date":"2020-11-24", "internal_order_number":"SO-0000056", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk37", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-31", "ship_date":"2020-12-31", "internal_order_number":"SO-0000059", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk38", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk39", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk4", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk40", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk41", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk42", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk43", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk44", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk45", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk46", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk47", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk48", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk49", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk5", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk50", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk51", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk52", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk53", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk54", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk55", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk56", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-07", "ship_date":"2020-12-07", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk57", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"SO-0000060", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk58", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk59", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk6", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk60", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk61", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk62", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk63", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk64", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-11-28", "ship_date":"2020-11-28", "internal_order_number":"SO-0000061", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk65", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000062", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk66", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000062", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk67", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000062", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk68", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000062", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk69", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-20", "ship_date":"2020-12-20", "internal_order_number":"SO-0000062", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk7", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk70", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-15", "ship_date":"2020-12-15", "internal_order_number":"SO-0000064", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk71", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-15", "ship_date":"2020-12-15", "internal_order_number":"SO-0000064", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk72", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-15", "ship_date":"2020-12-15", "internal_order_number":"SO-0000064", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk73", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk74", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk75", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk76", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk77", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk78", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk79", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk8", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk80", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk81", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk82", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-12-21", "ship_date":"2020-12-21", "internal_order_number":"SO-0000067", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk83", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"", "ship_date":"", "internal_order_number":"SO-0000077", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk84", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk85", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk86", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk87", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk88", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk89", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk9", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2021-10-15", "ship_date":"2020-10-15", "internal_order_number":"SO-0000053", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk90", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk91", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk92", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk93", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-01-11", "ship_date":"2021-01-11", "internal_order_number":"SO-0000078", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk94", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk95", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk96", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk97", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk98", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"},
{"serial_number":"YL-NANOuk99", "model":"Photon 100", "version":"", "location":"", "additional_information":"", "manufacturer_warranty":"", "stellarcyber_warranty":"2022-03-11", "ship_date":"2021-03-11", "internal_order_number":"SO-0000085", "tracking_number":"", "recieved_date":"2022-01-01"}]