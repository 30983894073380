// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Order, OrderWarranty, OrderHardwareItem, OrderSoftwarePart, OrderSoftwareItem, OTP, Company, ItemLog, Item, UPSTrackingData, UPSToken, ImportedSoftwareParts, ImportedProductsShipped, ImportedOrdersSoftware, ImportedOrdersHardware, ImportedOrders, ImportedProducts, Products, LoginInformation, Todo } = initSchema(schema);

export {
  Order,
  OrderWarranty,
  OrderHardwareItem,
  OrderSoftwarePart,
  OrderSoftwareItem,
  OTP,
  Company,
  ItemLog,
  Item,
  UPSTrackingData,
  UPSToken,
  ImportedSoftwareParts,
  ImportedProductsShipped,
  ImportedOrdersSoftware,
  ImportedOrdersHardware,
  ImportedOrders,
  ImportedProducts,
  Products,
  LoginInformation,
  Todo
};