import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';

Amplify.configure(awsExports); // Configure Amplify with your project settings

const APITestPage = () => {
  const [data, setData] = useState(null);
  const [cleanedData, setCleanedData] = useState([]);

  const [loading, setLoading] = useState(true);

// Form input states
  const [endpoint, setEndpoint] = useState('');
  const [path, setPath] = useState('');
  const [parameters, setParameters] = useState('');

  const fetchData = async () => {
    let queryData; // Declare queryData variable


    if(parameters)
    {
        console.log(parameters);
        console.log(JSON.parse(parameters));
    }
    // try {
    //     if (parameters) {
    //         queryData = await API.get(endpoint, path, {
    //             queryStringParameters: JSON.parse(parameters)
    //         });

    //     } else {
    //         queryData = await API.get(endpoint, path);
    //     }

    //     setData(queryData); // Data is already a JSON object
    //     setLoading(false); // Set loading to false
    // } catch (error) {
    //     console.error('Error fetching data:', error);
    //     setLoading(false); // Handle the error and set loading to false
    // }
}

  useEffect(() => {
    fetchData(); // Call fetchData when the component mounts
  }, []);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true while fetching new data
    fetchData();
  }


//   if (data) {
//     const records = data['result']['records'];

//     for (let i = 0; i < records.length; i++) {
//       const record = records[i];
//       const accountId = record['AccountId'];
//       const accountName = record['Account']['Name'];
//       if (!cleanedData.some(item => item.AccountId === accountId)) {
//         cleanedData.push({ AccountId: accountId, AccountName: accountName });
//       }
//     }
//   }

   return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label>
          API Endpoint:
          <input type="text" value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />
        </label>
        <br />
        <label>
          API Path:
          <input type="text" value={path} onChange={(e) => setPath(e.target.value)} />
        </label>
        <br />
        <label>
          Query Parameters (JSON):
          <input type="text" value={parameters} onChange={(e) => setParameters(e.target.value)} />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <p>Data:</p>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}







export default APITestPage;