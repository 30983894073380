import React, { useEffect, useState } from 'react';
// import { API } from 'aws-amplify';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';

Amplify.configure(awsExports); // Configure Amplify with your project settings

const Salesforce = () => {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    // try {
    //   const queryData = await API.get('salesforceapi', '/getObjectData', {
    //     queryStringParameters: {
    //       objectName: 'Opportunity',
    //       // ID: '006Jx000008NQJnIAO'
    //     }
    //   });
    //   setData(queryData);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }
  }

  useEffect(() => {
    fetchData(); // Call fetchData when the component mounts
  }, []);

  return (
    <div>
      {data ? (
        <div>
          <p>Data: {JSON.stringify(data)}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Salesforce;
