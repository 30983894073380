import { useState, useEffect } from "react";
import { DataStore } from "@aws-amplify/datastore";
import { Amplify } from "aws-amplify";
import awsExports from "../../aws-exports";
import { useParams } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { LoginInformation } from '../../models';

Amplify.configure(awsExports);

const DisplayUserInformation = () => {
  const isNonMobile = useMediaQuery("(min-width:600px");
  const { username } = useParams();
  const [userdata, setUserData] = useState({
    username: "",
    privilege: "",
    name: "",
    title: "",
    email: "",
    slackid: "",
    salesforce_name: "",
    se_support: "",
  });

  useEffect(() => {
    // Fetch user data when the component mounts
    getuserData();
  }, []);

  async function getuserData() {
    const awsuserdata = await DataStore.query(LoginInformation, (order) =>
      order.username.eq(username)
    );

    if (awsuserdata.length > 0) {
      // Update the state with the fetched user data
      setUserData(awsuserdata[0]);
    }
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    // Update the form data state with the new value
    setUserData({
      ...userdata,
      [name]: value,
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Form submitted");  
    // Handle form submission with updated userdata
    // You can use 'userdata' to send the updated data to your backend
    const originalUser = await DataStore.query(LoginInformation, userdata.id);

    // Example: Update user data in the database using Amplify
    const updateUser = await DataStore.save(
      LoginInformation.copyOf(originalUser, (updated) => {
        updated.username = userdata.username;
        updated.privilege = userdata.privilege;
        updated.name = userdata.name;
        updated.title = userdata.title;
        updated.email = userdata.email;
        updated.slackid = userdata.slackid;
        updated.salesforce_name = userdata.salesforce_name;
        updated.se_support = userdata.se_support;
      })
    );
  }
  

  return (
    <Box m="20px">
      <Header title="User Information" subtitle="" />

      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Username"
            name="username"
            value={userdata.username || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Privilege"
            name="privilege"
            value={userdata.privilege || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Full Name"
            name="name"
            value={userdata.name || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Title"
            name="title"
            value={userdata.title || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />

          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Email"
            name="email"
            value={userdata.email || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Slack ID"
            name="slackid"
            value={userdata.slackid  || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Salesforce Name"
            name="salesforce_name"
            value={userdata.salesforce_name || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="SE Support"
            name="se_support"
            value={userdata.se_support || ""}
            onChange={handleFieldChange}
            sx={{ gridColumn: "span 1" }}
          />
        </Box>

        <Box m="20px">
          <Button type="submit" color="secondary" variant="contained">
            Update User
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default DisplayUserInformation;
