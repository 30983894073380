import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import 'react-pro-sidebar/dist/css/styles.css';

// import { Auth } from 'aws-amplify';


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[1],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [username, setUsername] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      // try {
      //   const user = await Auth.currentAuthenticatedUser();
      //   setUsername(user.username);
      // } catch (error) {
      //   console.error('Error fetching user:', error);
      // }
    }
  
    fetchUser();
  }, []);


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[1]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "10px 0 20px 0", color: colors.grey[100],
            }}
          >
            {!isCollapsed && ( <Box   display="flex"   justifyContent="space-between"   alignItems="center"   ml="15px" >   <Typography variant="h3" color={colors.grey[1]}>     Stellar Cyber   </Typography>   <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>     <MenuOutlinedIcon />   </IconButton> </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px"> <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.jpeg`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
  />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {username}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[1]}>
                  VP Fancy Admin
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>

  <Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}>Products</Typography>
    <Item title="Display Product Data" to="/000productdisplay" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
    <Item title="Create Product" to="/000productcreate" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>

  <Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}>Customer Order Information</Typography>
    <Item title="Display Customer Information" to="/002companyview" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
    <Item title="Display Order Information" to="/002displayorder" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
<Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}
            > Import Export
            </Typography>


            <Item title="Create Order" to="/001createorder" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="UPS" to="/007UPS" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Exported Inventory" to="/0exportedinventory" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Imported Inventory" to="/0importedinventory" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Exported Orders" to="/0exportedorders" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Imported Orders" to="/0importedorders" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="View OTP" to="/003viewOTP" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected}/>


            <Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}
            > Data
            </Typography>
            <Item title="SF->Z" to="/008SFtoZSync" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
             <Item title="SF->Z Quote" to="/008SFtoZSyncQuote" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="API Testing" to="/009APITesting" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Software Part Names" to="/002softwarepartnames" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Manage Users" to="/team" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Create New User" to="/createuser" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Contacts Information" to="/contacts" icon={<ContactsOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Invoices Balances" to="/invoices" icon={<ReceiptOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="SalesForce API Data Test" to="/2salesforcelogin" icon={<ReceiptOutlinedIcon />} selected={selected} setSelected={setSelected}/>

            <Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}
            > Pages
            </Typography>
            <Item title="Profile Form" to="/form" icon={<PersonOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Calendar" to="/calendar" icon={<CalendarTodayOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="FAQ Page" to="/faq" icon={<HelpOutlineOutlinedIcon />} selected={selected} setSelected={setSelected}/>

            <Typography variant="h6" color={colors.grey[1]} sx={{ m: "15px 0 5px 20px" }}
            > Charts
            </Typography>
            <Item title="Bar Chart" to="/bar" icon={<BarChartOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Pie Chart" to="/pie" icon={<PieChartOutlineOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Line Chart" to="/line" icon={<TimelineOutlinedIcon />} selected={selected} setSelected={setSelected}/>
            <Item title="Geography Chart" to="/geography" icon={<MapOutlinedIcon />} selected={selected} setSelected={setSelected}/>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
