import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped } from '../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'


/**
 * Fetches inventory data based on the internal order number.
 *
 * @param {string} internalOrderNumber - The internal order number to identify the order.
 * @returns {Object} empty array if nothing found, else it will return an array with [orderData, orderDataHardware, orderDataHardwareItmes, orderDataSoftware]
 * @throws {Error} If an error occurs during the data fetching process.
 *
 * @example
 * const internalOrderNumber = '123456';
 * const inventoryData = await fetchInventoryData(internalOrderNumber);
 * console.log(inventoryData);
 */
export async function fetchInventoryDataTest(internalOrderNumber) {
    const result = {};
    const q_orderData = await DataStore.query(ImportedOrders, order => order.internal_order_number.eq(internalOrderNumber,));
    if(q_orderData)
    {
        result.orderData = q_orderData[0];

        if(q_orderData[0].hardware === "yes")
        {

            const q_orderDataHardware = await DataStore.query(ImportedOrdersHardware, order => order.internal_order_number.eq(internalOrderNumber,));
            console.log(q_orderDataHardware);

            if(q_orderDataHardware.length > 0)
            {
                result.orderDataHardware = q_orderDataHardware[0];

                const hardwareFields = ["M6000", "M3000", "Photon_400", "Photon_300", "Photon_250", "Photon_160", "Photon_150", "Photon_100"];
                for (const field of hardwareFields) {
                    const target_value = result.orderDataHardware[field] || 0;
                    const formattedField = field.replace(/_/g, ' ');
                    const q_orderDataHardwareItems = await DataStore.query(ImportedProductsShipped, (order) => order.and(order => [
                        order.model.eq(formattedField),
                        order.internal_order_number.eq(internalOrderNumber)
                    ]));
    
                    result.orderDataHardwareItems = result.orderDataHardwareItems || {};
                    result.orderDataHardwareItems[field] = result.orderDataHardwareItems[field] || [];
    
                    const dummy_data = {
                        serial_number: "",
                        model: formattedField,
                        version: "",
                        location: "",
                        additional_information: "",
                        manufacturer_warranty: "",
                        ship_date: "",
                        internal_order_number: "",
                        tracking_number: "",
                        received_date: "",
                        stellarcyber_warranty: ""
                    }
                    
                    
                    if(q_orderDataHardwareItems.length > 0)
                    {
                        result.orderDataHardwareItems[field] = q_orderDataHardwareItems;
                    }
                    else
                    {
                        result.orderDataHardwareItems = result.orderDataHardwareItems || {};
    
                    }
                    if(q_orderDataHardwareItems.length < target_value)
                    {
                        for (let i = q_orderDataHardwareItems.length; i < target_value; i++)
                        {
                            result.orderDataHardwareItems[field].push(dummy_data);
                        }
                    }
    
                };
            }
            else
            {
                console.log("need to create hardware information");
            }
        }

        if(q_orderData[0].software === "yes")
        {
            const q_orderDataSoftware = await DataStore.query(ImportedOrdersSoftware, order => order.internal_order_number.eq(internalOrderNumber,));
            if(q_orderDataSoftware.length > 0)
            {
                result.orderDataSoftware = q_orderDataSoftware[0];

            }
            else
            {
                console.log("need to create software information");

            }
        }
    }

    console.log(result);


    return result;

}
