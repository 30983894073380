export const createChannelName = (internal_order_number, order_type, partner_name, end_customer_name) =>
{
    let channel_name;
    
    const lastFourCharacters = internal_order_number.substring(internal_order_number.length - 4);
    const orderType = order_type || '';  // Default to an empty string if orderType is null or undefined
    const partnerName = partner_name || '';  // Default to an empty string if partnerName is null or undefined
    const endCustomerName = end_customer_name || '';  // Default to an empty string if endCustomerName is null or undefined
    console.log("partnerName : " + partnerName + " endCustomerName : " + endCustomerName);

    // Concatenate the string and limit to 80 characters
    // Helper function to sanitize a string
    const sanitizeString = (str) => {
        return str.replace(/[^a-z0-9_]/ig, ''); // Remove anything that is not a-z, 0-9, or _
    };
    if(partnerName === '' && endCustomerName === '')
    {
        channel_name = `${sanitizeString(orderType)}_${sanitizeString(lastFourCharacters)}`.substring(0, 80);
        console.log("no customer names");
    }
    else if(partnerName === '')
    {
        console.log("no partner names");

        channel_name = `${sanitizeString(orderType)}_${sanitizeString(lastFourCharacters)}_${sanitizeString(endCustomerName)}`.substring(0, 80);
    }
    else if (endCustomerName === '')
    {
        console.log("no end names");

        channel_name = `${sanitizeString(orderType)}_${sanitizeString(lastFourCharacters)}_${sanitizeString(partnerName)}`.substring(0, 80);
        
    }
    else if (partnerName == endCustomerName)
    {
        console.log("same name");

        channel_name = `${sanitizeString(orderType)}_${sanitizeString(lastFourCharacters)}_${sanitizeString(partnerName)}`.substring(0, 80);

    }
    else
    {
        console.log("has both naemes");

        channel_name = `${sanitizeString(orderType)}_${sanitizeString(lastFourCharacters)}_${sanitizeString(partnerName)}_${sanitizeString(endCustomerName)}`.substring(0, 80);

    }

    channel_name = channel_name.toLowerCase();
    return channel_name;
}