import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import ReactPDF from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import awsExports from "../../aws-exports";
import { Amplify } from 'aws-amplify';

Amplify.configure(awsExports); // Configure Amplify with your project settings


const PDFCreate = () => {

 
    // async function testupload (){
    //     try {
    //         const result = await uploadData({key: "TestUpload2023_12_05_1259PM",data: "./test.pdf"}).result;
    //         console.log("Succeeded: ", result);
    //       } catch (error) {
    //         console.log("Error : ", error);
    //       }
    // }

    return(
"hi"
    )
}
   


  export default PDFCreate;