import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';


import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";

import { ImportedOrders, ImportedOrdersHardware, ImportedOrdersSoftware, ImportedProductsShipped, ImportedProducts } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { Amplify} from 'aws-amplify'
import awsExports from "../../aws-exports";
import { useParams } from 'react-router-dom';

import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react';
import { ItemLog } from "../../models";
import { Item } from "../../models";

Amplify.configure(awsExports);

const ProductCreate = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");  

  //Display error messages
  const [errorMessage, setErrorMessage] = useState("");

  // Valid Models ***Switch this into a datastore for later, that way it will be easier to add
  const valid_model_names = ["M6000", "M3000", "Photon 400", "Photon 300", "Photon 260", "Photon 250", "Photon 160", "Photon 150", "Photon 100"];


  const [items_creation, set_items_creation] = useState({
    serial_numbers: '',
    model: '',
    manufactuer_warranty: '',
    received_date: '',
    pool: '',
    location: ''
  });

  const handleUpdateItemsData = (event) => {
    const { name, value } = event.target;
    set_items_creation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  


  //Variable for userInformation
  const [itemData, set_itemData] = useState([]);
  async function fetchData()
  {
    try {
    const importedItemData = await DataStore.query(Item);
    if (importedItemData) {set_itemData(importedItemData);}
    } 
    catch (error) {console.error('Error fetching Login Information:', error);}
  }
  useEffect(() => {fetchData()}, []);


    
  // Seperates the serial numbers into array, check if any of the serial numbers are duplicated
  function seperate_serial_numbers(serial_numbers)
  {
    // Check if serial_numbers is empty
    if (!serial_numbers) {setErrorMessage("Error: Serial numbers input is empty"); return [];}
    // Check if serial_numbers ends with a semicolon
    if (serial_numbers.endsWith(";")) 
    {
      // Remove the trailing semicolon
      serial_numbers = serial_numbers.slice(0, -1);
    }
    
    // Split the serial numbers by semicolon
    let separated_serials = serial_numbers.split(";");
    
    // Trim each serial number to remove any leading or trailing whitespace
    separated_serials = separated_serials.map(serial => serial.trim());


    // // Check if the serial numbers exists
    // separated_serials.map((serial_number) => {
    //   if(itemData.serial_number.includes(serial_number))
    //   {
    //     setErrorMessage("Error: This serial numbers already exists. " + serial_number); return [];
    //   }
    // })
    // Return the array
    return separated_serials;
  }
  // Checks is model name is valid
  function check_model(model_name)
  {
    if (!model_name) {setErrorMessage("Error: No model name is entered"); return false;}
    if(valid_model_names.includes(model_name)){return true;}
    else{{setErrorMessage("Error: Model name is invalid. Please enter one of the following : " + valid_model_names); return [];}return false;}
  }
   // Checks is model name is valid
   function check_received_date(received_date)
   {
     if (!received_date) {setErrorMessage("Error: No received date was entered."); return false;}
     return true;
   }
  

  //Creates item in aws database
  async function handleCreateIems(event)
  {
    event.preventDefault();
    setErrorMessage("");
    check_model(items_creation.model);
    check_received_date(items_creation.received_date);
    const seperated_serial_numbers = seperate_serial_numbers(items_creation.serial_numbers);
    if(errorMessage == "")
    {
      // Adds the serial number into movement log
      try {
        await Promise.all(
          seperated_serial_numbers.map(async (serial_number) => {
            await DataStore.save(
              new ItemLog({
                movement_date: items_creation.received_date,
                from_location: "Manufacturer",
                pool: items_creation || "New",
                to_location: items_creation.location || "Stellar Cyber HQ",
                serial_number: serial_number // Use the serial_number from the loop
              })
            );
          })
        );
      } catch (error) {console.error("Error saving items to database:", error);}

      // Adds the serial number into items
      try {
        await Promise.all(
          seperated_serial_numbers.map(async (serial_number) => {
            await DataStore.save(
              new Item({
              serial_number: serial_number,
              model: items_creation.model,
              location: items_creation.location || "Stellar Cyber HQ",
              received_date:  items_creation.received_date,
              pool: items_creation.pool || "New",
            })
          );
          })
        );
      }
      catch (error) {console.error("Error saving items to database:", error);}
    }
    console.log(items_creation);
  }



  return (
    
 <Box m="20px">
  <Box m="20px">
      <h1>Create Items</h1>
  </Box>
  <form onSubmit={handleCreateIems}>

  {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
  <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
 
  <TextField fullWidth variant="filled" id="serial_numbers" name="serial_numbers" label="Serial Numbers" multiline rows={4} placeholder="SerialNumber1;SerialNumber2;"
    sx={{ gridColumn: "span 4" }} onChange={handleUpdateItemsData} value={items_creation.serial_numbers || ""} />

  </Box>
  <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
  <TextField fullWidth variant="filled" type="text" label="Model" name="model"
          sx={{ gridColumn: "span 1" }} onChange={handleUpdateItemsData} value={items_creation.model || ""}/>
  <TextField fullWidth variant="filled" type="date" label="Manufactuer Warranty" name="manufactuer_warranty"
          sx={{ gridColumn: "span 1" }} onChange={handleUpdateItemsData} value={items_creation.manufactuer_warranty || ""}/>
  <TextField fullWidth variant="filled" type="date" label="Received Date" name="received_date"
          sx={{ gridColumn: "span 1" }} onChange={handleUpdateItemsData} value={items_creation.received_date || ""}/>
  <TextField fullWidth variant="filled" type="text" label="Pool" name="pool"
          sx={{ gridColumn: "span 1" }} onChange={handleUpdateItemsData} value={items_creation.pool || ""}/>
            <TextField fullWidth variant="filled" type="text" label="Location" name="location"
          sx={{ gridColumn: "span 1" }} onChange={handleUpdateItemsData} value={items_creation.location || ""}/>
  </Box>
  <Box m="20px">
    <Button type="submit" color="secondary" variant="contained">
        Add Items To Inventory
    </Button>
  </Box>
    </form>     
    </Box>
  );

};

export default ProductCreate;


