
import { Box, Typography, useTheme, Button, TextField,FormControl,InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';

{/* IOT, EOT, Order Type, Order Date, Partner Name, End Customer Name, Sales Rep */}
export function orderinformation_setone(displayOrderData, handleTextFieldData, handleOrderTypeData, handleDateFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* Internal Order Number */}
                <TextField fullWidth type="text" label="Internal Order Number" value={displayOrderData.internal_order_number || ""} name="internal_order_number"
            onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} disabled />
            {/* External Order Number */}
            <TextField fullWidth type="text" label="External Order Number" value={displayOrderData.external_order_number || ""} name="external_order_number"
            onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
            {/* Order Type */}
            <FormControl fullWidth>
                <InputLabel id="order_type">Order Type</InputLabel>
                <Select labelId="order_type" name="order_type" label="Order Type" value={displayOrderData.order_type || "SO"} onChange={handleOrderTypeData} sx={{ gridColumn: "span 1" }}>
                    <MenuItem value={"SO"}>SO</MenuItem>
                    <MenuItem value={"POC"}>POC</MenuItem>
                    <MenuItem value={"RMA"}>RMA</MenuItem>
                    <MenuItem value={"INT"}>INT</MenuItem>
                </Select>
            </FormControl>
            {/* Order Date */}
            <DatePicker fullWidth label="Order Date" defaultValue={dayjs(displayOrderData.order_date) || dayjs()}  name="order_date"
                onChange={handleDateFieldData} sx={{ gridColumn: "span 1" }} />
            {/* Partner Name */}
            <TextField fullWidth type="text" label="Partner Name" value={displayOrderData.partner_name || ""} name="partner_name"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
            {/* End Customer Name*/}
            <TextField fullWidth type="text" label="End Customer Name" value={displayOrderData.end_customer_name || ""} name="end_customer_name"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
            {/*Sales Rep*/}
            <TextField fullWidth type="text" label="Sales Rep" value={displayOrderData.sales_rep || ""} name="sales_rep"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
        </Box>
    )
}

{/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
export function orderinformation_settwo(displayOrderData, handleTextFieldData, isNonMobile)
{
    return(
         <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
         {/*Ship Country*/}
         <TextField fullWidth type="text" label="Ship Country" value={displayOrderData.ship_country || ""} name="ship_country"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
         {/*Ship Address*/}
         <TextField fullWidth multiline type="text" label="Ship Address" value={displayOrderData.ship_address || ""} name="ship_address"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 3" }} />
         {/*Bill Country*/}
         <TextField fullWidth type="text" label="Bill Country" value={displayOrderData.bill_country || ""} name="bill_country"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
         {/*Bill Address*/}
         <TextField fullWidth multiline type="text" label="Bill Address" value={displayOrderData.bill_address || ""} name="bill_address"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 3" }} />
         {/*Contact Name*/} 
         <TextField fullWidth type="text" label="Contact Name" value={displayOrderData.contact_name || ""} name="contact_name"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
         {/*Contact Phone*/} 
         <TextField fullWidth type="text" label="Contact Phone" value={displayOrderData.contact_phone || ""} name="contact_phone"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
         {/*Contact Email*/} 
         <TextField fullWidth type="text" label="Contact Email" value={displayOrderData.contact_email || ""} name="contact_email"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 2" }} />
     </Box>
    )
}

{/* Hardware,Software,Progress,Additional Information,Internal Invoices,Salesforce Link */}
export function orderinformation_setthree(displayOrderData, handleTextFieldData, isNonMobile)
{
    return(
         <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
         {/*Hardware*/} 
         <FormControl fullWidth>
         <InputLabel id="hardware">Hardware</InputLabel>
         <Select labelId="hardware" name="hardware" label="Hardware" value={displayOrderData.hardware || "no"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"no"}>No</MenuItem>
                     <MenuItem value={"yes"}>Yes</MenuItem>
         </Select>
         </FormControl>

         {/*software*/} 
         <FormControl fullWidth>
         <InputLabel id="software">Software</InputLabel>
         <Select labelId="software" name="software" label="Software" value={displayOrderData.software || "no"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"no"}>No</MenuItem>
                     <MenuItem value={"yes"}>Yes</MenuItem>
         </Select>
         </FormControl>
         {/*progress*/}
         <FormControl fullWidth>
         <InputLabel id="progress">Progress</InputLabel>
         <Select labelId="progress" name="progress" label="Progress" value={displayOrderData.progress || "New"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"New"}>New</MenuItem>
                     <MenuItem value={"On Hold"}>On Hold</MenuItem>
                     <MenuItem value={"Completed"}>Completed</MenuItem>
         </Select>
         </FormControl>
         {/*additional_information*/} 
         <TextField fullWidth multiline type="text" label="Additional Details" value={displayOrderData.additional_details || ""} name="additional_details"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 4" }} />
         {/*internal_invoices*/} 
         <TextField fullWidth type="text" label="Internal Invoice #" value={displayOrderData.internal_invoice || ""} name="internal_invoice"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
         {/*salesforce_link*/} 
         <TextField fullWidth type="text" label="Salesforce Link" value={displayOrderData.salesforce_link || ""} name="salesforce_link"
         onChange={handleTextFieldData} sx={{ gridColumn: "span 2" }} />
     </Box>
    );
}

{/* slack channel, notifications, slack channel archive, software cancelled, opportunityid, orderid */}
export function orderinformation_setfour(displayOrderData, handleTextFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*slack_channel*/} 
            <TextField fullWidth type="text" label="Slack Channel" value={displayOrderData.slack_channel || ""} name="slack_channel"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
            {/*notifications*/} 
            <FormControl fullWidth>
            <InputLabel id="notifications">Notifications</InputLabel>
            <Select labelId="notifications" name="notifications" label="Notifications" value={displayOrderData.notifications || "1"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*slack_channel_archive*/} 
            <FormControl fullWidth>
            <InputLabel id="slack_channel_archive">Slack Channel Archive</InputLabel>
            <Select labelId="slack_channel_archive" name="slack_channel_archive" label="Software Cancelled" value={displayOrderData.slack_channel_archive || "0"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*software_cancelled*/} 
            <FormControl fullWidth>
            <InputLabel id="software_cancelled">Software Cancelled</InputLabel>
            <Select labelId="software_cancelled" name="software_cancelled" label="Software Cancelled" value={displayOrderData.software_cancelled || "0"} onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*opportunityid*/} 
            <TextField fullWidth type="text" label="Opportunity ID" value={displayOrderData.opportunityid || ""} name="opportunityid"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
            {/*orderid*/} 
            <TextField fullWidth type="text" label="Order ID" value={displayOrderData.orderid || ""} name="orderid"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 1" }} />
</Box>
    )
}


export function orderinformation_hardware_setone(displayOrderData, hardwareFields, handleNumberFieldData, isNonMobile)
{
    const components = [];

    for (let index = 0; index < hardwareFields.length; index++)
    {
        components.push(<TextField fullWidth type="number" label={hardwareFields[index].replace(/_/g, " ")}   value={displayOrderData[hardwareFields[index]] || ""} name={hardwareFields[index]}
            onChange={handleNumberFieldData} sx={{ gridColumn: "span 1" }}/>);
    }
    
    return(<Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
        {components}
        
    </Box>
    );
}

export function orderinformation_hardware_settwo(displayOrderData, handleNumberFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            <TextField fullWidth type="number" label="Hardware Warranty"  value={displayOrderData.additional_warranty || ""} name="additional_warranty"
                onChange={handleNumberFieldData} sx={{ gridColumn: "span 1" }}/>
        </Box>
    );
}

export function orderinformation_hardware_setthree(displayOrderData, handleTextFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            <TextField multiline fullWidth type="text" label="Additional Details"  value={displayOrderData.addtional_details || ""} name="addtional_details"
                onChange={handleTextFieldData} sx={{ gridColumn: "span 4" }}/>
        </Box>
    );
}

export function orderinformation_software_setone(displayOrderData, handleFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Number of Items*/} 
            <TextField fullWidth type="number" label="Number of Items"   value={displayOrderData.items || ""} name="items"
            onChange={handleFieldData} sx={{ gridColumn: "span 1" }}/>
            {/*Ramp*/} 
            <FormControl fullWidth>
                <InputLabel id="ramp">Ramp</InputLabel>
                <Select labelId="ramp" name="ramp" label="ramp" value={displayOrderData.ramp || "0"} onChange={handleFieldData} sx={{ gridColumn: "span 1" }}>
                            <MenuItem value={"0"}>No</MenuItem>
                            <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
export function orderinformation_software_settwo(displayOrderData, handleFieldData, handleDateFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*OTP*/} 
            <TextField fullWidth type="text" label="OTP"   value={displayOrderData.otp || ""} name="otp"
            onChange={handleFieldData} sx={{ gridColumn: "span 1" }}/>
            {/* End Date */}
            <DatePicker 
                fullWidth label="End Date" defaultValue={dayjs(displayOrderData.end_date) || dayjs()}  name="end_date"
                onChange={(date) => handleDateFieldData(date, "end_date")}
                sx={{ gridColumn: "span 1" }} />
            {/*Ramp*/} 
            <FormControl fullWidth>
                <InputLabel id="SaaS">SaaS</InputLabel>
                <Select labelId="SaaS" name="SaaS" label="SaaS" value={displayOrderData.SaaS || "0"} onChange={handleFieldData} sx={{ gridColumn: "span 1" }}>
                            <MenuItem value={"0"}>No</MenuItem>
                            <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
            </FormControl>
            {displayOrderData.SaaS &&
                <TextField fullWidth type="text" label="Domain Name"   value={displayOrderData.domain_name || ""} name="domain_name"
                onChange={handleFieldData} sx={{ gridColumn: "span 1" }}/>
            }
        </Box> 
    )
}
export function orderinformation_software_setthree(displayOrderData, handleFieldData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Additional Details*/}
            <TextField fullWidth multiline type="text" label="Additional Details" value={displayOrderData.additional_details || ""} name="additional_details"
            onChange={handleFieldData} sx={{ gridColumn: "span 4" }} />
        </Box>
    )
}
export function orderinformation_software_setfour(displayOrderData, handleFieldData, handleDateFieldData, isNonMobile)
{

    const components = [];

    for(let counter = 1; counter <= displayOrderData.items; counter++ )
    {
        components.push(
            <Box key={counter} display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* OTP */}
            <TextField fullWidth type="text" label={`Item ${counter}`} value={displayOrderData[`item_${counter}`] || ""} name={`item_${counter}`} onChange={handleFieldData} sx={{ gridColumn: "span 2" }}/>
            <TextField fullWidth type="number" label={`Quantity ${counter}`} value={displayOrderData[`quantity_${counter}`] || ""} name={`quantity_${counter}`} onChange={handleFieldData} sx={{ gridColumn: "span 1" }}/>
            <TextField fullWidth type="number" label={`Term ${counter}`} value={displayOrderData[`term_${counter}`] || ""} name={`term_${counter}`} onChange={handleFieldData} sx={{ gridColumn: "span 1" }}/>
            {displayOrderData.ramp &&
                <DatePicker fullWidth label={`Start Date ${counter}`} defaultValue={dayjs(displayOrderData[`start_date_${counter}`]) || dayjs()} name={`start_date_${counter}`} 
                onChange={(date) => handleDateFieldData(date, `start_date_${counter}`)}
                sx={{ gridColumn: "span 1" }} />
            }
            </Box>

        )
    }

    return components;

}


{/* IOT, EOT, Order Type, Order Date, Partner Name, End Customer Name, Sales Rep */}
export function displayorderinformation_setone(displayOrderData, handleDateFieldData,isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* Internal Order Number */}
            <TextField disabled fullWidth type="text" label="Internal Order Number" value={displayOrderData.internal_order_number || ""} name="internal_order_number"
            sx={{ gridColumn: "span 1" }} />
            {/* External Order Number */}
            <TextField disabled fullWidth type="text" label="External Order Number" value={displayOrderData.external_order_number || ""} name="external_order_number"
            sx={{ gridColumn: "span 1" }} />
            {/* Order Type */}
            <TextField disabled fullWidth type="text" label="Order Type" value={displayOrderData.order_type|| ""} name="order_type"
            sx={{ gridColumn: "span 1" }} />
            {/* Order Date */}
            <DatePicker disabled fullWidth label="Order Date" defaultValue={dayjs(displayOrderData.order_date) || dayjs()}  name="order_date"
                onChange={handleDateFieldData} sx={{ gridColumn: "span 1" }} />
            {/* Partner Name */}
            <TextField disabled fullWidth type="text" label="Partner Name" value={displayOrderData.partner_name || ""} name="partner_name"
                sx={{ gridColumn: "span 1" }} />
            {/* End Customer Name*/}
            <TextField disabled fullWidth type="text" label="End Customer Name" value={displayOrderData.end_customer_name || ""} name="end_customer_name"
                sx={{ gridColumn: "span 1" }} />
            {/*Sales Rep*/}
            <TextField disabled fullWidth type="text" label="Sales Rep" value={displayOrderData.sales_rep || ""} name="sales_rep"
                sx={{ gridColumn: "span 1" }} />
        </Box>
    )
}

{/* Ship Country/Address, Bill Country/Address, Contact Name/Phone/Email*/}
export function displayorderinformation_settwo(displayOrderData, isNonMobile)
{
    return(
         <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
         {/*Ship Country*/}
         <TextField disabled fullWidth type="text" label="Ship Country" value={displayOrderData.ship_country || ""} name="ship_country"
            sx={{ gridColumn: "span 1" }} />
         {/*Ship Address*/}
         <TextField disabled fullWidth multiline type="text" label="Ship Address" value={displayOrderData.ship_address || ""} name="ship_address"
            sx={{ gridColumn: "span 3" }} />
         {/*Bill Country*/}
         <TextField disabled fullWidth type="text" label="Bill Country" value={displayOrderData.bill_country || ""} name="bill_country"
            sx={{ gridColumn: "span 1" }} />
         {/*Bill Address*/}
         <TextField disabled fullWidth multiline type="text" label="Bill Address" value={displayOrderData.bill_address || ""} name="bill_address"
            sx={{ gridColumn: "span 3" }} />
         {/*Contact Name*/} 
         <TextField disabled fullWidth type="text" label="Contact Name" value={displayOrderData.contact_name || ""} name="contact_name"
            sx={{ gridColumn: "span 1" }} />
         {/*Contact Phone*/} 
         <TextField disabled fullWidth type="text" label="Contact Phone" value={displayOrderData.contact_phone || ""} name="contact_phone"
            sx={{ gridColumn: "span 1" }} />
         {/*Contact Email*/} 
         <TextField disabled fullWidth type="text" label="Contact Email" value={displayOrderData.contact_email || ""} name="contact_email"
            sx={{ gridColumn: "span 2" }} />
     </Box>
    )
}

{/* Hardware,Software,Progress,Additional Information,Internal Invoices,Salesforce Link */}
export function displayorderinformation_setthree(displayOrderData, isNonMobile)
{
    return(
         <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
         {/*Hardware*/} 
         <FormControl fullWidth>
         <InputLabel id="hardware">Hardware</InputLabel>
         <Select disabled labelId="hardware" name="hardware" label="Hardware" value={displayOrderData.hardware || "no"} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"no"}>No</MenuItem>
                     <MenuItem value={"yes"}>Yes</MenuItem>
         </Select>
         </FormControl>

         {/*software*/} 
         <FormControl fullWidth>
         <InputLabel id="software">Software</InputLabel>
         <Select disabled labelId="software" name="software" label="Software" value={displayOrderData.software || "no"} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"no"}>No</MenuItem>
                     <MenuItem value={"yes"}>Yes</MenuItem>
         </Select>
         </FormControl>
         {/*progress*/}
         <FormControl fullWidth>
         <InputLabel id="progress">Progress</InputLabel>
         <Select disabled labelId="progress" name="progress" label="Progress" value={displayOrderData.progress || "New"} sx={{ gridColumn: "span 1" }}>
                     <MenuItem value={"New"}>New</MenuItem>
                     <MenuItem value={"On Hold"}>On Hold</MenuItem>
                     <MenuItem value={"Completed"}>Completed</MenuItem>
         </Select>
         </FormControl>
         {/*additional_information*/} 
         <TextField disabled fullWidth multiline type="text" label="Additional Details" value={displayOrderData.additional_details || ""} name="additional_details"
         sx={{ gridColumn: "span 4" }} />
         {/*internal_invoices*/} 
         <TextField disabled fullWidth type="text" label="Internal Invoice #" value={displayOrderData.internal_invoice || ""} name="internal_invoice"
         sx={{ gridColumn: "span 1" }} />
         {/*salesforce_link*/} 
         <TextField disabled fullWidth type="text" label="Salesforce Link" value={displayOrderData.salesforce_link || ""} name="salesforce_link"
         sx={{ gridColumn: "span 2" }} />
     </Box>
    );
}

{/* slack channel, notifications, slack channel archive, software cancelled, opportunityid, orderid */}
export function displayorderinformation_setfour(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*slack_channel*/} 
            <TextField disabled fullWidth type="text" label="Slack Channel" value={displayOrderData.slack_channel || ""} name="slack_channel"
                sx={{ gridColumn: "span 1" }} />
            {/*notifications*/} 
            <FormControl fullWidth>
            <InputLabel id="notifications">Notifications</InputLabel>
            <Select disabled labelId="notifications" name="notifications" label="Notifications" value={displayOrderData.notifications || "1"} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*slack_channel_archive*/} 
            <FormControl fullWidth>
            <InputLabel id="slack_channel_archive">Slack Channel Archive</InputLabel>
            <Select disabled labelId="slack_channel_archive" name="slack_channel_archive" label="Software Cancelled" value={displayOrderData.slack_channel_archive || "0"} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*software_cancelled*/} 
            <FormControl fullWidth>
            <InputLabel id="software_cancelled">Software Cancelled</InputLabel>
            <Select disabled labelId="software_cancelled" name="software_cancelled" label="Software Cancelled" value={displayOrderData.software_cancelled || "0"} sx={{ gridColumn: "span 1" }}>
                        <MenuItem value={"0"}>No</MenuItem>
                        <MenuItem value={"1"}>Yes</MenuItem>
            </Select>
            </FormControl>
            {/*opportunityid*/} 
            <TextField disabled fullWidth type="text" label="Opportunity ID" value={displayOrderData.opportunityid || ""} name="opportunityid"
                sx={{ gridColumn: "span 1" }} />
            {/*orderid*/} 
            <TextField disabled fullWidth type="text" label="Order ID" value={displayOrderData.orderid || ""} name="orderid"
                sx={{ gridColumn: "span 1" }} />
</Box>
    )
}


export function displayorderinformation_hardware_setone(displayOrderData, hardwareFields,isNonMobile)
{
    const components = [];

    for (let index = 0; index < hardwareFields.length; index++)
    {
        components.push(<TextField disabled fullWidth type="number" label={hardwareFields[index].replace(/_/g, " ")}   value={displayOrderData[hardwareFields[index]] || ""} name={hardwareFields[index]}
            sx={{ gridColumn: "span 1" }}/>);
    }
    
    return(<Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
        {components}
        
    </Box>
    );
}

export function displayorderinformation_hardware_settwo(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            <TextField disabled fullWidth type="number" label="Hardware Warranty"  value={displayOrderData.additional_warranty || ""} name="additional_warranty"
                sx={{ gridColumn: "span 1" }}/>
        </Box>
    );
}

export function displayorderinformation_hardware_setthree(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            <TextField disabled multiline fullWidth type="text" label="Additional Details"  value={displayOrderData.addtional_details || ""} name="addtional_details"
                sx={{ gridColumn: "span 4" }}/>
        </Box>
    );
}

export function displayorderinformation_software_setone(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Number of Items*/} 
            <TextField disabled fullWidth type="number" label="Number of Items"   value={displayOrderData.items || ""} name="items"
            sx={{ gridColumn: "span 1" }}/>
            {/*Ramp*/} 
            <FormControl fullWidth>
                <InputLabel id="ramp">Ramp</InputLabel>
                <Select disabled labelId="ramp" name="ramp" label="ramp" value={displayOrderData.ramp || "0"} sx={{ gridColumn: "span 1" }}>
                            <MenuItem value={"0"}>No</MenuItem>
                            <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
export function displayorderinformation_software_settwo(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*OTP*/} 
            <TextField disabled fullWidth type="text" label="OTP"   value={displayOrderData.otp || ""} name="otp"
             sx={{ gridColumn: "span 1" }}/>
            {/* End Date */}
            <DatePicker disabled 
                fullWidth label="End Date" defaultValue={dayjs(displayOrderData.end_date) || dayjs()}  name="end_date"
                sx={{ gridColumn: "span 1" }} />
            {/*Ramp*/} 
            <FormControl fullWidth>
                <InputLabel id="SaaS">SaaS</InputLabel>
                <Select disabled labelId="SaaS" name="SaaS" label="SaaS" value={displayOrderData.SaaS || "0"}  sx={{ gridColumn: "span 1" }}>
                            <MenuItem value={"0"}>No</MenuItem>
                            <MenuItem value={"1"}>Yes</MenuItem>
                </Select>
            </FormControl>
            {displayOrderData.SaaS &&
                <TextField disabled fullWidth type="text" label="Domain Name"   value={displayOrderData.domain_name || ""} name="domain_name"
                 sx={{ gridColumn: "span 1" }}/>
            }
        </Box> 
    )
}
export function displayorderinformation_software_setthree(displayOrderData, isNonMobile)
{
    return(
        <Box display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/*Additional Details*/}
            <TextField disabled fullWidth multiline type="text" label="Additional Details" value={displayOrderData.additional_details || ""} name="additional_details"
             sx={{ gridColumn: "span 4" }} />
        </Box>
    )
}
export function displayorderinformation_software_setfour(displayOrderData, isNonMobile)
{

    const components = [];

    for(let counter = 1; counter <= displayOrderData.items; counter++ )
    {
        components.push(
            <Box key={counter} display="grid" margin="30px" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 4fr))" alignItems="center" sx={{"& > div": { gridColumn: isNonMobile ? undefined : "span 4" },}}>
            {/* OTP */}
            <TextField disabled fullWidth type="text" label={`Item ${counter}`} value={displayOrderData[`item_${counter}`] || ""} name={`item_${counter}`} sx={{ gridColumn: "span 2" }}/>
            <TextField disabled fullWidth type="number" label={`Quantity ${counter}`} value={displayOrderData[`quantity_${counter}`] || ""} name={`quantity_${counter}`} sx={{ gridColumn: "span 1" }}/>
            <TextField disabled fullWidth type="number" label={`Term ${counter}`} value={displayOrderData[`term_${counter}`] || ""} name={`term_${counter}`} sx={{ gridColumn: "span 1" }}/>
            {displayOrderData.ramp &&
                <DatePicker disabled fullWidth label={`Start Date ${counter}`} defaultValue={dayjs(displayOrderData[`start_date_${counter}`]) || dayjs()} name={`start_date_${counter}`} 
                sx={{ gridColumn: "span 1" }} />
            }
            </Box>

        )
    }

    return components;

}