export const hexdata = [
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T18:44:43+00:00",
    "datetime": "2024-05-03T18:44:43+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4154,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 151,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 49,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T17:44:32+00:00",
    "datetime": "2024-05-03T17:44:32+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4199,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T16:44:23+00:00",
    "datetime": "2024-05-03T16:44:23+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4173,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 153,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 49,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T15:44:13+00:00",
    "datetime": "2024-05-03T15:44:13+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4191,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 147,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T14:43:43+00:00",
    "datetime": "2024-05-03T14:43:43+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4197,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 130,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T13:43:33+00:00",
    "datetime": "2024-05-03T13:43:33+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4217,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 139,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 47,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T12:43:23+00:00",
    "datetime": "2024-05-03T12:43:23+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4217,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T11:43:13+00:00",
    "datetime": "2024-05-03T11:43:13+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4190,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 126,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 41,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T10:43:04+00:00",
    "datetime": "2024-05-03T10:43:04+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4208,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 130,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T09:42:55+00:00",
    "datetime": "2024-05-03T09:42:55+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4196,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 131,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T08:42:42+00:00",
    "datetime": "2024-05-03T08:42:42+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4180,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T07:42:34+00:00",
    "datetime": "2024-05-03T07:42:34+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4199,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 131,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T06:42:25+00:00",
    "datetime": "2024-05-03T06:42:25+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4175,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 131,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T05:42:16+00:00",
    "datetime": "2024-05-03T05:42:16+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4206,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 133,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T04:42:07+00:00",
    "datetime": "2024-05-03T04:42:07+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4212,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 136,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T03:41:58+00:00",
    "datetime": "2024-05-03T03:41:58+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4231,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 133,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T02:41:51+00:00",
    "datetime": "2024-05-03T02:41:51+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4241,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 42,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-03T01:41:43+00:00",
    "datetime": "2024-05-03T01:41:43+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4229,
    "throughput": 85.79499603424662,
    "version": "",
    "license_usage": 2261,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 42,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T23:41:25+00:00",
    "datetime": "2024-05-02T23:41:25+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4253,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 41,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T22:41:16+00:00",
    "datetime": "2024-05-02T22:41:16+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4242,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 129,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 37,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T21:41:06+00:00",
    "datetime": "2024-05-02T21:41:06+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4238,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 128,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 36,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T20:40:56+00:00",
    "datetime": "2024-05-02T20:40:56+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4233,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 120,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 32,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T19:40:46+00:00",
    "datetime": "2024-05-02T19:40:46+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4229,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 124,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 33,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T18:40:36+00:00",
    "datetime": "2024-05-02T18:40:36+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4230,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 128,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 35,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T17:40:26+00:00",
    "datetime": "2024-05-02T17:40:26+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4192,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 39,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T16:40:06+00:00",
    "datetime": "2024-05-02T16:40:06+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4180,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 134,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 36,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T15:39:52+00:00",
    "datetime": "2024-05-02T15:39:52+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4145,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 143,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 40,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T14:39:28+00:00",
    "datetime": "2024-05-02T14:39:28+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4117,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 154,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T13:39:19+00:00",
    "datetime": "2024-05-02T13:39:19+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4102,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 150,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T12:39:08+00:00",
    "datetime": "2024-05-02T12:39:08+00:00",
    "num_tenants": 7,
    "users": 46,
    "assets": 4064,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 153,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 43,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T11:38:59+00:00",
    "datetime": "2024-05-02T11:38:59+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 4045,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 153,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 41,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T10:38:50+00:00",
    "datetime": "2024-05-02T10:38:50+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 4005,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T09:38:42+00:00",
    "datetime": "2024-05-02T09:38:42+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 4000,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T08:38:27+00:00",
    "datetime": "2024-05-02T08:38:27+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3985,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T07:38:17+00:00",
    "datetime": "2024-05-02T07:38:17+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3975,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 161,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 43,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T06:38:08+00:00",
    "datetime": "2024-05-02T06:38:08+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3922,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 163,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T05:37:58+00:00",
    "datetime": "2024-05-02T05:37:58+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3841,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 161,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T04:37:48+00:00",
    "datetime": "2024-05-02T04:37:48+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3801,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 168,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T03:37:39+00:00",
    "datetime": "2024-05-02T03:37:39+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3793,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 167,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T02:37:29+00:00",
    "datetime": "2024-05-02T02:37:29+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3776,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 170,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 49,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-02T01:37:20+00:00",
    "datetime": "2024-05-02T01:37:20+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3775,
    "throughput": 86.87250742514614,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 172,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T23:36:59+00:00",
    "datetime": "2024-05-01T23:36:59+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3779,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 174,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T22:36:48+00:00",
    "datetime": "2024-05-01T22:36:48+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3775,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 175,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T21:36:37+00:00",
    "datetime": "2024-05-01T21:36:37+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3798,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T20:36:28+00:00",
    "datetime": "2024-05-01T20:36:28+00:00",
    "num_tenants": 7,
    "users": 49,
    "assets": 3814,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 187,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T19:36:18+00:00",
    "datetime": "2024-05-01T19:36:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3846,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 181,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T18:36:08+00:00",
    "datetime": "2024-05-01T18:36:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3850,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 185,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T17:35:58+00:00",
    "datetime": "2024-05-01T17:35:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3858,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T16:35:49+00:00",
    "datetime": "2024-05-01T16:35:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3189,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 182,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T15:35:39+00:00",
    "datetime": "2024-05-01T15:35:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3918,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 164,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T14:35:11+00:00",
    "datetime": "2024-05-01T14:35:11+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3925,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 164,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T13:34:57+00:00",
    "datetime": "2024-05-01T13:34:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3942,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 165,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T12:34:46+00:00",
    "datetime": "2024-05-01T12:34:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3939,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 194,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 74,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T11:34:36+00:00",
    "datetime": "2024-05-01T11:34:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3955,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 194,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 76,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T10:34:20+00:00",
    "datetime": "2024-05-01T10:34:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3974,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 203,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 80,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T09:34:12+00:00",
    "datetime": "2024-05-01T09:34:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3965,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 202,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 82,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T08:33:58+00:00",
    "datetime": "2024-05-01T08:33:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3981,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 202,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 85,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T07:33:50+00:00",
    "datetime": "2024-05-01T07:33:50+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3989,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 205,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 87,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T06:33:42+00:00",
    "datetime": "2024-05-01T06:33:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4023,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 210,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 92,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T05:33:26+00:00",
    "datetime": "2024-05-01T05:33:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4076,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 207,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 92,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T04:33:16+00:00",
    "datetime": "2024-05-01T04:33:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4094,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 214,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 96,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T03:33:07+00:00",
    "datetime": "2024-05-01T03:33:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4102,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 214,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 97,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T02:32:57+00:00",
    "datetime": "2024-05-01T02:32:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4103,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 217,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 103,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-05-01T01:32:47+00:00",
    "datetime": "2024-05-01T01:32:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4097,
    "throughput": 87.48261429016347,
    "version": "",
    "license_usage": 2201,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 220,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 106,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T23:32:28+00:00",
    "datetime": "2024-04-30T23:32:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4109,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 223,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 111,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T22:32:18+00:00",
    "datetime": "2024-04-30T22:32:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4122,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 222,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T21:32:09+00:00",
    "datetime": "2024-04-30T21:32:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4133,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 224,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 119,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T20:31:59+00:00",
    "datetime": "2024-04-30T20:31:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4113,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 127,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T19:31:49+00:00",
    "datetime": "2024-04-30T19:31:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4096,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 130,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T18:31:39+00:00",
    "datetime": "2024-04-30T18:31:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4109,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 230,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 129,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T17:31:30+00:00",
    "datetime": "2024-04-30T17:31:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4108,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 242,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 132,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T16:31:21+00:00",
    "datetime": "2024-04-30T16:31:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3106,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 241,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 128,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T15:31:10+00:00",
    "datetime": "2024-04-30T15:31:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4096,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 123,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T14:30:48+00:00",
    "datetime": "2024-04-30T14:30:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4076,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 244,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 128,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T13:30:38+00:00",
    "datetime": "2024-04-30T13:30:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4092,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 237,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T12:30:27+00:00",
    "datetime": "2024-04-30T12:30:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4114,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 229,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T11:30:05+00:00",
    "datetime": "2024-04-30T11:30:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4135,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 235,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 120,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T10:29:52+00:00",
    "datetime": "2024-04-30T10:29:52+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4154,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 134,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T09:29:43+00:00",
    "datetime": "2024-04-30T09:29:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4176,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 134,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T08:29:27+00:00",
    "datetime": "2024-04-30T08:29:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4187,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 134,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T07:29:17+00:00",
    "datetime": "2024-04-30T07:29:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 296,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 131,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T06:29:06+00:00",
    "datetime": "2024-04-30T06:29:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4249,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 295,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 132,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T05:28:56+00:00",
    "datetime": "2024-04-30T05:28:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4255,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 135,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T04:28:46+00:00",
    "datetime": "2024-04-30T04:28:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4270,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T03:28:35+00:00",
    "datetime": "2024-04-30T03:28:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4282,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 139,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T02:28:25+00:00",
    "datetime": "2024-04-30T02:28:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4280,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 304,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 140,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-30T01:28:15+00:00",
    "datetime": "2024-04-30T01:28:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4285,
    "throughput": 84.28083597675985,
    "version": "",
    "license_usage": 2185,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 140,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T23:27:55+00:00",
    "datetime": "2024-04-29T23:27:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4258,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 307,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 142,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T22:27:45+00:00",
    "datetime": "2024-04-29T22:27:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4240,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 307,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 145,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T21:27:34+00:00",
    "datetime": "2024-04-29T21:27:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4210,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 143,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T20:27:25+00:00",
    "datetime": "2024-04-29T20:27:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4184,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 292,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 144,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T19:27:15+00:00",
    "datetime": "2024-04-29T19:27:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4166,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 141,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T18:27:06+00:00",
    "datetime": "2024-04-29T18:27:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4118,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 277,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 141,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T17:26:56+00:00",
    "datetime": "2024-04-29T17:26:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4067,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 273,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 142,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T16:26:47+00:00",
    "datetime": "2024-04-29T16:26:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3979,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 267,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 143,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T15:26:37+00:00",
    "datetime": "2024-04-29T15:26:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3951,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 256,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 139,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T14:26:14+00:00",
    "datetime": "2024-04-29T14:26:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3821,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 248,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T13:26:04+00:00",
    "datetime": "2024-04-29T13:26:04+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3697,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 231,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 131,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T12:25:55+00:00",
    "datetime": "2024-04-29T12:25:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3575,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 224,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 127,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T11:25:47+00:00",
    "datetime": "2024-04-29T11:25:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3445,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 210,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 127,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T10:25:39+00:00",
    "datetime": "2024-04-29T10:25:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3379,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 114,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T09:25:31+00:00",
    "datetime": "2024-04-29T09:25:31+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3340,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 114,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T08:25:07+00:00",
    "datetime": "2024-04-29T08:25:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3310,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 161,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T07:24:56+00:00",
    "datetime": "2024-04-29T07:24:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3238,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 160,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T06:24:47+00:00",
    "datetime": "2024-04-29T06:24:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3139,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 114,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T05:24:37+00:00",
    "datetime": "2024-04-29T05:24:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3075,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T04:24:27+00:00",
    "datetime": "2024-04-29T04:24:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3028,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 112,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T03:24:18+00:00",
    "datetime": "2024-04-29T03:24:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3010,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 148,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 108,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T02:24:10+00:00",
    "datetime": "2024-04-29T02:24:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3000,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 148,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 109,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-29T01:24:02+00:00",
    "datetime": "2024-04-29T01:24:02+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2959,
    "throughput": 75.7910593112049,
    "version": "",
    "license_usage": 1282,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 153,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 111,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T23:23:43+00:00",
    "datetime": "2024-04-28T23:23:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2949,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 150,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 110,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T22:23:34+00:00",
    "datetime": "2024-04-28T22:23:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2952,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 148,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 108,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T21:23:24+00:00",
    "datetime": "2024-04-28T21:23:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2943,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 142,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 101,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T20:23:14+00:00",
    "datetime": "2024-04-28T20:23:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2927,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 144,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 103,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T19:23:04+00:00",
    "datetime": "2024-04-28T19:23:04+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2911,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 148,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 107,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T18:22:54+00:00",
    "datetime": "2024-04-28T18:22:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2908,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 151,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 109,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T17:22:39+00:00",
    "datetime": "2024-04-28T17:22:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2904,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 113,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T16:22:30+00:00",
    "datetime": "2024-04-28T16:22:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2894,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 113,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T15:22:20+00:00",
    "datetime": "2024-04-28T15:22:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2898,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 111,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T14:21:58+00:00",
    "datetime": "2024-04-28T14:21:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2927,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 165,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 120,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T13:21:48+00:00",
    "datetime": "2024-04-28T13:21:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2923,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 164,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 121,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T12:21:38+00:00",
    "datetime": "2024-04-28T12:21:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2919,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 158,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T11:21:29+00:00",
    "datetime": "2024-04-28T11:21:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2913,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 114,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T10:21:21+00:00",
    "datetime": "2024-04-28T10:21:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2904,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 163,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 118,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T09:21:12+00:00",
    "datetime": "2024-04-28T09:21:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2896,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 164,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T08:20:57+00:00",
    "datetime": "2024-04-28T08:20:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2895,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 162,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T07:20:47+00:00",
    "datetime": "2024-04-28T07:20:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2897,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 166,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 119,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T06:20:37+00:00",
    "datetime": "2024-04-28T06:20:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2899,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 168,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T05:20:26+00:00",
    "datetime": "2024-04-28T05:20:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2905,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 169,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 119,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T04:20:08+00:00",
    "datetime": "2024-04-28T04:20:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2897,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 168,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T03:19:56+00:00",
    "datetime": "2024-04-28T03:19:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2920,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 169,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T02:19:46+00:00",
    "datetime": "2024-04-28T02:19:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2925,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 171,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 121,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-28T01:19:36+00:00",
    "datetime": "2024-04-28T01:19:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2944,
    "throughput": 77.06776677494271,
    "version": "",
    "license_usage": 1393,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 170,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 119,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T23:19:16+00:00",
    "datetime": "2024-04-27T23:19:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2984,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 171,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 120,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T22:19:06+00:00",
    "datetime": "2024-04-27T22:19:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3001,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 178,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 125,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T21:18:56+00:00",
    "datetime": "2024-04-27T21:18:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3069,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 125,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T20:18:47+00:00",
    "datetime": "2024-04-27T20:18:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3161,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 182,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 120,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T19:18:36+00:00",
    "datetime": "2024-04-27T19:18:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3206,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 187,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 118,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T18:18:26+00:00",
    "datetime": "2024-04-27T18:18:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3241,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 191,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 118,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T17:18:16+00:00",
    "datetime": "2024-04-27T17:18:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3283,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 189,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 120,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T16:18:07+00:00",
    "datetime": "2024-04-27T16:18:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3345,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 201,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 128,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T15:17:56+00:00",
    "datetime": "2024-04-27T15:17:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3403,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 213,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 129,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T14:17:35+00:00",
    "datetime": "2024-04-27T14:17:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3476,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 226,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T13:17:26+00:00",
    "datetime": "2024-04-27T13:17:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3569,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 247,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 144,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T12:17:16+00:00",
    "datetime": "2024-04-27T12:17:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3631,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 259,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 149,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T11:17:07+00:00",
    "datetime": "2024-04-27T11:17:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3726,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 265,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 151,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T10:16:58+00:00",
    "datetime": "2024-04-27T10:16:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3778,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 262,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 153,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T09:16:50+00:00",
    "datetime": "2024-04-27T09:16:50+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3823,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 261,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 151,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T08:16:36+00:00",
    "datetime": "2024-04-27T08:16:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3866,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 258,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T07:16:24+00:00",
    "datetime": "2024-04-27T07:16:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3964,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 259,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 153,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T06:16:15+00:00",
    "datetime": "2024-04-27T06:16:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4101,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 256,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 150,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T05:16:05+00:00",
    "datetime": "2024-04-27T05:16:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4167,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 252,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 149,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T04:15:56+00:00",
    "datetime": "2024-04-27T04:15:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4201,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 261,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 154,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T03:15:46+00:00",
    "datetime": "2024-04-27T03:15:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4183,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 261,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 154,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T02:15:36+00:00",
    "datetime": "2024-04-27T02:15:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4205,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 264,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 158,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-27T01:15:27+00:00",
    "datetime": "2024-04-27T01:15:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4232,
    "throughput": 88.01400712191138,
    "version": "",
    "license_usage": 2113,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 273,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T23:14:59+00:00",
    "datetime": "2024-04-26T23:14:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4267,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 274,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T22:14:49+00:00",
    "datetime": "2024-04-26T22:14:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4302,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 271,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 164,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T21:14:40+00:00",
    "datetime": "2024-04-26T21:14:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4314,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 271,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T20:14:30+00:00",
    "datetime": "2024-04-26T20:14:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4341,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 275,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 167,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T19:14:20+00:00",
    "datetime": "2024-04-26T19:14:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4342,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 279,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 174,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T18:14:09+00:00",
    "datetime": "2024-04-26T18:14:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4360,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 283,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 170,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T17:13:59+00:00",
    "datetime": "2024-04-26T17:13:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4390,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 289,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 172,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T16:13:50+00:00",
    "datetime": "2024-04-26T16:13:50+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4382,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 284,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T15:13:40+00:00",
    "datetime": "2024-04-26T15:13:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4385,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 281,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T14:13:17+00:00",
    "datetime": "2024-04-26T14:13:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4378,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 278,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 155,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T13:13:07+00:00",
    "datetime": "2024-04-26T13:13:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4354,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T12:12:56+00:00",
    "datetime": "2024-04-26T12:12:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4348,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 291,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 156,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T11:12:46+00:00",
    "datetime": "2024-04-26T11:12:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4315,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 298,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 158,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T10:12:36+00:00",
    "datetime": "2024-04-26T10:12:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4327,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 160,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T09:12:27+00:00",
    "datetime": "2024-04-26T09:12:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4324,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T08:12:15+00:00",
    "datetime": "2024-04-26T08:12:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4320,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T07:12:05+00:00",
    "datetime": "2024-04-26T07:12:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4297,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 296,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 160,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T06:11:56+00:00",
    "datetime": "2024-04-26T06:11:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4222,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 162,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T05:11:46+00:00",
    "datetime": "2024-04-26T05:11:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4215,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 168,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T04:11:36+00:00",
    "datetime": "2024-04-26T04:11:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4219,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T03:11:27+00:00",
    "datetime": "2024-04-26T03:11:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4223,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 298,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 162,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T02:11:18+00:00",
    "datetime": "2024-04-26T02:11:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4231,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 289,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 158,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-26T01:11:08+00:00",
    "datetime": "2024-04-26T01:11:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4234,
    "throughput": 88.34298804418087,
    "version": "",
    "license_usage": 2213,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 288,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 153,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T23:10:49+00:00",
    "datetime": "2024-04-25T23:10:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4275,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T22:10:39+00:00",
    "datetime": "2024-04-25T22:10:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4288,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 287,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 153,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T21:10:30+00:00",
    "datetime": "2024-04-25T21:10:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4300,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 280,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 154,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T20:10:21+00:00",
    "datetime": "2024-04-25T20:10:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4284,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 287,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T19:10:00+00:00",
    "datetime": "2024-04-25T19:10:00+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4296,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 286,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 156,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T18:09:49+00:00",
    "datetime": "2024-04-25T18:09:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4294,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T17:09:40+00:00",
    "datetime": "2024-04-25T17:09:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4307,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T16:09:30+00:00",
    "datetime": "2024-04-25T16:09:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4300,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 288,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T15:09:19+00:00",
    "datetime": "2024-04-25T15:09:19+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4311,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T14:08:59+00:00",
    "datetime": "2024-04-25T14:08:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4292,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 290,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 162,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T13:08:48+00:00",
    "datetime": "2024-04-25T13:08:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4323,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 282,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T12:08:38+00:00",
    "datetime": "2024-04-25T12:08:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4323,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T11:08:30+00:00",
    "datetime": "2024-04-25T11:08:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4338,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 164,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T10:08:21+00:00",
    "datetime": "2024-04-25T10:08:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4310,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 286,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 162,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T09:08:12+00:00",
    "datetime": "2024-04-25T09:08:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4306,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 286,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T08:07:57+00:00",
    "datetime": "2024-04-25T08:07:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4303,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 289,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T07:07:47+00:00",
    "datetime": "2024-04-25T07:07:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4274,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 291,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 168,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T06:07:37+00:00",
    "datetime": "2024-04-25T06:07:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4287,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T05:07:27+00:00",
    "datetime": "2024-04-25T05:07:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4319,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T04:07:18+00:00",
    "datetime": "2024-04-25T04:07:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4329,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 289,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T03:07:08+00:00",
    "datetime": "2024-04-25T03:07:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4339,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T02:06:58+00:00",
    "datetime": "2024-04-25T02:06:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4346,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 292,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 168,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-25T01:06:48+00:00",
    "datetime": "2024-04-25T01:06:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4353,
    "throughput": 85.51744667272776,
    "version": "",
    "license_usage": 2188,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 174,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T23:06:30+00:00",
    "datetime": "2024-04-24T23:06:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4394,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 305,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 175,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T22:06:19+00:00",
    "datetime": "2024-04-24T22:06:19+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4408,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 176,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T21:06:09+00:00",
    "datetime": "2024-04-24T21:06:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4382,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 308,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 176,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T20:05:59+00:00",
    "datetime": "2024-04-24T20:05:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4405,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 311,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 173,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T19:05:48+00:00",
    "datetime": "2024-04-24T19:05:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4406,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 174,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T18:05:37+00:00",
    "datetime": "2024-04-24T18:05:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4410,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 296,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 173,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T17:05:27+00:00",
    "datetime": "2024-04-24T17:05:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4394,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 179,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T16:05:10+00:00",
    "datetime": "2024-04-24T16:05:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4401,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 291,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 181,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T15:04:56+00:00",
    "datetime": "2024-04-24T15:04:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4372,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 183,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T14:04:36+00:00",
    "datetime": "2024-04-24T14:04:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4384,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 313,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 192,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T13:04:26+00:00",
    "datetime": "2024-04-24T13:04:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4362,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 304,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 183,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T12:04:16+00:00",
    "datetime": "2024-04-24T12:04:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4356,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 309,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 184,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T11:04:06+00:00",
    "datetime": "2024-04-24T11:04:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4327,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 308,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 181,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T10:03:56+00:00",
    "datetime": "2024-04-24T10:03:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4326,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 305,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 181,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T09:03:45+00:00",
    "datetime": "2024-04-24T09:03:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4345,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 305,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 180,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T08:03:28+00:00",
    "datetime": "2024-04-24T08:03:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4325,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 176,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T07:03:18+00:00",
    "datetime": "2024-04-24T07:03:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4300,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 178,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T06:03:07+00:00",
    "datetime": "2024-04-24T06:03:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4280,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 304,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 179,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T05:02:58+00:00",
    "datetime": "2024-04-24T05:02:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4262,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 306,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 182,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T04:02:48+00:00",
    "datetime": "2024-04-24T04:02:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4270,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 307,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 184,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T03:02:39+00:00",
    "datetime": "2024-04-24T03:02:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4254,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 307,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 183,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T02:02:29+00:00",
    "datetime": "2024-04-24T02:02:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4247,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 308,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 185,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-24T01:02:20+00:00",
    "datetime": "2024-04-24T01:02:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4191,
    "throughput": 88.66589427639887,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 306,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 184,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T23:02:00+00:00",
    "datetime": "2024-04-23T23:02:00+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 305,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 184,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T22:01:51+00:00",
    "datetime": "2024-04-23T22:01:51+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4203,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 304,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 187,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T21:01:41+00:00",
    "datetime": "2024-04-23T21:01:41+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4217,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 186,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T20:01:30+00:00",
    "datetime": "2024-04-23T20:01:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4221,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 306,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 189,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T19:01:20+00:00",
    "datetime": "2024-04-23T19:01:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4214,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 308,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 191,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T18:01:10+00:00",
    "datetime": "2024-04-23T18:01:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4225,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 190,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T17:01:00+00:00",
    "datetime": "2024-04-23T17:01:00+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4217,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 298,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 191,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T16:00:51+00:00",
    "datetime": "2024-04-23T16:00:51+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4214,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 188,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T15:00:41+00:00",
    "datetime": "2024-04-23T15:00:41+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4219,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 194,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T14:00:07+00:00",
    "datetime": "2024-04-23T14:00:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4198,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 298,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 193,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T12:59:54+00:00",
    "datetime": "2024-04-23T12:59:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4231,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 199,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T11:59:45+00:00",
    "datetime": "2024-04-23T11:59:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4239,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 309,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 201,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T10:59:36+00:00",
    "datetime": "2024-04-23T10:59:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4295,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 396,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 222,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T09:59:26+00:00",
    "datetime": "2024-04-23T09:59:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4300,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 399,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 225,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T08:59:17+00:00",
    "datetime": "2024-04-23T08:59:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4269,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 396,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 223,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T07:59:03+00:00",
    "datetime": "2024-04-23T07:59:03+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4252,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 397,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 223,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T06:58:54+00:00",
    "datetime": "2024-04-23T06:58:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4277,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 400,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 224,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T05:58:45+00:00",
    "datetime": "2024-04-23T05:58:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4315,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 400,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 226,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T04:58:35+00:00",
    "datetime": "2024-04-23T04:58:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4316,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 403,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 231,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T03:58:26+00:00",
    "datetime": "2024-04-23T03:58:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4317,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 405,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 232,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T02:58:17+00:00",
    "datetime": "2024-04-23T02:58:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4321,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 413,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 240,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T01:58:06+00:00",
    "datetime": "2024-04-23T01:58:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4325,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 410,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 238,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-23T00:57:57+00:00",
    "datetime": "2024-04-23T00:57:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4321,
    "throughput": 87.79918686379219,
    "version": "",
    "license_usage": 2096,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 414,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 239,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T23:57:47+00:00",
    "datetime": "2024-04-22T23:57:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4320,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 415,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 237,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T22:57:37+00:00",
    "datetime": "2024-04-22T22:57:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4311,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 416,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 238,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T21:57:28+00:00",
    "datetime": "2024-04-22T21:57:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4294,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 421,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 243,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T20:57:18+00:00",
    "datetime": "2024-04-22T20:57:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4263,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 412,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 238,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T19:57:09+00:00",
    "datetime": "2024-04-22T19:57:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4213,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 392,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 227,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T18:56:59+00:00",
    "datetime": "2024-04-22T18:56:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4182,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 382,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 221,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T17:56:49+00:00",
    "datetime": "2024-04-22T17:56:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4155,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 375,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 215,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T16:56:39+00:00",
    "datetime": "2024-04-22T16:56:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4091,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 367,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 210,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T15:56:29+00:00",
    "datetime": "2024-04-22T15:56:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4015,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 350,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 201,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T14:56:19+00:00",
    "datetime": "2024-04-22T14:56:19+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3893,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 333,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 195,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T13:55:59+00:00",
    "datetime": "2024-04-22T13:55:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3781,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 319,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 194,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T12:55:49+00:00",
    "datetime": "2024-04-22T12:55:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3632,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 186,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T11:55:41+00:00",
    "datetime": "2024-04-22T11:55:41+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3516,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 188,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T10:55:33+00:00",
    "datetime": "2024-04-22T10:55:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3422,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 206,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T09:55:25+00:00",
    "datetime": "2024-04-22T09:55:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3386,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T08:55:01+00:00",
    "datetime": "2024-04-22T08:55:01+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3381,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 210,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T07:54:47+00:00",
    "datetime": "2024-04-22T07:54:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3343,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 168,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T06:54:39+00:00",
    "datetime": "2024-04-22T06:54:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3245,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 208,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T05:54:31+00:00",
    "datetime": "2024-04-22T05:54:31+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3160,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 203,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 159,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T04:54:22+00:00",
    "datetime": "2024-04-22T04:54:22+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3114,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 198,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 154,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T03:54:13+00:00",
    "datetime": "2024-04-22T03:54:13+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3086,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 143,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T02:54:04+00:00",
    "datetime": "2024-04-22T02:54:04+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3065,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 187,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 146,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T01:53:53+00:00",
    "datetime": "2024-04-22T01:53:53+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3050,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 146,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-22T00:53:44+00:00",
    "datetime": "2024-04-22T00:53:44+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2969,
    "throughput": 74.55930409604171,
    "version": "",
    "license_usage": 1226,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 145,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T23:53:35+00:00",
    "datetime": "2024-04-21T23:53:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2975,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 184,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T22:53:26+00:00",
    "datetime": "2024-04-21T22:53:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2956,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 179,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 145,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T21:53:16+00:00",
    "datetime": "2024-04-21T21:53:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2964,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 177,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 144,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T20:53:06+00:00",
    "datetime": "2024-04-21T20:53:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2969,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T19:52:56+00:00",
    "datetime": "2024-04-21T19:52:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2974,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 181,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 147,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T18:52:47+00:00",
    "datetime": "2024-04-21T18:52:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2956,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 182,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 149,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T17:52:38+00:00",
    "datetime": "2024-04-21T17:52:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2952,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 188,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T16:52:29+00:00",
    "datetime": "2024-04-21T16:52:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2945,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 191,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T15:52:19+00:00",
    "datetime": "2024-04-21T15:52:19+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2943,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 198,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 164,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T14:52:09+00:00",
    "datetime": "2024-04-21T14:52:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2972,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 202,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 164,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T13:51:40+00:00",
    "datetime": "2024-04-21T13:51:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2977,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 199,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 162,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T12:51:32+00:00",
    "datetime": "2024-04-21T12:51:32+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2972,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 213,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 174,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T11:51:23+00:00",
    "datetime": "2024-04-21T11:51:23+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2959,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 171,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T10:51:14+00:00",
    "datetime": "2024-04-21T10:51:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2963,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 173,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T09:51:05+00:00",
    "datetime": "2024-04-21T09:51:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2952,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 209,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 171,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T08:50:56+00:00",
    "datetime": "2024-04-21T08:50:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2950,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 210,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 168,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T07:50:43+00:00",
    "datetime": "2024-04-21T07:50:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2936,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 204,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T06:50:34+00:00",
    "datetime": "2024-04-21T06:50:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2942,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 206,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T05:50:25+00:00",
    "datetime": "2024-04-21T05:50:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2937,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 204,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 163,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T04:50:07+00:00",
    "datetime": "2024-04-21T04:50:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2952,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 205,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T03:49:56+00:00",
    "datetime": "2024-04-21T03:49:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2954,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 246,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 159,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T02:49:49+00:00",
    "datetime": "2024-04-21T02:49:49+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2973,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 250,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T01:49:41+00:00",
    "datetime": "2024-04-21T01:49:41+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2989,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 245,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 160,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-21T00:49:32+00:00",
    "datetime": "2024-04-21T00:49:32+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3003,
    "throughput": 77.29128305905986,
    "version": "",
    "license_usage": 1375,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 239,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 153,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T23:49:24+00:00",
    "datetime": "2024-04-20T23:49:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3012,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 147,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T22:49:15+00:00",
    "datetime": "2024-04-20T22:49:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3053,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 236,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T21:49:05+00:00",
    "datetime": "2024-04-20T21:49:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3079,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 232,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 144,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T20:48:57+00:00",
    "datetime": "2024-04-20T20:48:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3117,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 228,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T19:48:48+00:00",
    "datetime": "2024-04-20T19:48:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3178,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 230,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T18:48:39+00:00",
    "datetime": "2024-04-20T18:48:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3224,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 231,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 132,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T17:48:30+00:00",
    "datetime": "2024-04-20T17:48:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3275,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 239,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 126,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T16:48:22+00:00",
    "datetime": "2024-04-20T16:48:22+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3338,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 242,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 128,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T15:48:13+00:00",
    "datetime": "2024-04-20T15:48:13+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3414,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 252,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 130,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T14:48:03+00:00",
    "datetime": "2024-04-20T14:48:03+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3458,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 258,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 133,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T13:47:43+00:00",
    "datetime": "2024-04-20T13:47:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3529,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 270,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 127,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T12:47:34+00:00",
    "datetime": "2024-04-20T12:47:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3627,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 279,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 128,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T11:47:25+00:00",
    "datetime": "2024-04-20T11:47:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3713,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 287,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 131,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T10:47:15+00:00",
    "datetime": "2024-04-20T10:47:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3748,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 290,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 134,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T09:46:59+00:00",
    "datetime": "2024-04-20T09:46:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3799,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 141,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T08:46:44+00:00",
    "datetime": "2024-04-20T08:46:44+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3842,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 145,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T07:46:31+00:00",
    "datetime": "2024-04-20T07:46:31+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3906,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 298,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 145,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T06:46:20+00:00",
    "datetime": "2024-04-20T06:46:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4038,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 141,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T05:46:11+00:00",
    "datetime": "2024-04-20T05:46:11+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4132,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 303,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 147,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T04:46:03+00:00",
    "datetime": "2024-04-20T04:46:03+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4145,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 316,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 155,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T03:45:53+00:00",
    "datetime": "2024-04-20T03:45:53+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4170,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 272,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 158,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T02:45:44+00:00",
    "datetime": "2024-04-20T02:45:44+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4190,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 272,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 159,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-20T01:45:34+00:00",
    "datetime": "2024-04-20T01:45:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 82.60990075388332,
    "version": "",
    "license_usage": 2108,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 274,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 160,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T23:45:08+00:00",
    "datetime": "2024-04-19T23:45:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4247,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 290,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 171,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T22:44:57+00:00",
    "datetime": "2024-04-19T22:44:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4256,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 297,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 178,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T21:44:48+00:00",
    "datetime": "2024-04-19T21:44:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4268,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 309,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 190,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T20:44:38+00:00",
    "datetime": "2024-04-19T20:44:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4299,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 313,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 193,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T19:44:28+00:00",
    "datetime": "2024-04-19T19:44:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4320,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 330,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 207,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T18:44:18+00:00",
    "datetime": "2024-04-19T18:44:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4322,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 337,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 214,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T17:44:09+00:00",
    "datetime": "2024-04-19T17:44:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4338,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 339,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 217,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T16:43:59+00:00",
    "datetime": "2024-04-19T16:43:59+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4347,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 354,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 226,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T15:43:48+00:00",
    "datetime": "2024-04-19T15:43:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4341,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 355,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 224,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T14:43:38+00:00",
    "datetime": "2024-04-19T14:43:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4354,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 361,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 224,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T13:43:17+00:00",
    "datetime": "2024-04-19T13:43:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4340,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 359,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 233,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T12:43:06+00:00",
    "datetime": "2024-04-19T12:43:06+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4325,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 359,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 237,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T11:42:56+00:00",
    "datetime": "2024-04-19T11:42:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4315,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 361,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 235,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T10:42:46+00:00",
    "datetime": "2024-04-19T10:42:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4305,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 362,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 235,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T09:42:36+00:00",
    "datetime": "2024-04-19T09:42:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4300,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 362,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 232,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T08:42:27+00:00",
    "datetime": "2024-04-19T08:42:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4283,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 369,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 239,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T07:42:03+00:00",
    "datetime": "2024-04-19T07:42:03+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4245,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 364,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 236,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T06:41:54+00:00",
    "datetime": "2024-04-19T06:41:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4210,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 366,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 236,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T05:41:46+00:00",
    "datetime": "2024-04-19T05:41:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4204,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 363,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 236,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T04:41:37+00:00",
    "datetime": "2024-04-19T04:41:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 356,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 230,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T03:41:29+00:00",
    "datetime": "2024-04-19T03:41:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 362,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 236,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T02:41:21+00:00",
    "datetime": "2024-04-19T02:41:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4186,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 358,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 231,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-19T01:41:12+00:00",
    "datetime": "2024-04-19T01:41:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4177,
    "throughput": 84.60591736169152,
    "version": "",
    "license_usage": 2139,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 363,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 236,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T23:40:54+00:00",
    "datetime": "2024-04-18T23:40:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4171,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 354,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 227,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T22:40:45+00:00",
    "datetime": "2024-04-18T22:40:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4173,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 356,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 225,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T21:40:36+00:00",
    "datetime": "2024-04-18T21:40:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4190,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 360,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 226,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T20:40:26+00:00",
    "datetime": "2024-04-18T20:40:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4197,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 366,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 228,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T19:40:10+00:00",
    "datetime": "2024-04-18T19:40:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4217,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 364,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 228,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T18:39:57+00:00",
    "datetime": "2024-04-18T18:39:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4226,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 471,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 237,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T17:39:42+00:00",
    "datetime": "2024-04-18T17:39:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 473,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 239,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T16:39:27+00:00",
    "datetime": "2024-04-18T16:39:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4178,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 478,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T15:39:17+00:00",
    "datetime": "2024-04-18T15:39:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4196,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 492,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T14:39:08+00:00",
    "datetime": "2024-04-18T14:39:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 505,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 258,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T13:38:48+00:00",
    "datetime": "2024-04-18T13:38:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4232,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 522,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 256,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T12:38:39+00:00",
    "datetime": "2024-04-18T12:38:39+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4242,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 536,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 257,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T11:38:30+00:00",
    "datetime": "2024-04-18T11:38:30+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4256,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 552,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 260,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T10:38:21+00:00",
    "datetime": "2024-04-18T10:38:21+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4266,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 558,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 255,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T09:38:14+00:00",
    "datetime": "2024-04-18T09:38:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4270,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 559,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 259,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T08:38:05+00:00",
    "datetime": "2024-04-18T08:38:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4274,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 563,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T07:37:51+00:00",
    "datetime": "2024-04-18T07:37:51+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4268,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 571,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 268,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T06:37:43+00:00",
    "datetime": "2024-04-18T06:37:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4232,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 575,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 274,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T05:37:34+00:00",
    "datetime": "2024-04-18T05:37:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4218,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 581,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 279,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T04:37:24+00:00",
    "datetime": "2024-04-18T04:37:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4203,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 589,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 284,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T03:37:14+00:00",
    "datetime": "2024-04-18T03:37:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 598,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 290,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T02:37:05+00:00",
    "datetime": "2024-04-18T02:37:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4199,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 607,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 295,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-18T01:36:56+00:00",
    "datetime": "2024-04-18T01:36:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4204,
    "throughput": 86.95554804731353,
    "version": "",
    "license_usage": 2145,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 603,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 291,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T23:36:40+00:00",
    "datetime": "2024-04-17T23:36:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 617,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 300,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T22:36:32+00:00",
    "datetime": "2024-04-17T22:36:32+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4209,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 610,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 298,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T21:36:23+00:00",
    "datetime": "2024-04-17T21:36:23+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4239,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 609,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 298,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T20:36:13+00:00",
    "datetime": "2024-04-17T20:36:13+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4253,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 609,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 295,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T19:36:04+00:00",
    "datetime": "2024-04-17T19:36:04+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4252,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 603,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 289,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T18:35:55+00:00",
    "datetime": "2024-04-17T18:35:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4270,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 501,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 278,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T17:35:45+00:00",
    "datetime": "2024-04-17T17:35:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4271,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 487,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 273,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T16:35:35+00:00",
    "datetime": "2024-04-17T16:35:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4270,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 474,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T15:35:26+00:00",
    "datetime": "2024-04-17T15:35:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4225,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 455,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 253,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T14:35:14+00:00",
    "datetime": "2024-04-17T14:35:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4202,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 432,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 257,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T13:34:53+00:00",
    "datetime": "2024-04-17T13:34:53+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4169,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 430,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T12:34:43+00:00",
    "datetime": "2024-04-17T12:34:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4163,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 430,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T11:34:33+00:00",
    "datetime": "2024-04-17T11:34:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4144,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 437,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T10:34:24+00:00",
    "datetime": "2024-04-17T10:34:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4132,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 429,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 271,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T09:34:15+00:00",
    "datetime": "2024-04-17T09:34:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4122,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 425,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T08:34:05+00:00",
    "datetime": "2024-04-17T08:34:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4140,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 425,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T07:33:50+00:00",
    "datetime": "2024-04-17T07:33:50+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4129,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 413,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 257,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T06:33:42+00:00",
    "datetime": "2024-04-17T06:33:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4118,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 404,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T05:33:34+00:00",
    "datetime": "2024-04-17T05:33:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4118,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 401,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 245,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T04:33:25+00:00",
    "datetime": "2024-04-17T04:33:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4128,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 391,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 240,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T03:33:17+00:00",
    "datetime": "2024-04-17T03:33:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4120,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 388,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 241,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T02:33:08+00:00",
    "datetime": "2024-04-17T02:33:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4123,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 394,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 245,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-17T01:33:00+00:00",
    "datetime": "2024-04-17T01:33:00+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4104,
    "throughput": 92.52174770015337,
    "version": "",
    "license_usage": 2191,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 399,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 250,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T23:32:43+00:00",
    "datetime": "2024-04-16T23:32:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4102,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 390,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T22:32:34+00:00",
    "datetime": "2024-04-16T22:32:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4119,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 405,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 257,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T21:32:25+00:00",
    "datetime": "2024-04-16T21:32:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4107,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 405,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 265,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T20:32:16+00:00",
    "datetime": "2024-04-16T20:32:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4143,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 403,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 265,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T19:32:05+00:00",
    "datetime": "2024-04-16T19:32:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4138,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 394,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T18:31:55+00:00",
    "datetime": "2024-04-16T18:31:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4141,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 402,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T17:31:46+00:00",
    "datetime": "2024-04-16T17:31:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4146,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 400,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T16:31:31+00:00",
    "datetime": "2024-04-16T16:31:31+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4186,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 394,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T15:31:22+00:00",
    "datetime": "2024-04-16T15:31:22+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 387,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T14:31:12+00:00",
    "datetime": "2024-04-16T14:31:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4210,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 396,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 270,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T13:30:51+00:00",
    "datetime": "2024-04-16T13:30:51+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4222,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 389,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 271,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T12:30:42+00:00",
    "datetime": "2024-04-16T12:30:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4196,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 396,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 278,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T11:30:33+00:00",
    "datetime": "2024-04-16T11:30:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4198,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 407,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 285,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T10:30:24+00:00",
    "datetime": "2024-04-16T10:30:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 497,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 309,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T09:30:09+00:00",
    "datetime": "2024-04-16T09:30:09+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4192,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "yellow",
    "system_health_ml": "green",
    "alert_count": 503,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 316,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T08:29:55+00:00",
    "datetime": "2024-04-16T08:29:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4189,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 511,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 322,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T07:29:42+00:00",
    "datetime": "2024-04-16T07:29:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4190,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 519,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 328,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T06:29:33+00:00",
    "datetime": "2024-04-16T06:29:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4186,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 522,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 328,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T05:29:24+00:00",
    "datetime": "2024-04-16T05:29:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4206,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 517,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 323,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T04:29:14+00:00",
    "datetime": "2024-04-16T04:29:14+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4216,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 526,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 329,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T03:29:04+00:00",
    "datetime": "2024-04-16T03:29:04+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4225,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 530,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 332,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T02:28:55+00:00",
    "datetime": "2024-04-16T02:28:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4208,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 526,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 329,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-16T01:28:45+00:00",
    "datetime": "2024-04-16T01:28:45+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4214,
    "throughput": 86.28005878362649,
    "version": "",
    "license_usage": 2133,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 526,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 333,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T23:28:25+00:00",
    "datetime": "2024-04-15T23:28:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4211,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 527,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 336,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T22:28:15+00:00",
    "datetime": "2024-04-15T22:28:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4170,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 516,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 323,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T21:28:05+00:00",
    "datetime": "2024-04-15T21:28:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4137,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 505,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 315,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T20:27:56+00:00",
    "datetime": "2024-04-15T20:27:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4088,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 502,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 320,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T19:27:47+00:00",
    "datetime": "2024-04-15T19:27:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4049,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 484,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 314,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T18:27:37+00:00",
    "datetime": "2024-04-15T18:27:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4016,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 484,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 317,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T17:27:28+00:00",
    "datetime": "2024-04-15T17:27:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3979,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 479,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 318,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T16:27:18+00:00",
    "datetime": "2024-04-15T16:27:18+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3922,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 477,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 314,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T15:27:08+00:00",
    "datetime": "2024-04-15T15:27:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3847,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 467,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 308,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T14:26:58+00:00",
    "datetime": "2024-04-15T14:26:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3735,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 454,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 303,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T13:26:38+00:00",
    "datetime": "2024-04-15T13:26:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3611,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 432,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 294,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T12:26:29+00:00",
    "datetime": "2024-04-15T12:26:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3479,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 414,
    "critical_alert_count": 11,
    "high_fidelity_alert_count": 282,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T11:26:20+00:00",
    "datetime": "2024-04-15T11:26:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3341,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 387,
    "critical_alert_count": 4,
    "high_fidelity_alert_count": 269,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T10:26:12+00:00",
    "datetime": "2024-04-15T10:26:12+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3271,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 318,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 251,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T09:26:03+00:00",
    "datetime": "2024-04-15T09:26:03+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3242,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 314,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 246,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T08:25:54+00:00",
    "datetime": "2024-04-15T08:25:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3201,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 309,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 244,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T07:25:42+00:00",
    "datetime": "2024-04-15T07:25:42+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3135,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 313,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T06:25:33+00:00",
    "datetime": "2024-04-15T06:25:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3044,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 322,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 258,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T05:25:24+00:00",
    "datetime": "2024-04-15T05:25:24+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2978,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 324,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 261,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T04:25:08+00:00",
    "datetime": "2024-04-15T04:25:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2946,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 327,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T03:24:57+00:00",
    "datetime": "2024-04-15T03:24:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2928,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 325,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T02:24:48+00:00",
    "datetime": "2024-04-15T02:24:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2926,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 326,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 260,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-15T01:24:38+00:00",
    "datetime": "2024-04-15T01:24:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2900,
    "throughput": 76.37381831018281,
    "version": "",
    "license_usage": 1273,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 328,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T23:24:20+00:00",
    "datetime": "2024-04-14T23:24:20+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2867,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 329,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 271,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T22:24:10+00:00",
    "datetime": "2024-04-14T22:24:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2871,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 333,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 275,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T21:24:01+00:00",
    "datetime": "2024-04-14T21:24:01+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2858,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 328,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 268,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T20:23:53+00:00",
    "datetime": "2024-04-14T20:23:53+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2853,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 328,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T19:23:43+00:00",
    "datetime": "2024-04-14T19:23:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2851,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 330,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 268,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T18:23:34+00:00",
    "datetime": "2024-04-14T18:23:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2826,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 323,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T17:23:25+00:00",
    "datetime": "2024-04-14T17:23:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2812,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 327,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T16:23:15+00:00",
    "datetime": "2024-04-14T16:23:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2813,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 325,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 265,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T15:23:05+00:00",
    "datetime": "2024-04-14T15:23:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2800,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 319,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T14:22:56+00:00",
    "datetime": "2024-04-14T14:22:56+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2773,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 317,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T13:22:36+00:00",
    "datetime": "2024-04-14T13:22:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2790,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 315,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T12:22:26+00:00",
    "datetime": "2024-04-14T12:22:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2791,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 316,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T11:22:17+00:00",
    "datetime": "2024-04-14T11:22:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2792,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 317,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 266,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T10:22:07+00:00",
    "datetime": "2024-04-14T10:22:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2786,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 314,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 265,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T09:21:57+00:00",
    "datetime": "2024-04-14T09:21:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2778,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 318,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T08:21:48+00:00",
    "datetime": "2024-04-14T08:21:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2786,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 308,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T07:21:34+00:00",
    "datetime": "2024-04-14T07:21:34+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2789,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 299,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 252,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T06:21:25+00:00",
    "datetime": "2024-04-14T06:21:25+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2793,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 294,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 247,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T05:21:15+00:00",
    "datetime": "2024-04-14T05:21:15+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2796,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 281,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 238,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T04:21:05+00:00",
    "datetime": "2024-04-14T04:21:05+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2809,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 285,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 241,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T03:20:55+00:00",
    "datetime": "2024-04-14T03:20:55+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2815,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 292,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T02:20:46+00:00",
    "datetime": "2024-04-14T02:20:46+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2816,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 292,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 251,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-14T01:20:36+00:00",
    "datetime": "2024-04-14T01:20:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2828,
    "throughput": 82.79912095992209,
    "version": "",
    "license_usage": 1374,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 292,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 251,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T23:20:10+00:00",
    "datetime": "2024-04-13T23:20:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2873,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 296,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 245,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T22:19:58+00:00",
    "datetime": "2024-04-13T22:19:58+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2897,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 309,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 257,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T21:19:47+00:00",
    "datetime": "2024-04-13T21:19:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2947,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 316,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 259,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T19:18:07+00:00",
    "datetime": "2024-04-13T19:18:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3102,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 338,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 259,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T18:17:57+00:00",
    "datetime": "2024-04-13T18:17:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 2335,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 363,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 268,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T17:17:47+00:00",
    "datetime": "2024-04-13T17:17:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3184,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 387,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 277,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T16:17:37+00:00",
    "datetime": "2024-04-13T16:17:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3249,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 397,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 277,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T15:17:26+00:00",
    "datetime": "2024-04-13T15:17:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3301,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 406,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 276,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T14:17:16+00:00",
    "datetime": "2024-04-13T14:17:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3381,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 420,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 280,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T13:16:54+00:00",
    "datetime": "2024-04-13T13:16:54+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3448,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 443,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 285,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T12:16:43+00:00",
    "datetime": "2024-04-13T12:16:43+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3542,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 452,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 286,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T11:16:33+00:00",
    "datetime": "2024-04-13T11:16:33+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3627,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 461,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 291,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T10:16:23+00:00",
    "datetime": "2024-04-13T10:16:23+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3677,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 456,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T08:13:37+00:00",
    "datetime": "2024-04-13T08:13:37+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3765,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 455,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 289,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T07:13:08+00:00",
    "datetime": "2024-04-13T07:13:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3852,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 458,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 292,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T06:12:53+00:00",
    "datetime": "2024-04-13T06:12:53+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3923,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 451,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T05:12:44+00:00",
    "datetime": "2024-04-13T05:12:44+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3977,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 454,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 291,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T04:12:35+00:00",
    "datetime": "2024-04-13T04:12:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3991,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 443,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 283,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T03:12:26+00:00",
    "datetime": "2024-04-13T03:12:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3988,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 434,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 276,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T02:12:17+00:00",
    "datetime": "2024-04-13T02:12:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4010,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 430,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 268,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-13T01:12:08+00:00",
    "datetime": "2024-04-13T01:12:08+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4031,
    "throughput": 84.1902693190307,
    "version": "",
    "license_usage": 2127,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 426,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 260,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T23:11:48+00:00",
    "datetime": "2024-04-12T23:11:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4065,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 411,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 248,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T22:11:38+00:00",
    "datetime": "2024-04-12T22:11:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4087,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 395,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 237,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T21:11:28+00:00",
    "datetime": "2024-04-12T21:11:28+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4121,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 390,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 231,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T20:11:17+00:00",
    "datetime": "2024-04-12T20:11:17+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4138,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 400,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 239,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T19:11:07+00:00",
    "datetime": "2024-04-12T19:11:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4137,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 402,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 247,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T18:10:57+00:00",
    "datetime": "2024-04-12T18:10:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 3795,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 390,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 247,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T17:10:47+00:00",
    "datetime": "2024-04-12T17:10:47+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4168,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 386,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 245,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T16:10:36+00:00",
    "datetime": "2024-04-12T16:10:36+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4178,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 380,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 247,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T15:10:26+00:00",
    "datetime": "2024-04-12T15:10:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4183,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 383,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 250,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T14:10:11+00:00",
    "datetime": "2024-04-12T14:10:11+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4185,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 397,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T13:09:48+00:00",
    "datetime": "2024-04-12T13:09:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4197,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 398,
    "critical_alert_count": 3,
    "high_fidelity_alert_count": 270,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T12:09:38+00:00",
    "datetime": "2024-04-12T12:09:38+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4176,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 398,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 258,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T11:09:27+00:00",
    "datetime": "2024-04-12T11:09:27+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4158,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 404,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T10:09:16+00:00",
    "datetime": "2024-04-12T10:09:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4150,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 414,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 273,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T09:09:07+00:00",
    "datetime": "2024-04-12T09:09:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4124,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 408,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T08:08:57+00:00",
    "datetime": "2024-04-12T08:08:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4114,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 402,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 263,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T07:08:44+00:00",
    "datetime": "2024-04-12T07:08:44+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4052,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 400,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 261,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T06:08:35+00:00",
    "datetime": "2024-04-12T06:08:35+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4046,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 403,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 261,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T05:08:26+00:00",
    "datetime": "2024-04-12T05:08:26+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4051,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 403,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 261,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T04:08:16+00:00",
    "datetime": "2024-04-12T04:08:16+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4070,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 418,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 272,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T03:08:07+00:00",
    "datetime": "2024-04-12T03:08:07+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4082,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 422,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 276,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T02:07:57+00:00",
    "datetime": "2024-04-12T02:07:57+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4071,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 433,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 285,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-12T01:07:48+00:00",
    "datetime": "2024-04-12T01:07:48+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4053,
    "throughput": 86.05808762166836,
    "version": "",
    "license_usage": 2173,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 435,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T23:07:29+00:00",
    "datetime": "2024-04-11T23:07:29+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4063,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 453,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 295,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T22:07:19+00:00",
    "datetime": "2024-04-11T22:07:19+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4073,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 473,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 300,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T21:07:10+00:00",
    "datetime": "2024-04-11T21:07:10+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4079,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 467,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 299,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T20:07:00+00:00",
    "datetime": "2024-04-11T20:07:00+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4075,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 466,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 295,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T19:06:50+00:00",
    "datetime": "2024-04-11T19:06:50+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4082,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 463,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 291,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T18:06:40+00:00",
    "datetime": "2024-04-11T18:06:40+00:00",
    "num_tenants": 7,
    "users": 47,
    "assets": 4029,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 474,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T17:06:29+00:00",
    "datetime": "2024-04-11T17:06:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4035,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 487,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T16:06:19+00:00",
    "datetime": "2024-04-11T16:06:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4023,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 471,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 281,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T15:06:09+00:00",
    "datetime": "2024-04-11T15:06:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3989,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 464,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 274,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T14:05:59+00:00",
    "datetime": "2024-04-11T14:05:59+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3950,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 465,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 264,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T13:05:41+00:00",
    "datetime": "2024-04-11T13:05:41+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3919,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 481,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 274,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T12:05:32+00:00",
    "datetime": "2024-04-11T12:05:32+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3886,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 491,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 285,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T11:05:23+00:00",
    "datetime": "2024-04-11T11:05:23+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3874,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 487,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 281,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T10:05:09+00:00",
    "datetime": "2024-04-11T10:05:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3888,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 501,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 290,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T09:04:55+00:00",
    "datetime": "2024-04-11T09:04:55+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3876,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 507,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 296,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T08:04:46+00:00",
    "datetime": "2024-04-11T08:04:46+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3881,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 506,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 294,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T07:04:34+00:00",
    "datetime": "2024-04-11T07:04:34+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3894,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 506,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 295,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T06:04:24+00:00",
    "datetime": "2024-04-11T06:04:24+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3905,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 502,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 294,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T05:04:16+00:00",
    "datetime": "2024-04-11T05:04:16+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3901,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 505,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 299,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T04:04:06+00:00",
    "datetime": "2024-04-11T04:04:06+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3880,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 494,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 288,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T03:03:56+00:00",
    "datetime": "2024-04-11T03:03:56+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3877,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 478,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 283,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T02:03:47+00:00",
    "datetime": "2024-04-11T02:03:47+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3874,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 479,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 278,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-11T01:03:37+00:00",
    "datetime": "2024-04-11T01:03:37+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3834,
    "throughput": 86.14506712566939,
    "version": "",
    "license_usage": 2098,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 482,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 281,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T23:03:18+00:00",
    "datetime": "2024-04-10T23:03:18+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3779,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 462,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 275,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T22:03:08+00:00",
    "datetime": "2024-04-10T22:03:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3767,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 461,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 274,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T21:02:59+00:00",
    "datetime": "2024-04-10T21:02:59+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3757,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 444,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T20:02:49+00:00",
    "datetime": "2024-04-10T20:02:49+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3772,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 457,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 275,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T19:02:40+00:00",
    "datetime": "2024-04-10T19:02:40+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3780,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 456,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 278,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T18:02:30+00:00",
    "datetime": "2024-04-10T18:02:30+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3766,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 443,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 276,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T17:02:19+00:00",
    "datetime": "2024-04-10T17:02:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3749,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 449,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 281,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T16:02:08+00:00",
    "datetime": "2024-04-10T16:02:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3747,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 458,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 285,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T15:01:57+00:00",
    "datetime": "2024-04-10T15:01:57+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3728,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 456,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 290,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T14:01:36+00:00",
    "datetime": "2024-04-10T14:01:36+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3708,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 442,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 291,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T13:01:16+00:00",
    "datetime": "2024-04-10T13:01:16+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3706,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 440,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 290,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T12:01:03+00:00",
    "datetime": "2024-04-10T12:01:03+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3847,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 439,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 286,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T11:00:53+00:00",
    "datetime": "2024-04-10T11:00:53+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4166,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 427,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 273,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T10:00:44+00:00",
    "datetime": "2024-04-10T10:00:44+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4360,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 421,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 271,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T09:00:35+00:00",
    "datetime": "2024-04-10T09:00:35+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4580,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 429,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 275,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T08:00:27+00:00",
    "datetime": "2024-04-10T08:00:27+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4672,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 430,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 276,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T07:00:08+00:00",
    "datetime": "2024-04-10T07:00:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4779,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 440,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 282,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T05:59:56+00:00",
    "datetime": "2024-04-10T05:59:56+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4947,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 451,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 289,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T04:59:46+00:00",
    "datetime": "2024-04-10T04:59:46+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5036,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 459,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 297,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T03:59:37+00:00",
    "datetime": "2024-04-10T03:59:37+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5119,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 470,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 307,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T02:59:28+00:00",
    "datetime": "2024-04-10T02:59:28+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5173,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 500,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 325,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T01:59:20+00:00",
    "datetime": "2024-04-10T01:59:20+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5237,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 514,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 334,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-10T00:59:11+00:00",
    "datetime": "2024-04-10T00:59:11+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5313,
    "throughput": 85.7340659704134,
    "version": "",
    "license_usage": 2103,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 519,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 337,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T23:59:02+00:00",
    "datetime": "2024-04-09T23:59:02+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5452,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 539,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 358,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T22:58:47+00:00",
    "datetime": "2024-04-09T22:58:47+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5504,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 551,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 372,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T21:58:38+00:00",
    "datetime": "2024-04-09T21:58:38+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5571,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 552,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 383,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T20:58:29+00:00",
    "datetime": "2024-04-09T20:58:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5635,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 584,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 402,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T19:58:17+00:00",
    "datetime": "2024-04-09T19:58:17+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5684,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 582,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 408,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T18:58:08+00:00",
    "datetime": "2024-04-09T18:58:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5735,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 564,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 395,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T17:57:58+00:00",
    "datetime": "2024-04-09T17:57:58+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5797,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 561,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 392,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T16:57:49+00:00",
    "datetime": "2024-04-09T16:57:49+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5863,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 540,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 376,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T15:57:39+00:00",
    "datetime": "2024-04-09T15:57:39+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5916,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 526,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 366,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T14:57:28+00:00",
    "datetime": "2024-04-09T14:57:28+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6035,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 515,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 352,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T13:57:19+00:00",
    "datetime": "2024-04-09T13:57:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6147,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 519,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 344,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T12:56:59+00:00",
    "datetime": "2024-04-09T12:56:59+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6311,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 522,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 344,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T11:56:48+00:00",
    "datetime": "2024-04-09T11:56:48+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6395,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 518,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 340,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T10:56:38+00:00",
    "datetime": "2024-04-09T10:56:38+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6386,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 629,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 401,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T09:56:29+00:00",
    "datetime": "2024-04-09T09:56:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6374,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 616,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 390,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T08:56:18+00:00",
    "datetime": "2024-04-09T08:56:18+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6308,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 599,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 374,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T07:56:09+00:00",
    "datetime": "2024-04-09T07:56:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6315,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 586,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 362,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T06:55:56+00:00",
    "datetime": "2024-04-09T06:55:56+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6391,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 558,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 342,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T05:55:46+00:00",
    "datetime": "2024-04-09T05:55:46+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6378,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 535,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 321,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T04:55:33+00:00",
    "datetime": "2024-04-09T04:55:33+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6396,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 524,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 310,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T03:55:23+00:00",
    "datetime": "2024-04-09T03:55:23+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6373,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 496,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 286,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T02:55:09+00:00",
    "datetime": "2024-04-09T02:55:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6386,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 462,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 267,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T01:54:58+00:00",
    "datetime": "2024-04-09T01:54:58+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6417,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 440,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 249,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-09T00:54:48+00:00",
    "datetime": "2024-04-09T00:54:48+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6432,
    "throughput": 82.99490624469125,
    "version": "",
    "license_usage": 2198,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 414,
    "critical_alert_count": 54,
    "high_fidelity_alert_count": 228,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T23:54:39+00:00",
    "datetime": "2024-04-08T23:54:39+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6423,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 388,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 204,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T22:54:29+00:00",
    "datetime": "2024-04-08T22:54:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6407,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 368,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 183,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T21:54:19+00:00",
    "datetime": "2024-04-08T21:54:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6377,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 323,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 149,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T20:54:08+00:00",
    "datetime": "2024-04-08T20:54:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6344,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 129,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T19:53:57+00:00",
    "datetime": "2024-04-08T19:53:57+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6280,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 287,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 121,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T18:53:46+00:00",
    "datetime": "2024-04-08T18:53:46+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6221,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 277,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 117,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T17:53:29+00:00",
    "datetime": "2024-04-08T17:53:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6159,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 264,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 112,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T16:53:19+00:00",
    "datetime": "2024-04-08T16:53:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6086,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 259,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 111,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T15:53:08+00:00",
    "datetime": "2024-04-08T15:53:08+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5982,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 241,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 102,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T14:52:58+00:00",
    "datetime": "2024-04-08T14:52:58+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5806,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 221,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 93,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T13:52:48+00:00",
    "datetime": "2024-04-08T13:52:48+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5598,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 192,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 86,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T12:52:28+00:00",
    "datetime": "2024-04-08T12:52:28+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5333,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 174,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 81,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T11:52:19+00:00",
    "datetime": "2024-04-08T11:52:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5109,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 173,
    "critical_alert_count": 53,
    "high_fidelity_alert_count": 81,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T10:52:10+00:00",
    "datetime": "2024-04-08T10:52:10+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4923,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 49,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T09:52:01+00:00",
    "datetime": "2024-04-08T09:52:01+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4758,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 49,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T08:51:53+00:00",
    "datetime": "2024-04-08T08:51:53+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4626,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 49,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T07:51:44+00:00",
    "datetime": "2024-04-08T07:51:44+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4474,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 50,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T06:51:30+00:00",
    "datetime": "2024-04-08T06:51:30+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4221,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 50,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T05:51:22+00:00",
    "datetime": "2024-04-08T05:51:22+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4077,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 50,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 8,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T04:51:14+00:00",
    "datetime": "2024-04-08T04:51:14+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4018,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 54,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 11,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T03:51:05+00:00",
    "datetime": "2024-04-08T03:51:05+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3972,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 53,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 11,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T02:50:56+00:00",
    "datetime": "2024-04-08T02:50:56+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3934,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 51,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 11,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T01:50:47+00:00",
    "datetime": "2024-04-08T01:50:47+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3865,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 50,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 12,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-08T00:50:39+00:00",
    "datetime": "2024-04-08T00:50:39+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3724,
    "throughput": 74.78110209935585,
    "version": "",
    "license_usage": 1329,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 47,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 12,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T23:50:31+00:00",
    "datetime": "2024-04-07T23:50:31+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3768,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 50,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 19,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T22:50:23+00:00",
    "datetime": "2024-04-07T22:50:23+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3751,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 59,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 29,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T21:50:09+00:00",
    "datetime": "2024-04-07T21:50:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3733,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 75,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 40,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T20:49:57+00:00",
    "datetime": "2024-04-07T20:49:57+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3700,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 80,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T19:49:49+00:00",
    "datetime": "2024-04-07T19:49:49+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3703,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 83,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T18:49:40+00:00",
    "datetime": "2024-04-07T18:49:40+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3685,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 58,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T17:49:31+00:00",
    "datetime": "2024-04-07T17:49:31+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3669,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 109,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 75,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T16:49:23+00:00",
    "datetime": "2024-04-07T16:49:23+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3674,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 112,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 77,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T15:49:14+00:00",
    "datetime": "2024-04-07T15:49:14+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3687,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 118,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 83,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T14:49:05+00:00",
    "datetime": "2024-04-07T14:49:05+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3652,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 122,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 86,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T13:48:56+00:00",
    "datetime": "2024-04-07T13:48:56+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3714,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 135,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 97,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T12:48:37+00:00",
    "datetime": "2024-04-07T12:48:37+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3708,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 142,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 102,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T11:48:28+00:00",
    "datetime": "2024-04-07T11:48:28+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3714,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 111,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T10:48:19+00:00",
    "datetime": "2024-04-07T10:48:19+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3734,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 170,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 124,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T09:48:11+00:00",
    "datetime": "2024-04-07T09:48:11+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3737,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 137,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T08:47:52+00:00",
    "datetime": "2024-04-07T08:47:52+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3728,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 195,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 151,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T07:47:44+00:00",
    "datetime": "2024-04-07T07:47:44+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3722,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 201,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 158,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T06:47:32+00:00",
    "datetime": "2024-04-07T06:47:32+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3721,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T05:47:24+00:00",
    "datetime": "2024-04-07T05:47:24+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3726,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 218,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 172,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T04:47:16+00:00",
    "datetime": "2024-04-07T04:47:16+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3737,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 227,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 182,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T03:47:01+00:00",
    "datetime": "2024-04-07T03:47:01+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3747,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 244,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 195,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T02:46:53+00:00",
    "datetime": "2024-04-07T02:46:53+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3748,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 259,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 209,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-07T01:46:45+00:00",
    "datetime": "2024-04-07T01:46:45+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3757,
    "throughput": 77.19780545581202,
    "version": "",
    "license_usage": 1438,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 270,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 218,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T23:45:23+00:00",
    "datetime": "2024-04-06T23:45:23+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3770,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 275,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 222,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T22:45:07+00:00",
    "datetime": "2024-04-06T22:45:07+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3814,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 276,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 224,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T21:44:58+00:00",
    "datetime": "2024-04-06T21:44:58+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3888,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 284,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 229,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T20:44:50+00:00",
    "datetime": "2024-04-06T20:44:50+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 3991,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 291,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 232,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T19:44:42+00:00",
    "datetime": "2024-04-06T19:44:42+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4070,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 295,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 233,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T18:44:27+00:00",
    "datetime": "2024-04-06T18:44:27+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4148,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 301,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 230,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T17:44:18+00:00",
    "datetime": "2024-04-06T17:44:18+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4215,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 311,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 239,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T16:44:09+00:00",
    "datetime": "2024-04-06T16:44:09+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4268,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 323,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 244,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T15:44:00+00:00",
    "datetime": "2024-04-06T15:44:00+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4349,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 346,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 262,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T14:43:43+00:00",
    "datetime": "2024-04-06T14:43:43+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4488,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 369,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 272,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T13:43:33+00:00",
    "datetime": "2024-04-06T13:43:33+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4608,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 398,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 282,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T12:43:14+00:00",
    "datetime": "2024-04-06T12:43:14+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4752,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 415,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 289,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T11:43:05+00:00",
    "datetime": "2024-04-06T11:43:05+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 4904,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 432,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 308,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T09:41:24+00:00",
    "datetime": "2024-04-06T09:41:24+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5077,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 444,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 315,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T08:41:15+00:00",
    "datetime": "2024-04-06T08:41:15+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5210,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 454,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 321,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T07:41:07+00:00",
    "datetime": "2024-04-06T07:41:07+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5405,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 465,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 327,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T06:40:54+00:00",
    "datetime": "2024-04-06T06:40:54+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5695,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 493,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 350,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T05:40:44+00:00",
    "datetime": "2024-04-06T05:40:44+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5907,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 507,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 364,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T04:40:36+00:00",
    "datetime": "2024-04-06T04:40:36+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 5985,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 522,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 379,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T03:40:29+00:00",
    "datetime": "2024-04-06T03:40:29+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6113,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 556,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 405,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T02:40:21+00:00",
    "datetime": "2024-04-06T02:40:21+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6190,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 578,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 424,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-06T01:40:07+00:00",
    "datetime": "2024-04-06T01:40:07+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6256,
    "throughput": 84.68823612313896,
    "version": "",
    "license_usage": 2128,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 613,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 455,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T23:39:46+00:00",
    "datetime": "2024-04-05T23:39:46+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6360,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 677,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 515,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T22:39:37+00:00",
    "datetime": "2024-04-05T22:39:37+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6400,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 709,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 541,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T21:39:28+00:00",
    "datetime": "2024-04-05T21:39:28+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6395,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 739,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 569,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T20:39:20+00:00",
    "datetime": "2024-04-05T20:39:20+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6453,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 754,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 584,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T19:39:12+00:00",
    "datetime": "2024-04-05T19:39:12+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6492,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 802,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 620,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T18:39:03+00:00",
    "datetime": "2024-04-05T18:39:03+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6516,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 829,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 638,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T17:38:54+00:00",
    "datetime": "2024-04-05T17:38:54+00:00",
    "num_tenants": 7,
    "users": 45,
    "assets": 6550,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 859,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 661,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T16:38:45+00:00",
    "datetime": "2024-04-05T16:38:45+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6559,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 865,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 668,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T15:38:35+00:00",
    "datetime": "2024-04-05T15:38:35+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6599,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 886,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 689,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T14:38:26+00:00",
    "datetime": "2024-04-05T14:38:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6625,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 874,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 690,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T13:38:16+00:00",
    "datetime": "2024-04-05T13:38:16+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6660,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 876,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 694,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T12:37:56+00:00",
    "datetime": "2024-04-05T12:37:56+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6710,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 850,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 677,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T11:37:47+00:00",
    "datetime": "2024-04-05T11:37:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6776,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 826,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 652,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T10:37:39+00:00",
    "datetime": "2024-04-05T10:37:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6814,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 819,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 644,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T09:37:31+00:00",
    "datetime": "2024-04-05T09:37:31+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6845,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 796,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 625,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T08:37:23+00:00",
    "datetime": "2024-04-05T08:37:23+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6889,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 778,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 615,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T07:37:13+00:00",
    "datetime": "2024-04-05T07:37:13+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6997,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 767,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 611,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T06:37:00+00:00",
    "datetime": "2024-04-05T06:37:00+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6997,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 744,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 588,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T05:36:52+00:00",
    "datetime": "2024-04-05T05:36:52+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6779,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 723,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 570,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T04:36:44+00:00",
    "datetime": "2024-04-05T04:36:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6662,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 697,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 547,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T03:36:35+00:00",
    "datetime": "2024-04-05T03:36:35+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6523,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 672,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 526,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T02:36:26+00:00",
    "datetime": "2024-04-05T02:36:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6380,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 639,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 493,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-05T01:36:16+00:00",
    "datetime": "2024-04-05T01:36:16+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 6261,
    "throughput": 83.34642929090404,
    "version": "",
    "license_usage": 2164,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 605,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 458,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T23:35:58+00:00",
    "datetime": "2024-04-04T23:35:58+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5985,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 514,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 371,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T22:35:50+00:00",
    "datetime": "2024-04-04T22:35:50+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5903,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 476,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 341,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T21:35:42+00:00",
    "datetime": "2024-04-04T21:35:42+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5820,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 446,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 312,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T20:35:33+00:00",
    "datetime": "2024-04-04T20:35:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5728,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 428,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 289,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T19:35:24+00:00",
    "datetime": "2024-04-04T19:35:24+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5600,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 380,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 252,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T18:35:09+00:00",
    "datetime": "2024-04-04T18:35:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5495,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 334,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 214,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T17:34:54+00:00",
    "datetime": "2024-04-04T17:34:54+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5375,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 300,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 187,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T16:34:44+00:00",
    "datetime": "2024-04-04T16:34:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5243,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 277,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 165,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T15:34:34+00:00",
    "datetime": "2024-04-04T15:34:34+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 5067,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 255,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 136,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T14:34:24+00:00",
    "datetime": "2024-04-04T14:34:24+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 4857,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 241,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 126,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T13:34:15+00:00",
    "datetime": "2024-04-04T13:34:15+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 4634,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 241,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T12:33:56+00:00",
    "datetime": "2024-04-04T12:33:56+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 4379,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 248,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 116,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T11:33:47+00:00",
    "datetime": "2024-04-04T11:33:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 4095,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 238,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 112,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T10:33:39+00:00",
    "datetime": "2024-04-04T10:33:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 3894,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 236,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 110,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T09:33:30+00:00",
    "datetime": "2024-04-04T09:33:30+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 3680,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 107,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T08:33:23+00:00",
    "datetime": "2024-04-04T08:33:23+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 3372,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 223,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 94,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T07:33:14+00:00",
    "datetime": "2024-04-04T07:33:14+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 2834,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 218,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 87,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T06:33:02+00:00",
    "datetime": "2024-04-04T06:33:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1684,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 211,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 81,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T05:32:54+00:00",
    "datetime": "2024-04-04T05:32:54+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1684,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 200,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 69,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T04:32:47+00:00",
    "datetime": "2024-04-04T04:32:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1690,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 193,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 58,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T03:32:40+00:00",
    "datetime": "2024-04-04T03:32:40+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1694,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 197,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T02:32:33+00:00",
    "datetime": "2024-04-04T02:32:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1710,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 205,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-04T01:32:26+00:00",
    "datetime": "2024-04-04T01:32:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1711,
    "throughput": 78.01518239761151,
    "version": "",
    "license_usage": 1560,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 207,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T23:32:11+00:00",
    "datetime": "2024-04-03T23:32:11+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1709,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 205,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T22:32:04+00:00",
    "datetime": "2024-04-03T22:32:04+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1706,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 203,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T21:31:56+00:00",
    "datetime": "2024-04-03T21:31:56+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1702,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 193,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T20:31:48+00:00",
    "datetime": "2024-04-03T20:31:48+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1705,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 193,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T19:31:41+00:00",
    "datetime": "2024-04-03T19:31:41+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1709,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 185,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T18:31:33+00:00",
    "datetime": "2024-04-03T18:31:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1704,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 181,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T17:31:25+00:00",
    "datetime": "2024-04-03T17:31:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1702,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 41,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T16:31:17+00:00",
    "datetime": "2024-04-03T16:31:17+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1702,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T15:31:09+00:00",
    "datetime": "2024-04-03T15:31:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1702,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 177,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 40,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T14:31:01+00:00",
    "datetime": "2024-04-03T14:31:01+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1717,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 173,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 40,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T13:30:53+00:00",
    "datetime": "2024-04-03T13:30:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1724,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 167,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 42,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T12:30:35+00:00",
    "datetime": "2024-04-03T12:30:35+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 183,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T11:30:26+00:00",
    "datetime": "2024-04-03T11:30:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 190,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T10:30:08+00:00",
    "datetime": "2024-04-03T10:30:08+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 190,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T09:29:57+00:00",
    "datetime": "2024-04-03T09:29:57+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 192,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T08:29:48+00:00",
    "datetime": "2024-04-03T08:29:48+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 191,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T07:29:39+00:00",
    "datetime": "2024-04-03T07:29:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1720,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 189,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T06:29:27+00:00",
    "datetime": "2024-04-03T06:29:27+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 189,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T05:29:18+00:00",
    "datetime": "2024-04-03T05:29:18+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1724,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 184,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 56,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T04:29:11+00:00",
    "datetime": "2024-04-03T04:29:11+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 173,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 54,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T03:29:03+00:00",
    "datetime": "2024-04-03T03:29:03+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T02:28:55+00:00",
    "datetime": "2024-04-03T02:28:55+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1704,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 144,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-03T01:28:47+00:00",
    "datetime": "2024-04-03T01:28:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1700,
    "throughput": 75.59838483572568,
    "version": "",
    "license_usage": 1538,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 146,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T23:28:33+00:00",
    "datetime": "2024-04-02T23:28:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1709,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 142,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T22:28:25+00:00",
    "datetime": "2024-04-02T22:28:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 146,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T21:28:17+00:00",
    "datetime": "2024-04-02T21:28:17+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 144,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T20:28:09+00:00",
    "datetime": "2024-04-02T20:28:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1732,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 131,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T19:28:01+00:00",
    "datetime": "2024-04-02T19:28:01+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1742,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 124,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T18:27:53+00:00",
    "datetime": "2024-04-02T18:27:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1747,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 124,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 49,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T17:27:44+00:00",
    "datetime": "2024-04-02T17:27:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1748,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 119,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T16:27:36+00:00",
    "datetime": "2024-04-02T16:27:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1746,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 112,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 46,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T15:27:27+00:00",
    "datetime": "2024-04-02T15:27:27+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1738,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 118,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T14:27:18+00:00",
    "datetime": "2024-04-02T14:27:18+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1727,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 109,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 48,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T13:27:10+00:00",
    "datetime": "2024-04-02T13:27:10+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1723,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 108,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 47,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T12:26:53+00:00",
    "datetime": "2024-04-02T12:26:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1720,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 37,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T11:26:45+00:00",
    "datetime": "2024-04-02T11:26:45+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 96,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 38,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T10:26:36+00:00",
    "datetime": "2024-04-02T10:26:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1719,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 37,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T09:26:28+00:00",
    "datetime": "2024-04-02T09:26:28+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1717,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 97,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 39,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T08:26:20+00:00",
    "datetime": "2024-04-02T08:26:20+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 96,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 39,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T07:26:09+00:00",
    "datetime": "2024-04-02T07:26:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 97,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 38,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T06:25:57+00:00",
    "datetime": "2024-04-02T06:25:57+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 103,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 43,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T05:25:49+00:00",
    "datetime": "2024-04-02T05:25:49+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1712,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 105,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 45,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T04:25:44+00:00",
    "datetime": "2024-04-02T04:25:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1710,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 110,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T03:25:34+00:00",
    "datetime": "2024-04-02T03:25:34+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1709,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 109,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T02:25:25+00:00",
    "datetime": "2024-04-02T02:25:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1706,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 113,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-02T01:25:15+00:00",
    "datetime": "2024-04-02T01:25:15+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1706,
    "throughput": 75.49748224832365,
    "version": "",
    "license_usage": 1552,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 112,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T23:24:49+00:00",
    "datetime": "2024-04-01T23:24:49+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1705,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 120,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T22:24:41+00:00",
    "datetime": "2024-04-01T22:24:41+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1705,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 120,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 60,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T21:24:33+00:00",
    "datetime": "2024-04-01T21:24:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1694,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 122,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 61,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T20:24:25+00:00",
    "datetime": "2024-04-01T20:24:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1685,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 129,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 67,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T19:24:16+00:00",
    "datetime": "2024-04-01T19:24:16+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1671,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 128,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 67,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T18:24:08+00:00",
    "datetime": "2024-04-01T18:24:08+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1669,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 124,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 67,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T17:23:59+00:00",
    "datetime": "2024-04-01T17:23:59+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1657,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 123,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 68,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T16:23:47+00:00",
    "datetime": "2024-04-01T16:23:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1651,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 116,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 66,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T15:23:39+00:00",
    "datetime": "2024-04-01T15:23:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1638,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 107,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 63,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T14:23:31+00:00",
    "datetime": "2024-04-01T14:23:31+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1635,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 99,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T12:20:54+00:00",
    "datetime": "2024-04-01T12:20:54+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1538,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 87,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T10:19:07+00:00",
    "datetime": "2024-04-01T10:19:07+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1493,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T09:18:44+00:00",
    "datetime": "2024-04-01T09:18:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1494,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 86,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T08:18:35+00:00",
    "datetime": "2024-04-01T08:18:35+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1496,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 85,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 54,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T07:18:27+00:00",
    "datetime": "2024-04-01T07:18:27+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1495,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 81,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T06:18:14+00:00",
    "datetime": "2024-04-01T06:18:14+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1496,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 85,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 54,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T05:18:06+00:00",
    "datetime": "2024-04-01T05:18:06+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1497,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 82,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T04:17:56+00:00",
    "datetime": "2024-04-01T04:17:56+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1498,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 84,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T03:17:47+00:00",
    "datetime": "2024-04-01T03:17:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1499,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 84,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T02:17:38+00:00",
    "datetime": "2024-04-01T02:17:38+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1496,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 84,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 50,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-04-01T01:17:28+00:00",
    "datetime": "2024-04-01T01:17:28+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1497,
    "throughput": 66.94332315183294,
    "version": "",
    "license_usage": 738,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 80,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 47,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T23:17:09+00:00",
    "datetime": "2024-03-31T23:17:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1496,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 84,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 53,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T22:17:01+00:00",
    "datetime": "2024-03-31T22:17:01+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1492,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 87,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 56,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T21:16:53+00:00",
    "datetime": "2024-03-31T21:16:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1508,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 90,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 58,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T20:16:44+00:00",
    "datetime": "2024-03-31T20:16:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1514,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 56,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T19:16:36+00:00",
    "datetime": "2024-03-31T19:16:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1513,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 56,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T18:16:25+00:00",
    "datetime": "2024-03-31T18:16:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 619,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 89,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 56,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T17:16:17+00:00",
    "datetime": "2024-03-31T17:16:17+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1514,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 91,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T16:16:08+00:00",
    "datetime": "2024-03-31T16:16:08+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1518,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 93,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T15:15:59+00:00",
    "datetime": "2024-03-31T15:15:59+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1523,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 96,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 61,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T14:15:52+00:00",
    "datetime": "2024-03-31T14:15:52+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1526,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 61,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T13:15:44+00:00",
    "datetime": "2024-03-31T13:15:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1543,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 96,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 60,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T12:15:35+00:00",
    "datetime": "2024-03-31T12:15:35+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1546,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 93,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T11:15:05+00:00",
    "datetime": "2024-03-31T11:15:05+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1544,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 92,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 58,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T10:14:55+00:00",
    "datetime": "2024-03-31T10:14:55+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1542,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 92,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T09:14:47+00:00",
    "datetime": "2024-03-31T09:14:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1542,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T08:14:39+00:00",
    "datetime": "2024-03-31T08:14:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1544,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 95,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 59,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T07:14:31+00:00",
    "datetime": "2024-03-31T07:14:31+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1545,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 93,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 57,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T06:14:18+00:00",
    "datetime": "2024-03-31T06:14:18+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1547,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 91,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T05:14:10+00:00",
    "datetime": "2024-03-31T05:14:10+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1552,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 89,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 54,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T04:14:02+00:00",
    "datetime": "2024-03-31T04:14:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1554,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 86,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T03:13:53+00:00",
    "datetime": "2024-03-31T03:13:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1555,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 89,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T02:13:44+00:00",
    "datetime": "2024-03-31T02:13:44+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1558,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 52,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-31T01:13:34+00:00",
    "datetime": "2024-03-31T01:13:34+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1560,
    "throughput": 68.9772933072435,
    "version": "",
    "license_usage": 826,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 89,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 51,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T23:11:45+00:00",
    "datetime": "2024-03-30T23:11:45+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1569,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 78,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 41,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T22:11:36+00:00",
    "datetime": "2024-03-30T22:11:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1572,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 76,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 38,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T21:11:26+00:00",
    "datetime": "2024-03-30T21:11:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1590,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 83,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 38,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T20:11:17+00:00",
    "datetime": "2024-03-30T20:11:17+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1637,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 37,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T19:11:10+00:00",
    "datetime": "2024-03-30T19:11:10+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1662,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 90,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 37,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T18:11:02+00:00",
    "datetime": "2024-03-30T18:11:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1166,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 88,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 34,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T17:10:54+00:00",
    "datetime": "2024-03-30T17:10:54+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1688,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 87,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 33,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T16:10:46+00:00",
    "datetime": "2024-03-30T16:10:46+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1710,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 86,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 31,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T15:10:37+00:00",
    "datetime": "2024-03-30T15:10:37+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1699,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 103,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 30,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T14:10:28+00:00",
    "datetime": "2024-03-30T14:10:28+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1713,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 110,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 35,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T13:10:20+00:00",
    "datetime": "2024-03-30T13:10:20+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1724,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 120,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 44,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T12:10:09+00:00",
    "datetime": "2024-03-30T12:10:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1724,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 132,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 55,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T11:09:43+00:00",
    "datetime": "2024-03-30T11:09:43+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1727,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 145,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 67,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T10:07:55+00:00",
    "datetime": "2024-03-30T10:07:55+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1726,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 78,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T09:07:46+00:00",
    "datetime": "2024-03-30T09:07:46+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1725,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 77,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T08:07:38+00:00",
    "datetime": "2024-03-30T08:07:38+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1723,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 78,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T07:07:30+00:00",
    "datetime": "2024-03-30T07:07:30+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 79,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T06:06:02+00:00",
    "datetime": "2024-03-30T06:06:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 78,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T05:05:45+00:00",
    "datetime": "2024-03-30T05:05:45+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 79,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T04:05:36+00:00",
    "datetime": "2024-03-30T04:05:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1717,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 155,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 78,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T03:05:28+00:00",
    "datetime": "2024-03-30T03:05:28+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1719,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 152,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 76,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T02:05:20+00:00",
    "datetime": "2024-03-30T02:05:20+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1730,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 152,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 77,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-30T01:05:05+00:00",
    "datetime": "2024-03-30T01:05:05+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1737,
    "throughput": 30.999706094265758,
    "version": "",
    "license_usage": 1547,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 2,
    "high_fidelity_alert_count": 82,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T23:04:47+00:00",
    "datetime": "2024-03-29T23:04:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1743,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 159,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 84,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T22:04:40+00:00",
    "datetime": "2024-03-29T22:04:40+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1740,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 89,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T21:04:32+00:00",
    "datetime": "2024-03-29T21:04:32+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1737,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 156,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 94,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T20:04:24+00:00",
    "datetime": "2024-03-29T20:04:24+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1742,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 180,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 114,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T19:04:16+00:00",
    "datetime": "2024-03-29T19:04:16+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1743,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 200,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 126,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T18:04:09+00:00",
    "datetime": "2024-03-29T18:04:09+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1746,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 233,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 152,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T17:04:02+00:00",
    "datetime": "2024-03-29T17:04:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1747,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 262,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 176,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T16:03:54+00:00",
    "datetime": "2024-03-29T16:03:54+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1743,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 275,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 185,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T15:03:47+00:00",
    "datetime": "2024-03-29T15:03:47+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1719,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 264,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 186,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T14:03:40+00:00",
    "datetime": "2024-03-29T14:03:40+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 262,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 186,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T13:03:33+00:00",
    "datetime": "2024-03-29T13:03:33+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 254,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 175,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T12:03:26+00:00",
    "datetime": "2024-03-29T12:03:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1713,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 246,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 166,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T11:03:02+00:00",
    "datetime": "2024-03-29T11:03:02+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1714,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 244,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 161,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T10:02:55+00:00",
    "datetime": "2024-03-29T10:02:55+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1710,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 231,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T09:02:48+00:00",
    "datetime": "2024-03-29T09:02:48+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 231,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 147,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T08:02:42+00:00",
    "datetime": "2024-03-29T08:02:42+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 234,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 146,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T07:02:36+00:00",
    "datetime": "2024-03-29T07:02:36+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 235,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 147,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T06:02:26+00:00",
    "datetime": "2024-03-29T06:02:26+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1717,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 235,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 148,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T05:02:19+00:00",
    "datetime": "2024-03-29T05:02:19+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 237,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 150,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T04:02:12+00:00",
    "datetime": "2024-03-29T04:02:12+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 241,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 155,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T03:02:06+00:00",
    "datetime": "2024-03-29T03:02:06+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 243,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 156,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T02:01:59+00:00",
    "datetime": "2024-03-29T02:01:59+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1718,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 242,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 156,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-29T01:01:53+00:00",
    "datetime": "2024-03-29T01:01:53+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1706,
    "throughput": 22.687435494044994,
    "version": "",
    "license_usage": 1632,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 239,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 154,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T23:01:39+00:00",
    "datetime": "2024-03-28T23:01:39+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1707,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 250,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 160,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T22:01:32+00:00",
    "datetime": "2024-03-28T22:01:32+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1696,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 244,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 157,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T21:01:25+00:00",
    "datetime": "2024-03-28T21:01:25+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1714,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 234,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 149,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T20:01:18+00:00",
    "datetime": "2024-03-28T20:01:18+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1723,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 219,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 132,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T19:01:11+00:00",
    "datetime": "2024-03-28T19:01:11+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 201,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 122,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T18:01:04+00:00",
    "datetime": "2024-03-28T18:01:04+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 164,
    "critical_alert_count": 0,
    "high_fidelity_alert_count": 91,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T17:00:57+00:00",
    "datetime": "2024-03-28T17:00:57+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1719,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 157,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 82,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T16:00:41+00:00",
    "datetime": "2024-03-28T16:00:41+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 150,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 80,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T15:00:34+00:00",
    "datetime": "2024-03-28T15:00:34+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1728,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 154,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 78,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T14:00:27+00:00",
    "datetime": "2024-03-28T14:00:27+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1715,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 154,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 76,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T13:00:20+00:00",
    "datetime": "2024-03-28T13:00:20+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1720,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 161,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 75,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T11:59:51+00:00",
    "datetime": "2024-03-28T11:59:51+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1716,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 163,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 77,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T10:59:34+00:00",
    "datetime": "2024-03-28T10:59:34+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 163,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 79,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T09:59:27+00:00",
    "datetime": "2024-03-28T09:59:27+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1720,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 166,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 82,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  },
  {
    "otp": "e5f43005e02f",
    "customer_name": "Virtual Armour",
    "license_type": "asset",
    "timestamp": "2024-03-28T08:59:20+00:00",
    "datetime": "2024-03-28T08:59:20+00:00",
    "num_tenants": 7,
    "users": 44,
    "assets": 1721,
    "throughput": 23.221672656561527,
    "version": "",
    "license_usage": 1603,
    "license_limit": 3000,
    "saved_queries": 0,
    "scheduled_reports": 0,
    "playbooks": 0,
    "response_actions": 0,
    "system_health": "yellow",
    "system_health_dp": null,
    "system_health_ds": "green",
    "system_health_ml": "green",
    "alert_count": 163,
    "critical_alert_count": 1,
    "high_fidelity_alert_count": 85,
    "sw_license_expiration_datetime": "2024-03-25T00:00:00+00:00",
    "support_license_expiration_datetime": "2024-03-25T00:00:00+00:00"
  }
];